import React, { useState, useEffect, ReactElement } from "react";
import { Buffer } from "buffer";

interface IProps {
  base64Data: string;
  fileType: string;
  fileName: string;
  children: ReactElement;
}

const FileDecoder = (props: IProps) => {
  const [fileData, setFileData] = useState<any>(null);

  const handleFileDecode = () => {
    const byteCharacters = Buffer.from(props.base64Data, "base64").toString(
      "binary"
    );
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const decodedFileData = new Blob([byteArray], { type: props.fileType });

    setFileData(decodedFileData);
  };

  const handleDownloadFile = () => {
    if (fileData) {
      const downloadLink = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = props.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    handleFileDecode();
  }, []);

  return (
    <div className="d-flex justify-content-center text-center flex-column p-8 mw-250px">
      <button
        onClick={handleDownloadFile}
        className="btn btn-link"
      >
        <div>{props.children}</div>
      </button>
    </div>
  );
};

export default FileDecoder;
