import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  modalHeader: string,
  modalMessage: string,
  show: boolean,
  handleClose: () => void;
  handleDiscard: () => void;
  handleSubmit: (data: any) => Promise<void> | void;
}

export const CloseConfirmationModal = (props: ModalProps) => {
  const { handleClose, handleDiscard, handleSubmit, show, modalHeader, modalMessage } = props;
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  return (
      <Modal
        id="closeConfirmationModal"
        show={show}
        tabIndex={-1}
        onHide={handleClose}
        backdrop
        backdropClassName="modal-backdrop-confirmation"
        dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      >
        <div className="modal-header">
          <h2>{modalHeader}</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-1"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
          {/* end::Close */}
        </div>
        <div className="modal-body py-lg-10 px-lg-10">
          <div className="flex-row-fluid py-lg-5 px-lg-15">
            {modalMessage}
          </div>
        </div>

        <div className="modal-footer">
            <button
                type="button"
                className="btn btn-lg btn-primary me-3"
                ref={submitBtnRef}
                onClick={handleSubmit}
            >
                <span className="indicator-label">Save</span>
            </button>
            <button
                type="button"
                className="btn btn-lg btn-danger me-3"
                onClick={handleDiscard}
            >
                <span className="indicator-label">Discard</span>
            </button>    
        </div>
      </Modal>
  )
}
