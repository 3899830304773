import { toAbsoluteUrl } from "../../../_metronic/helpers";
import FileDecoder from "./FileDecoder";
import DocumentDigitalSignature from "./DocumentDigitalSignature";
import { ReactElement } from "react";

interface IProps {
  notice?: ReactElement;
  documents: IDocument[];
}

interface IDocument {
  name: string;
  createdDate: string;
  data: string;
  type: string;
  docusignRedirectURL: string;
  requiresSignature: boolean;
}

export const DocumentsCollection = (props: IProps) => {
  return (
    <>
      <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
        <div className="col-12 col-sm-12 col-xl">
          <div className="card card-rounded h-100 p-8">
            {props.notice}
            <div className="d-flex justify-content-center text-center flex-row">
              {props.documents.map((document, index) => (
                  <div key={index}>
                    {document.requiresSignature ? (
                        <>
                          <div className="d-flex justify-content-center text-center flex-row">
                            <DocumentDigitalSignature
                              fileName={document.name}
                              fileType={document.type}
                              docusignRedirectURL={document.docusignRedirectURL}
                              children={
                                <div className="btn symbol symbol-75px mb-6">
                                  {document.type === "pdf" ? (
                                    <img
                                      src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={toAbsoluteUrl("/media/svg/files/doc.svg")}
                                      alt=""
                                    />
                                  )}
                                  <div className="fs-5 fw-bolder mb-2">
                                    {document.name}
                                  </div>
                                  <div className="fs-7 fw-bold text-gray-400 mt-auto">
                                    {document.createdDate}
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center text-center flex-row">
                          <FileDecoder
                            fileName={document.name}
                            fileType={document.type}
                            base64Data={document.data}
                            children={
                              <div className="btn symbol symbol-75px mb-6">
                                {document.type === "pdf" ? (
                                  <img
                                    src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={toAbsoluteUrl("/media/svg/files/doc.svg")}
                                    alt=""
                                  />
                                )}
                                <div className="fs-5 fw-bolder mb-2">
                                  {document.name}
                                </div>
                                <div className="fs-7 fw-bold text-gray-400 mt-auto">
                                  {document.createdDate}
                                </div>
                              </div>
                            }
                          />
                        </div>
                      )}
                  </div>   
              ))}
            </div>     
          </div>
        </div>
      </div>
    </>
  );
};
