import * as Yup from "yup";
import { employmentStatusLookup, employmentTypeLookup } from "../../Lookups";
import { groupedOptions as groupedOccupations } from "../../../../models/EmploymentData";
import { getEmployment } from "../../../common/componentDataHelpers/EmploymentHelper";

export const employmentSchema = Yup.object().shape({
  employments: Yup.array()
    .of(
      Yup.object().shape({
        employmentStatus: Yup.string()
          .label("Employment Status")
          .required("Please select an employment status."),
        employmentType: Yup.string()
          .label("Employment Type")
          .nullable()
          .when("employmentStatus", {
            is: (val: string) => !["1746", "1748"].includes(val), // Retired, Unemployed
            then: Yup.string()
              .label("Employment Type")
              .required("Please select an employment type.")
          }),
        occupation: Yup.string()
          .label("Occupation")
          .nullable()
          .when("employmentStatus", {
            is: (val: string) => !["1746", "1748"].includes(val), // Retired, Unemployed
            then: Yup.string()
              .label("Occupation")
              .required("Please select an occupation.")
          }),
        dateStarted: Yup.string()
          .label("Start Date")
          .required("Please specify the start date of this employment"),
        dateEnded: Yup.string()
          .label("End Date")
          .nullable()
          .when("employmentType", {
            is: (val: string) => val === "3665", // Previous Employment
            then: Yup.string()
              .required("Please specify the end date of this employment.")
              .notOneOf(
                ["", "0"],
                "Please specify the end date of this employment."
              ),
          }),
      })
    )
    .label("Employment Details")
    .meta({
      fieldArrayName: "employments",
      fieldArrayDescription: "Please add employment details below",
      addSeparatorAbove: false,
      itemTypeName: "Employment",
      getItemTitle: getEmployment,
      control: [
        {
          componentType: "Lookup",
          props: {
            label: "Employment Status",
            description: "",
            name: "employmentStatus",
            tooltip: "",
            values: employmentStatusLookup,
            required: true,
          },
        },
        {
          componentType: "LookupWithSearchAndGroups",
          props: {
            name: "occupation",
            label: "Select your occupation",
            required: true,
            description: "Select your occupation",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "",
            values: groupedOccupations,
          },
          dependsOnFields: [
            {
              fieldName: "employmentStatus",
              fieldValue: ["1742", "1743", "1744", "1745", "1747", "1749", "1750", "1751", "1752"]
            },
          ],
        },
        {
          componentType: "Lookup",
          props: {
            name: "employmentType",
            label: "What type of employment was this?",
            required: true,
            description: "",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "",
            values: employmentTypeLookup,
          },
          dependsOnFields: [
            {
              fieldName: "employmentStatus",
              fieldValue: ["1742", "1743", "1744", "1745", "1747", "1749", "1750", "1751", "1752"]
            },
          ],
        },
        {
          componentType: "DateMMYYYY",
          props: {
            name: "dateStarted",
            label: "Date Started",
            required: true,
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: 1920,
            endYear: new Date().getFullYear(),
          },
        },
        {
          componentType: "DateMMYYYY",
          props: {
            name: "dateEnded",
            label: "Date ended",
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: 1920,
            endYear: new Date().getFullYear(),
          },
          dependsOnFields: [
            {
              fieldName: "employmentType",
              fieldValue: "3665", // Previous Employment
            },
            {
              fieldName: "employmentStatus",
              fieldValue: "1748", // Unemployed
            },
          ],
        },
      ],
    }),
});
