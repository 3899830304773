import { useEffect, useState } from "react";
import { ExampleClientData } from "../../context/ExampleClientData";
import { usePortal } from "../../context/PortalContext";
import { IJournalNote, IMilestone, IAnnuity } from "../../models/ClientData";
import { JournalNote } from "../../modules/common/cards/JournalNote";
import { Milestones } from "../../modules/common/cards/Milestones";
import { Person } from "../../modules/common/cards/Person";
import { CTA3 } from "../../modules/common/cards/CTA3";
import { Notice } from "../../modules/common/cards/Notice";
import { Loader } from "../../modules/common/modals/LoaderModal";

export const Application = () => {
  const { clientData }: any = usePortal();

  const [journalNotes, setJournalNotes] = useState(Array<IJournalNote>());
  const [milestones, setMilestones] = useState(Array<IMilestone>());
  const [annuityData, setAnnuityData] = useState<IAnnuity>({});

  useEffect(() => {
    if (clientData) {
      setJournalNotes(clientData.annuity?.journalNotes ?? []);
      setMilestones(clientData.annuity?.milestones ?? []);
      setAnnuityData(clientData.annuity);
    }
  }, []);

  function decodeAdvisorPicture() {}

  return (
    <>
      {annuityData ? (
        <>
          {annuityData.status !== "Portal" &&
          annuityData.status !== "" &&
          annuityData.status ? (
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Person
                  personName={annuityData.iFAName}
                  personJobTitle={"Your Independant Financial Adviser"}
                  personNote={annuityData.iFADetails}
                  imageBase64={annuityData.iFAImage}
                  buttons={[
                    {
                      label: "Book an Appointment",
                      to: " ",
                      id: "booking",
                      className: null,
                      function: () => {},
                    },
                  ]}
                />
                <Milestones
                  milestones={[
                    ...milestones.map((item: IMilestone, index) => ({
                      name: item.name,
                      completed: item.completed,
                      completedDate: item.completedDate,
                      dueDate: item.dueDate,
                    })),
                  ]}
                  title="Milestones"
                  className="mb-5"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <h3 className="fw-bolder my-2">Your Progress</h3>
                <span className="text-gray-700 fw-bold mb-10">
                  As your application progresses we ensure to add notes to your
                  record, these are below:
                </span>
                <div className="pt-10">
                  {journalNotes.map((journalNote: IJournalNote, index) => {
                    return (
                      <div key={index}>
                        <JournalNote
                          createdBy={journalNote.createdBy}
                          createdByJobTitle={journalNote.createdByJobTitle}
                          imageBase64={journalNote.createdByImage}
                          subject={journalNote.subject}
                          note={journalNote.note}
                          date={journalNote.date}
                          className="mb-5"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <>
              <Notice
                noticeClasses={
                  "bg-light-warning rounded border-dark mt-9 mb-9 p-10 mb-10"
                }
                icon={undefined}
                iconClasses={null}
                header={"You don't have an application"}
                content={
                  "When you have an application underway, you will be able to track the progress here."
                }
                button={null}
              />

              <CTA3
                header={<div>Ready to start your retirement journey?</div>}
                image={"media/illustrations/bw/png/travel-2.png"}
                linkTo={""}
                linkLabel={"BOOK APPOINTMENT"}
                linkId="booking"
                subLinkTo={""}
                subLinkLabel={""}
              />
            </>
          )}
        </>
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </>
  );
};
