import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Radios } from "../../modules/forms/form_fields/Radios";
import { KTSVG } from "../../_metronic/helpers";
import CalendlyWidget from "../../modules/common/other/CalendlyWidget";
import { usePortal } from "../../context/PortalContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { Notice } from "../../modules/common/cards/Notice";
import { CTA1 } from "../../modules/common/cards/CTA1";

export const Booking = () => {
  const { returnClientData, setClientData, clientData }: any = usePortal();
  const [calendlyURL, setCalendlyURL] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [factFindCompleted, setFactFindCompleted] = useState(false);

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    setClientName(
      clientData?.title +
        " " +
        clientData?.firstName +
        " " +
        clientData?.lastName
    );
    setClientEmail(clientData?.email);
    // Format phone number to UK style
    setClientPhone(
      clientData?.primaryPhone
        ?.replace(/\s+/g, "")
        ?.replace(/(.)(\d{4})(\d)/, "+44 ($1)$2 $3")
    );

    if (clientData?.annuity?.factfindCompleted === "true") {
      setFactFindCompleted(true);
    }
  }, [clientData]);

  function submitStep(values: any) {
    if (values.chooseAppointment === "specialist") {
      setCalendlyURL(
        `https://calendly.com/specialist-2pdn/general-questions-with-an-annuity-specialist-portal?hide_event_type_details=1&hide_gdpr_banner=1&location=${clientPhone}`
      );
    }
    if (values.chooseAppointment === "adviser") {
      setCalendlyURL(
        `https://calendly.com/ifa-5-g/ifa-appointment?hide_event_type_details=1&hide_gdpr_banner=1&location=${clientPhone}`
      );
    }
  }

  function changeAppointmentType() {
    setCalendlyURL("");
  }

  return (
    <div className="container py-5">
      {calendlyURL !== "" ? (
        <div>
          <div className="d-flex justify-content-center mb-3">
            <button
              className="btn btn-secondary me-3"
              onClick={changeAppointmentType}
            >
              <span className="indicator-label">Change appointment type</span>
            </button>
          </div>
          <CalendlyWidget
            url={calendlyURL}
            prefill={{ name: clientName, email: clientEmail }}
          />
        </div>
      ) : (
        <Formik
          onSubmit={submitStep}
          initialValues={{ chooseAppointment: "specialist" }}
        >
          <Form>
            <Radios
              label={"What kind of appointment would you like?"}
              description={""}
              name={"chooseAppointment"}
              items={[
                {
                  label: "Speak with a retirement specialist",
                  value: "specialist",
                  description:
                    "For if you want to just run through a few questions with a member of our team or complete a Fact Find to arrange an appointment with a financial adviser.",
                  disabled: false,
                  defaultChecked: true,
                },

                {
                  label: "Speak with an independent financial adviser",
                  value: "adviser",
                  description:
                    user && factFindCompleted ? (
                      "Book an adviser appointment for independent financial advice and recommendation"
                    ) : (
                      <div>
                        You must first complete a Fact Find either on the portal
                        by clicking{" "}
                        <a href="/factfind" className="text-primary fw-bold">
                          {" "}
                          here{" "}
                        </a>
                        or, by booking an appointment with a retirement
                        specialist, before you can book to speak with a
                        financial adviser.
                      </div>
                    ),
                  disabled: user && factFindCompleted ? false : true,
                },
              ]}
            />
            <button type="submit" className="btn btn-lg btn-primary me-3 mt-5">
              <span className="indicator-label">
                Next
                <KTSVG
                  path="/media/icons/duotune/arrows/arr064.svg"
                  className="svg-icon-3 ms-2 me-0"
                />
              </span>
            </button>
          </Form>
        </Formik>
      )}

      <div className="card border rounded border-dark p-10 mt-5">
        <h1 className="fs-1">Need to get in touch now?</h1>
        <p>
          If you don't want to arrange an appointment, feel free to give us a
          call:
        </p>
        <h1>0808 281 2558</h1>
        <p>
          Mon to Fri - 9am - 5pm <br />
          Sat - 9am - 1pm
        </p>
      </div>
    </div>
  );
};
