import { getMedicalInfoSchema } from "../Shared/MedicalInfoSchema";

const header = "Medical details";

const description =
  "Tell us about your lifestyle, medical conditions and any medications";

const person1MedicalSchema = getMedicalInfoSchema(false);
const person2MedicalSchema = getMedicalInfoSchema(true);

export { header, description, person1MedicalSchema, person2MedicalSchema };
