import { useEffect, useState } from "react";
import { usePortal } from "../../context/PortalContext";
import { IQuote } from "../../models/QuoteData";
import { CTA1 } from "../../modules/common/cards/CTA1";
import { QuoteCollection } from "../../modules/common/cards/QuoteCollection";
import { CTA3 } from "../../modules/common/cards/CTA3";
import { Loader } from "../../modules/common/modals/LoaderModal";

export const MyQuotes = () => {
  const { clientData }: any = usePortal();

  const [quotesList, setQuotesList] = useState<Array<IQuote>>([]);
  const [firstQuoteCreatedDate, setFirstQuoteCreatedDate] = useState("");
  const [quotesListHighest, setQuotesListHighest] = useState(Array<IQuote>());

  useEffect(() => {
    setQuotesList(clientData.annuity?.quotes ?? []);
    setFirstQuoteCreatedDate(
      quotesList.length > 0 ? quotesList[0].createdDate ?? "" : ""
    );
  }, []);

  useEffect(() => {
    if (quotesList) {
      setQuotesListHighest(
        Object.values(
          quotesList.reduce((acc: { [key: string]: IQuote }, quote: IQuote) => {
            if (!quote.productName) {
              return acc;
            }
            if (
              !acc[quote.productName] ||
              acc[quote.productName].annuityAmountPA < quote.annuityAmountPA
            ) {
              acc[quote.productName] = quote;
            }
            return acc;
          }, {})
        )
      );
    }
  }, [quotesList]);

  return (
    <div>
      {clientData ? (
        <div>
          <div className="mb-10">
            <CTA1
              title={
                <div className="mb-5">
                  Ready to speak to <br />
                  an adviser?
                </div>
              }
              imageSrc="media/illustrations/bw/png/social-3.png"
              button={[
                {
                  to: "#",
                  label: "BOOK AN APPOINTMENT",
                  id: "booking",
                  className: "btn btn-primary fw-semibold px-6 py-3",
                  function: undefined,
                },
              ]}
            />
          </div>
          {quotesListHighest.length > 0 ? (
            <QuoteCollection
              title="Your Top Quotes"
              titleLink={null}
              topParagraph={null}
              bottomParagraph={null}
              notice={null}
              bottomButton={{
                to: "/annuityquotes",
                className: "btn btn-dark",
                label: "View all quotes",
              }}
              footer={null}
              quotes={quotesListHighest.map((quote) => ({
                logoLocation: `/media/providers/${quote.providerName}.png`,
                footer:
                  quote.productName === "Fixed Term Annuity"
                    ? "Based on a term of " +
                      quote.termYears +
                      " years and " +
                      quote.termMonths +
                      " months."
                    : "",
                outerTitle: quote.productName,
                quoteValues: [
                  {
                    title: "Provider",
                    value: quote.providerName,
                  },
                  {
                    title: "Total Annual Income",
                    value: new Intl.NumberFormat("en-gb", {
                      style: "currency",
                      currency: "GBP",
                    })
                      .format(quote.annuityAmountPA)
                      .toString(),
                  },
                  {
                    title: "Tax Free Cash",
                    value: new Intl.NumberFormat("en-gb", {
                      style: "currency",
                      currency: "GBP",
                    })
                      .format(quote.taxFreeCash)
                      .toString(),
                  },
                  ...(quote.guaranteedMaturitySum &&
                  quote.productName !== "Lifetime Annuity"
                    ? [
                        {
                          title: "Guaranteed Maturity Sum",
                          value: new Intl.NumberFormat("en-gb", {
                            style: "currency",
                            currency: "GBP",
                          })
                            .format(quote.guaranteedMaturitySum)
                            .toString(),
                        },
                      ]
                    : []),
                ],
                button: {
                  to: "",
                  className: "btn btn-primary",
                  label: "View Details",
                  id:
                    quote.productName === "Lifetime Annuity"
                      ? "whatIsALifeTimeAnnuity"
                      : "whatIsAFixedTermAnnuity",
                },
              }))}
            />
          ) : (
            <CTA3
              header={<div>It looks like you don't have any quotes yet...</div>}
              image={"media/illustrations/bw/png/there-is-nothing-here.png"}
              linkTo={"/fullquote"}
              linkLabel={"Get quotes"}
              subLinkTo={""}
              subLinkLabel={""}
            />
          )}
        </div>
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </div>
  );
};
