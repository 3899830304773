import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { HeaderUserMenu } from "../../../_metronic/partials";

const HeaderNav = () => {
  return (
    <>
      {/* Header with three evenly distributed sections */}
      <div
        id="kt_app_header"
        className="container-fluid py-4 app-header app-container"
      >
        <div className="row align-items-center">
          {/* Logo (Left) */}
          <div className="col-6">
            <Link to="/">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logopense.webp")}
                className="h-40px h-lg-40px app-sidebar-logo-default theme-light-show"
              />
            </Link>
          </div>

          {/* User Menu (Right) */}
          <div className="col-6 text-end">
            <HeaderUserMenu />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderNav;
