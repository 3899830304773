import { toAbsoluteUrl } from "../../../_metronic/helpers";

export const TrustpilotHorizontal = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="me-5">
        <span className="text-dark">Excellent </span>
        <span className="fw-bold">4.9 </span>
        {/* <span>|</span> */}
        {/* <span className="fw-bold"> 20 reviews</span> */}
      </div>
      <div className="me-5">
        <img
          className="align-text-bottom"
          src={toAbsoluteUrl("media/misc/star.svg")}
          alt=""
          width={100}
          height={100}
        />
      </div>
      <div>
        <img
          className="align-text-bottom"
          src={toAbsoluteUrl("media/misc/trustpilot.svg")}
          alt=""
          width={100}
          height={100}
        />
      </div>
    </div>
  );
};
