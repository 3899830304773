import * as Yup from "yup";
import { groupedOptions as groupedPensionProviders } from "../../../../models/PensionProviders";
import { pensionTypeLookup } from "../../Lookups";
import { typeFormatters } from "../../Formatters";
import { fieldValidation } from "../../Validators";
import {
  ExtractLabelFromGroupedOptionsBasedOnValue,
  ExtractLabelFromLookupOptionsBasedOnValue,
} from "../../Helpers";

function getPension(item: any, index: number): string {
  //ceding scheme name
  const cedingScheme = ExtractLabelFromGroupedOptionsBasedOnValue(
    item.cedingScheme,
    groupedPensionProviders
  );
  const customerProvidedCedingSchemeName =
    item.customerProvidedCedingSchemeName;
  const cedingSchemeName =
    cedingScheme === "" || cedingScheme === undefined
      ? customerProvidedCedingSchemeName
      : cedingScheme;
  // Array of available condition options.
  const valuation = item.valuation
    ? `£${item.valuation}`
    : "No valuation provided";

  if (cedingSchemeName) {
    return `${cedingSchemeName} - ${valuation}`;
  }

  // Fallback: return a default title if no match is found.
  return `Pension ${index + 1} - ${valuation}`;
}

export const pensionSchema = Yup.object().shape({
  pensions: Yup.array()
    .of(
      Yup.object().shape({
        pensionType: Yup.string().label("Pension Type").nullable(),
        cedingScheme: Yup.string().label("Ceding Scheme").nullable(),
        customerProvidedCedingSchemeName: Yup.string()
          .label("Provider Name")
          .nullable()
          .when("cedingScheme", {
            is: (val: string) => val === "-1",
            then: Yup.string().required(
              "Please tell us the name of your provider."
            ),
          }),
        valuation: Yup.string()
          .label("Valuation")
          .transform(typeFormatters["currency"])
          .test(
            "is-valid-pensionValuation",
            "Please enter a value between £10,000 and £10,000,000",
            (value) =>
              fieldValidation(value, "currency", 10000, 10000000, false)
          )
          .nullable(),
        valuationDate: Yup.string().label("Valuation Date").nullable(),
      })
    )
    .label("Pensions")
    .meta({
      fieldArrayName: "pensions",
      fieldArrayDescription:
        "Please add details for any pensions you have below",
      addSeparatorAbove: false,
      getItemTitle: getPension,
      itemTypeName: "Pension",
      control: [
        {
          componentType: "Lookup",
          props: {
            label: "Pension type (if known)",
            description:
              "Different types of pensions have different rules and benefits which can affect your retirement advice.",
            name: "pensionType",
            tooltip: "",
            values: pensionTypeLookup,
          },
        },
        {
          componentType: "LookupWithSearchAndGroups",
          props: {
            name: "cedingScheme",
            label: "Who is the pension provider?",
            required: false,
            description:
              "Search and select a provider. This is the company or organization that manages your pension.",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            values: groupedPensionProviders,
            allowFreeType: true,
            unknownEntryValue: "-1",
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "customerProvidedCedingSchemeName",
            label: "What is the name of this provider?",
            required: true,
            description: "Tell us the name of your pension provider.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
          },
          dependsOnFields: [
            {
              fieldName: "cedingScheme",
              fieldValue: "-1",
            },
          ],
        },
        {
          componentType: "InputBox",
          props: {
            label: "Valuation",
            description: "How much is in the pension?",
            name: "valuation",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "text",
            prefix: <div className="h3">£</div>,
            formatType: "currency",
          },
        },
        {
          componentType: "DateDDMMYYYY",
          props: {
            name: "valuationDate",
            label: "Valuation date",
            required: false,
            description: "This is the date when your pension was last valued.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear() - 10,
            endYear: new Date().getFullYear(),
          },
        },
      ],
    }),
});
