import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import { ReactNode } from "react";

export const CheckboxBoxes = (props: IProps) => {
  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="mb-10 fv-row">
        <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
          {/* Label text */}
          <span className={props.required ? "required" : ""}>
            {props.label}
          </span>

          {/* Button (tooltips, icons, etc.) */}
          <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
            {props.tooltip?.button && props.tooltip.button}
          </div>
        </label>
        {props.tooltip?.notice && props.tooltip.notice}
        {props.description != "" && (
          <small className="form-text">{props.description}</small>
        )}
        <div className="row mb-2" data-kt-buttons="true">
          {props.items.map((item, index) => {
            return (
              <div key={item.value} className="col-md-4 col-lg-3 mb-4 me-2">
                <Field
                  type="checkbox"
                  className="btn-check"
                  name={props.name}
                  value={item.value}
                  id={props.name + "-" + index}
                />
                <label
                  className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                  htmlFor={props.name + "-" + index}
                >
                  <span className="fs-6">{item.label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="text-danger mt-2">
        <ErrorMessage name={props.name} />
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  tooltip?: ITooltip;
  items: IItem[];
  name: string;
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
  required: boolean;
}

interface IItem {
  label: string;
  value: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
