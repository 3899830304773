import { PageTitle } from "../_metronic/layout/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import { useEffect, useState } from "react";
import { CarouselModal } from "../modules/common/modals/CarouselModal";
import { CTA1 } from "../modules/common/cards/CTA1";
import { FAQ } from "../modules/common/other/FAQ";
import { CTA3 } from "../modules/common/cards/CTA3";
import { Link } from "react-router-dom";
import { CheckBoxCTA2 } from "../modules/common/cards/CheckBoxCTA2";
import { ImageCard } from "../modules/common/cards/ImageCard";
import { toAbsoluteUrl } from "../_metronic/helpers";
import { usePortal } from "../context/PortalContext";
import { TrustpilotHorizontal } from "../modules/common/other/TrustpilotHorizontal";
import LogoBanner from "../modules/common/other/LogoBanner";

export const Home = () => {
  const [user, loading, error] = useAuthState(auth);
  const [showGetStartedModal, setShowGetStartedModal] = useState(false);
  const { setClientData }: any = usePortal();
  useEffect(() => {
    setShowGetStartedModal(true);

    //set sessionID if it's in the URL, this is for registrations that came from a lead lander,
    //if a sessionID is set, when it creates the lead, it will use this to transfer the existing
    //lead and associate to the new portal client
    const urlParams = new URLSearchParams(window.location.search);
    let _sessionID: string | null = null;

    urlParams.forEach((value, key) => {
      if (key.toLowerCase() === "sid") {
        _sessionID = value;
      }
    });
    if (_sessionID) {
      setClientData({ sessionId: _sessionID });
    }
  }, []);

  const providers = [
    { src: "/media/providers/Aviva.png", alt: "Aviva" },
    { src: "/media/providers/JUST.png", alt: "JUST" },
    { src: "/media/providers/Legal & General.png", alt: "Legal & General" },
    { src: "/media/providers/LV=.png", alt: "lV=" },
    { src: "/media/providers/Standard Life.png", alt: "Standard Life" },
    { src: "/media/providers/Scottish Widows.png", alt: "Scottish Widows" },
    { src: "/media/providers/Canada Life.png", alt: "Canada Life" },
  ];

  return (
    <>
      <div className="pb-10 fs-3">
        Hello, manage your retirement here in your portal
      </div>

      <CarouselModal
        handleClose={() => setShowGetStartedModal(false)}
        show={showGetStartedModal}
        modalHeader={""}
        items={[
          {
            title: "Welcome to Pense",
            content: (
              <div>
                Your retirement specialists, giving you the tools, knowledge and
                advice to help you maximise your retirement. Get generic and
                tailored quotes in minutes by telling us some basic details to
                take control of your retirement.
              </div>
            ),
            button1Override: {
              className: "btn bg-white align-self-center ms-2 mb-5",
              function: () => setShowGetStartedModal(false),
              to: "/auth/Login",
              label: "Already have an account?",
              nextOrprev: null,
            },
            button2Override: undefined,
            addNextPrevButtons: true,
          },
          {
            title: "Generic and tailored quotes",
            content: (
              <div>
                See your retirement quotes in minutes by telling us some basic
                details. Tailor to your circumstances by telling us a few more
                specific details for more accurate quotes.
              </div>
            ),
            button1Override: undefined,
            button2Override: undefined,
            addNextPrevButtons: true,
          },
          {
            title: "Speak to specialist advisers",
            content: (
              <div>
                We are here to help and support you by answering your questions,
                helping you make the most out of your retirement. Book online or
                telephone appointments in an instant.
              </div>
            ),
            button1Override: undefined,
            button2Override: undefined,
            addNextPrevButtons: true,
          },
          {
            title: "Register for free",
            content: (
              <div>
                Create an account for free to access a powerful suite of
                retirement planning tools, save your personalised quotes and
                progress, whilst enjoying the flexibility to proceed entirely at
                your own pace. Once you've completed the steps, you can then
                speak to one of our qualified Independent Financial Advisers.
              </div>
            ),
            button1Override: undefined,
            button2Override: {
              className: "",
              function: () => setShowGetStartedModal(false),
              to: "/quickquote",
              label: "Let's get some basic quotes",
              nextOrprev: null,
            },
            addNextPrevButtons: true,
          },
        ]}
        imageSrc="media\illustrations\bw\png\welcome.png"
      />

      <div className="row">
        <div className="col-12 mb-5">
          <CTA1
            title={"An online journey to your retirement"}
            titleClassName="text-dark fs-2qx fw-bold mb-3 mt-5"
            content={
              <div className="fs-3 mb-0 text-dark mt-5">
                <ul className="list-unstyled">
                  <li>
                    <i className="fas fa-check text-dark fs-2"></i> Get quotes
                    from the whole market
                  </li>
                  <li>
                    <i className="fas fa-check text-dark fs-2"></i> Speak with
                    one of our advisers over the telephone or video call
                  </li>
                  <li>
                    <i className="fas fa-check text-dark fs-2"></i> Relax while
                    we handle the rest
                  </li>
                </ul>
              </div>
            }
            imageSrc="media/illustrations/bw/png/statistics.png"
            button={[
              {
                id: "",
                to: "/quickquote",
                label: "Get started",
                className: "btn btn-success fs-1",
                function: undefined,
              },
            ]}
            subLinkTo="/auth/login"
            subLinkLabel="Already have an account?"
            cardFooter={
              <div className="card-footer">
                <div className="row d-flex justify-content-center align-items-center text-center">
                  <div className="col-md-6 col-sm-12">
                    <TrustpilotHorizontal />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <img
                      style={{ height: 75 }}
                      src={toAbsoluteUrl("media/misc/fscs-protected.png")}
                      alt=""
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 text-center fw-bold pt-5 pb-5">
                    Pense Ltd is authorised and regulated by the Financial
                    Conduct Authority number 231629. Find us on the FCA Website{" "}
                    <a
                      style={{ color: "blue", textDecoration: "underline" }}
                      href="https://register.fca.org.uk/s/firm?id=001b000000MfOTwAAN"
                    >
                      here
                    </a>
                    .
                  </div>
                </div>
              </div>
            }
          />
        </div>

        <div className="col-12 mb-5">
          <CheckBoxCTA2
            header={"Benefits of using Pense..."}
            content={
              <span className="fs-1 text-dark fw-bold">
                <Link to={"/auth/registration"} className="">
                  {"Register"}
                  <i className="ki-duotone ki-arrow-right fs-2 text-warning">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Link>
                &nbsp;for free to get even more
              </span>
            }
            listItems={[
              { text: "We can get you better rates than going directly" },
              { text: "Save and return to your quotes anytime" },
              { text: "Book and speak to an adviser online" },
              { text: "Access to a wide range of tools and information" },
            ]}
          />
        </div>

        <div className="col-12 mb-5">
          <LogoBanner header="We cover the whole market" images={providers} />
        </div>
        {/* <div className="col-12">
          <FAQ
            section={[
              {
                title: "General",
                questions: [
                  {
                    to: "/",
                    question:
                      "Who are Pense and how do I know I'm safe with you?",
                  },
                  {
                    to: "/",
                    question: "What are your fees?",
                  },
                  {
                    to: "/",
                    question: "How much should I pay into my pension?",
                  },
                  {
                    to: "/",
                    question: "How much money do I need for retirement?",
                  },
                ],
              },
              {
                title: "Annuities & Retirement",
                questions: [
                  {
                    to: "/",
                    question: "What is an Annuity?",
                  },
                  {
                    to: "/",
                    question:
                      "What options are available if I decide to tane an Annuity?",
                  },
                  {
                    to: "/",
                    question: "What are the alternatives to Annuities?",
                  },
                  {
                    to: "/",
                    question: "Do I have to accept my pension company's offer?",
                  },
                ],
              },
              {
                title: "Equity Release",
                questions: [
                  {
                    to: "/",
                    question: "What is Equity Release?",
                  },
                  {
                    to: "/",
                    question: "What is a lifetime mortgage?",
                  },
                  {
                    to: "/",
                    question:
                      "Why are some property types not eligible for some providers?",
                  },
                  {
                    to: "/",
                    question:
                      "How do I know if Equity Release is right for me?",
                  },
                ],
              },
            ]}
          />
        </div> */}
      </div>
    </>
  );
};
