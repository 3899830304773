import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";

interface IProps {
  className?: String;
  createdBy: string;
  createdByJobTitle: string;
  imageSrc?: string;
  imageBase64?: string;
  subject?: string;
  note?: string;
  date: string;
}

export const JournalNote = (props: IProps) => {
  return (
    <div className={`card card-rounded ${props.className}`}>
      {/* begin::Body */}
      <div className="card-body pb-0">
        {/* begin::Header */}
        <div className="d-flex align-items-center mb-5">
          {/* begin::User */}
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin::Avatar */}
            <div className="symbol symbol-45px me-5">
              {props.imageBase64 ? (
                <img
                  className="rounded-circle"
                  src={"data:image/png;base64," + props.imageBase64}
                  alt=""
                />
              ) : (
                <img className="rounded-circle" src={props.imageSrc} alt="" />
              )}
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className="d-flex flex-column">
              <a
                href="#"
                className="text-gray-800 text-hover-primary fs-6 fw-bold"
              >
                {props.createdBy}
              </a>

              <span className="text-gray-400 fw-semibold">
                {props.createdByJobTitle}
              </span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}
          <div className="my-0">
            <span className="text-gray-400 fw-semibold">{props.date}</span>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className="mb-5">
          {/* begin::Text */}
          <p className="text-gray-800 fw-bold">{props.subject}</p>
          <p className="text-gray-800 fw-normal mb-10">{props.note}</p>
          {/* end::Text */}
        </div>
        {/* end::Post */}
      </div>
      {/* end::Body */}
    </div>
  );
};
