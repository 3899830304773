import React from "react";
import { useFormikContext } from "formik";

interface IProps {
  dependsOnFields: Array<IDependsOnField>;
  dependsOnConditional?: string;
}

interface IDependsOnField {
  fieldName: string;
  fieldValue: string | string[];
}

const getNestedValueWithIndex = (
  obj: any,
  path: string,
  index: number
): any => {
  const indexedPath = path.replace("[]", String(index));
  return indexedPath.split(".").reduce((acc, curr) => acc && acc[curr], obj);
};

const getNestedValue = (obj: any, path: string): any => {
  return path.split(".").reduce((acc, curr) => acc && acc[curr], obj);
};

const WithVisibility = <T extends {}>(
  WrappedComponent: React.ComponentType<T>,
  formikValues: Record<string, any>
) => {
  const ComponentWithVisibility: React.FC<T & IProps> = (props) => {
    let showCounter = 0;
    if (props.dependsOnFields?.length > 0) {
      props.dependsOnFields.forEach((field) => {
        //check if it's referencing an array field
        if (field.fieldName.includes("[]")) {
          //could potentially make better rather than a set amount, unlikely someone would enter 20 items but possible...
          //basically had to do it this way because the field array may contain an object that doesn't contain the property, we want it to just skip over this rather than break
          let MAX_ITERATIONS = 20;
          let index = 0;

          while (index < MAX_ITERATIONS) {
            const value = getNestedValueWithIndex(
              formikValues,
              field.fieldName,
              index
            );

            //if value matches, set hide to false and break
            if (Array.isArray(field.fieldValue)) {
              // For array field values, check if the value exists in the array
              if (field.fieldValue.includes(String(value))) {
                showCounter++;
              }
            } else {
              if (value === field.fieldValue) {
                showCounter++;
              }
            }
            index++;
          }
        } else {
          const fieldValue = getNestedValue(formikValues, field.fieldName);
          if (Array.isArray(field.fieldValue)) {
            // For array field values, check if the value exists in the array
            if (field.fieldValue.includes(String(fieldValue))) {
              showCounter++;
            }
          } else if (fieldValue == field.fieldValue) {
            showCounter++;
          }
        }
      });
    }

    if (props.dependsOnFields?.length > 0) {
      if (props.dependsOnConditional?.toLowerCase() == "and") {
        if (showCounter >= props.dependsOnFields.length) {
          return <WrappedComponent {...props} />;
        } else {
          return null;
        }
      } else {
        if (showCounter > 0) {
          return <WrappedComponent {...props} />;
        } else {
          return null;
        }
      }
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  return ComponentWithVisibility;
};

export default WithVisibility;
