import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { ReactElement } from "react";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  modalHeader?: string;
  modalFooter?:ReactElement;
  text?: string;
  fullscreen?: boolean;
}

export const Loader = (props: ModalProps) => {
  const {show, modalHeader, modalFooter, text, fullscreen} = props;

  return (
    <Modal
      id="editDataModal"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName={
        fullscreen
          ? "modal-dialog modal-dialog-centered modal-fullscreen"
          : "modal-dialog modal-dialog-centered mw-450px"
      }
      show={show}
      backdrop={true}
    >
      <div className="modal-header">
        { modalHeader ? (<h2>{modalHeader}</h2>) : null }
      </div>
      <div className="modal-body d-flex flex-column align-items-center justify-content-center">
          <Oval
            height={100}
            width={100}
            color="#0051d2"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#d9e5f8"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          {props.text ? (
            <div className="fs-1 fw-bold text-dark mt-20">{text}</div>
          ) : (
            <div className="fs-1 fw-bold text-dark mt-20"> Please wait...</div>
          )}
      </div>

      { modalFooter ? (
        <div className="modal-footer">
          {modalFooter}
        </div>
      ) : null }
    </Modal>
  );
};
