import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Formik, Form, FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import * as Yup from "yup";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Step1 } from "./steps/Step1OLD";
import { Step2 } from "./steps/Step2OLD";
import { usePortal } from "../../../context/PortalContext";
import { IClientData } from "../../../models/ClientData";
import { useNavigate } from "react-router-dom";
import {
  schema as page1Schema,
  header as page1Header,
  description as page1Desctiption,
  stepperDescription as page1StepperDescription,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuickQuotePage1";
import {
  schema as page2Schema,
  header as page2Header,
  description as page2Desctiption,
  stepperDescription as page2StepperDescription,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuickQuotePage2";
import { ObjectShape } from "yup/lib/object";
import { StepperForm } from "../../../modules/common/other/StepperForm";
import { CTA1 } from "../../../modules/common/cards/CTA1";

export const QuickQuote = () => {
  <PageTitle>Quick Quote</PageTitle>;

  const { clientData, setClientData }: any = usePortal();
  const [quotesComplete, setQuotesComplete] = useState(false);
  const navigate = useNavigate();

  let initialData = {
    annuity: {
      pensionFund: undefined,
      plannedRetirementDate: undefined,
    },
    title: undefined,
    firstName: undefined,
    lastName: undefined,
    dateOfBirth: undefined,
    postcode: undefined,
    medicalInformation: {
      regularDrinkerYesNo: undefined,
      regularSmoker: undefined,
      weightIssuesYesNo: undefined,
    },
  };

  if (clientData) {
    initialData = clientData;
  }

  interface Schema<T extends ObjectShape = any> {
    fields: {
      [key: string]: T;
    };
  }

  interface IDynamicSchemaCondition {
    fieldName: string;
    fieldValue: any;
  }

  interface FormSchema {
    header: string;
    description: string;
    stepperDescription: string;
    schema: Schema;
    isPerson2: boolean;
    pageNumber: number;
    dynamicSchemaConditions?: Array<IDynamicSchemaCondition>;
  }

  const formSchemas: FormSchema[] = [];

  formSchemas.push({
    header: page1Header,
    description: page1Desctiption,
    schema: page1Schema,
    stepperDescription: page1StepperDescription,
    isPerson2: false,
    pageNumber: 0,
  });

  formSchemas.push({
    header: page2Header,
    description: page2Desctiption,
    schema: page2Schema,
    stepperDescription: page2StepperDescription,
    isPerson2: false,
    pageNumber: 1,
  });

  async function submit(values: any) {
    await setClientData(values);
    navigate("/quickquote/annuityquotes");
  }

  return (
    <>
      <StepperForm
        data={initialData}
        formSchemas={formSchemas}
        handleSubmit={submit}
        navigateAfterSubmitTo={undefined}
        TopContent={
          <div className="mb-10">
            <CTA1
              title={<div className="mb-5">Quick quote</div>}
              content={
                <>
                  <div className="fs-4 text-gray-800">
                    Curious about the potential income your pension can provide
                    at retirement? <br />
                    Get some quick, illustrative quotes* to find out,{" "}
                    <span className="fw-bolder text-black">
                      it takes less than 60 seconds.
                    </span>
                  </div>
                  <div className="small mt-10">
                    *We will make some assumptions based on the average person.
                  </div>
                </>
              }
              imageSrc="media/illustrations/bw/png/moneybox.png"
            />
          </div>
        }
      />
    </>
  );
};
