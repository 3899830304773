import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const NavCard = (props: IProps) => {
  const location = useLocation();
  return (
    <>
      <div className="card card-rounded text-left p-5 mb-5 mb-xl-10">
        <ul className="nav flex-wrap border-transparent fw-bold">
          {props.links?.map((item, index) => {
            return (
              <li key={item.label} className="nav-item my-1">
                <Link
                  className={
                    `btn btn-color-gray-600 btn-active-secondary btn-active-color-dark fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ` +
                    (location.pathname === item.to && "active")
                  }
                  to={item.to}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
        {props.buttons?.map((item, index) => {
          return (
            <div key={item.label} className="btn btn-lg btn-primary">
              <Link to={item.to}></Link>
              {item.label}
            </div>
          );
        })}
      </div>
    </>
  );
};

export { NavCard };

interface IProps {
  links: ILinks[] | null;
  buttons: IButtons[] | null;
}
interface ILinks {
  label: string;
  to: string;
}

interface IButtons {
  label: string;
  to: string;
  className: string | null;
}
