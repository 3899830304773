/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
interface IProps {
  className?: string;
  header: string;
  content: ReactNode;
  buttonId?: string;
  listItems: IListItem[];
}

interface IListItem {
  text: string;
}

export const CheckBoxCTA2 = (props: IProps) => {
  return (
    <div className="card card-rounded card-flush">
      {/* begin::Body */}
      <div className="card-body">
        <div className="row align-items-center my-10 mx-10">
          <div className="col-md-6 col-sm-12 mb-5 ps-10">{props.content}</div>

          <div className="col-md-6 col-sm-12 mt-5 ps-10">
            <div className="text-black fw-bold fs-2 ">
              <h2 className="fw-bolder mb-5">{props.header}</h2>
            </div>
            <div>
              {props.listItems.map((item, index) => {
                return (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <div className="symbol symbol-20px me-5">&#x2022;</div>
                    <div className="d-flex flex-column">
                      <div className="text-dark fs-6">{item.text}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};
