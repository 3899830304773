import * as Yup from "yup";
import { Notice } from "../../../common/cards/Notice";
import { typeFormatters } from "../../Formatters";
import { fieldValidation } from "../../Validators";

export const AnnuityQuotePage1Schema = Yup.object().shape({
  annuity: Yup.object().shape({
    pensionFund: Yup.string()
      .label("Pension Fund")
      .required(
        "An estimated value of your pension fund is required to get quotes."
      )
      .transform(typeFormatters["currency"])
      .test(
        "is-valid-pensionFundValue",
        "This must be a valid currency amount between £10,000 and £10,000,000.",
        (value) => fieldValidation(value, "currency", 10000, 10000000, true)
      )
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label:
              "What is the approximate current value of your pension fund(s)?",
            description:
              "If you have multiple pensions, please combine them and enter that amount",
            name: "annuity.pensionFund",
            required: true,
            placeholder: "",
            inputType: "text",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: false,
            formatType: "currency",
          },
        },
      }),

    plannedRetirementDate: Yup.string()
      .label("Planned Retirement Date")
      .required("Please tell us the month and year you are planning to retire.")
      .meta({
        control: {
          componentType: "DateMMYYYY",
          props: {
            name: "annuity.plannedRetirementDate",
            label: "When are you planning on retiring?",
            required: true,
            description: "",
            tooltip: {
              button: (
                <a
                  className="btn btn-small btn-outline form-text"
                  data-bs-toggle="collapse"
                  data-bs-target="#plannedRetirementDate"
                  aria-expanded="false"
                  aria-controls="plannedRetirementDate"
                >
                  Why?
                </a>
              ),
              notice: (
                <div className="collapse pb-5 pt-3" id="plannedRetirementDate">
                  <Notice
                    noticeClasses={"bg-light-warning"}
                    icon={undefined}
                    iconClasses={null}
                    header={undefined}
                    content={
                      "Letting us know when you plan on retiring can help us tailor your journey to what you need"
                    }
                    button={null}
                  />
                </div>
              ),
            },
            placeholder: "",
            prefix: undefined,
            startYear: new Date().getFullYear(),
            endYear: new Date().getFullYear() + 10,
            addSeparatorBelow: true,
          },
        },
      }),
  }),
});
