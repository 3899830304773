import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
    config: {
      // Dev
      instrumentationKey: '36136e94-e6c2-42e1-958e-35eb6d3b21a2',

      // Live
      // instrumentationKey: '39a0fe35-d852-45b7-a042-321e9852d574',
    },
  });
  
appInsights.loadAppInsights();
appInsights.trackPageView(); // Optional: Track page views

export default appInsights;