import React, { useEffect, useState } from "react";
import { PageTitle } from "../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import { usePortal } from "../../context/PortalContext";
import { StepperForm } from "../../modules/common/other/StepperForm";
import { ObjectShape } from "yup/lib/object";
import {
  schema as page1Schema,
  header as page1Header,
  description as page1Description,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PersonalDetails";
import {
  schema as page2Schema,
  header as page2Header,
  description as page2Description,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PensionDetails";

import {
  person1MedicalSchema,
  header as page3Header,
  description as page3Description,
} from "../../modules/forms/form_schemas/AnnuityFactfind/MedicalDetails";

import { person2MedicalSchema } from "../../modules/forms/form_schemas/AnnuityFactfind/MedicalDetails";

import {
  schema as page4Schema,
  header as page4Header,
  description as page4Description,
} from "../../modules/forms/form_schemas/AnnuityFactfind/EmploymentDetails";

import {
  schema as page5Schema,
  header as page5Header,
  description as page5Description,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PropertyDetails";

import { Loader } from "../../modules/common/modals/LoaderModal";
import { Notice } from "../../modules/common/cards/Notice";
import { KTSVG } from "../../_metronic/helpers";
import { CTA1 } from "../../modules/common/cards/CTA1";
import appInsights from "../../azureAppInsights";

interface FormSchema {
  header: string;
  description: string;
  stepperDescription?: string;
  schema: Schema;
  isPerson2: boolean;
  pageNumber: number;
  dynamicSchemaConditions?: Array<IDynamicSchemaCondition>;
}

interface IDynamicSchemaCondition {
  fieldName: string;
  fieldValue: any;
}

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

export const Factfind = () => {
  <PageTitle>Retirement Fact Find</PageTitle>;

  const {
    returnClientData,
    apiUpdateClientData,
    clientData,
    setClientData,
  }: any = usePortal();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(undefined);

  useEffect(() => {
    if (!clientData) {
      const fetchClientData = async () => {
        let responseCode = await returnClientData();

        if (responseCode !== 200) {
          navigate(`/error/${responseCode}`);
        }
      };

      fetchClientData();
    } else {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (clientData) {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }

    console.log(clientData);
  }, [clientData]);

  const formSchemas: FormSchema[] = [];

  formSchemas.push({
    header: page1Header,
    description: page1Description,
    schema: page1Schema,
    isPerson2: false,
    pageNumber: 0,
  });

  formSchemas.push({
    header: page2Header,
    description: page2Description,
    schema: page2Schema,
    isPerson2: false,
    pageNumber: 1,
  });

  formSchemas.push({
    header: page3Header,
    description: page3Description,
    schema: person1MedicalSchema,
    isPerson2: false,
    pageNumber: 2,
  });

  formSchemas.push({
    header: "Person 2 Medical details",
    description:
      "Tell us about your significant others lifestyle, medical conditions and any medications",
    schema: person2MedicalSchema,
    isPerson2: true,
    pageNumber: 3,
    dynamicSchemaConditions: [
      {
        fieldName: "client1OrJoint",
        fieldValue: "1496",
      },
    ],
  });

  formSchemas.push({
    header: page4Header,
    description: page4Description,
    schema: page4Schema,
    isPerson2: false,
    pageNumber: 4,
  });

  formSchemas.push({
    header: page5Header,
    description: page5Description,
    schema: page5Schema,
    isPerson2: false,
    pageNumber: 5,
  });

  async function submit(values: any) {
    let updatedValues = {
      ...values,
      annuity: {
        ...values.annuity,
        factfindCompleted: "true",
      },
    };

    try {
      console.log("updating please wait.");
      const responseCode = await apiUpdateClientData(updatedValues);

      if (responseCode !== 200) {
        // Reset the client data state - there was an error with the API so we don't know that our previous update was successful
        setClientData(values);
        navigate(`/error/${responseCode}`);
      } else {
        navigate("/factfindcompleted");
      }
    } catch (error: any) {
      console.error(error);

      // Reset the client data state - there was an error with the API so we don't know that our previous update was successful
      setClientData(values);

      let appInsightsError: Error = {
        name: "FactFind - apiUpdateClientData Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      navigate(`/error/${errorCode}`);
    }
  }

  return (
    <>
      {clientData ? (
        <StepperForm
          data={clientData}
          formSchemas={formSchemas}
          handleSubmit={submit}
          navigateAfterSubmitTo={undefined}
          SaveAndExitButton={false}
          TopContent={
            <div className="mb-10">
              <CTA1
                title={<div className="mb-5">Your fact find</div>}
                content={
                  <>
                    <div className="fs-4 text-gray-800">
                      Before you can speak with one of our advisers,
                      <br />
                      we need you to provide further information about yourself.
                    </div>
                  </>
                }
                tooltip={{
                  button: (
                    <a
                      className="btn btn-small btn-outline form-text"
                      style={{ maxWidth: "500px" }}
                      data-bs-toggle="collapse"
                      data-bs-target="#factfindTooltip"
                      aria-expanded="false"
                      aria-controls="factfindTooltip"
                    >
                      Why do you need more information from me?
                    </a>
                  ),
                  notice: (
                    <div className="collapse pb-5 pt-3" id="factfindTooltip">
                      <Notice
                        noticeClasses={"bg-light-warning"}
                        icon={undefined}
                        iconClasses={null}
                        header={undefined}
                        content={
                          <div style={{ maxWidth: "500px" }}>
                            The more we know about your current situation,
                            retirement objectives, and goals, the better we can
                            advise you on suitable products and solutions. As a
                            Trustpilot 5 star rated company, you can be sure
                            that your financial well-being is our top priority.
                          </div>
                        }
                        button={null}
                      />
                    </div>
                  ),
                }}
                imageSrc="media/illustrations/bw/png/file-manager.png"
              />
            </div>
          }
          FormFootercontent={
            clientData?.annuity?.status != "Portal" && (
              <>
                <Notice
                  noticeClasses={"mt-10 bg-light-warning border-warning"}
                  icon={
                    <KTSVG
                      path="/media/icons/duotune/general/gen047.svg"
                      className="svg-icon svg-icon-3x svg-icon-dark"
                    />
                  }
                  iconClasses={""}
                  header={"Record locked"}
                  content={
                    <>
                      <div className="mt-5">
                        Changes will not be saved, if you need to make any
                        changes to your details, please contact us on{" "}
                        <span className="text-dark text-bolder">
                          {" "}
                          0808 281 2558.
                        </span>
                      </div>
                    </>
                  }
                  button={null}
                />
              </>
            )
          }
        />
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </>
  );
};
