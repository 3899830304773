import * as Yup from "yup";
import { Notice } from "../../../common/cards/Notice";
import { fieldValidation } from "../../Validators";

const header = "About You";

const description =
  "Tell us a little about yourself (no contact details needed!).";

const stepperDescription =
  "Tell us a little about yourself (no contact details needed!).";

const schema = Yup.object().shape({
  title: Yup.string()
    .label("Title")
    .required("Please specify your title.")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Title",
          description: "",
          name: "title",
          tooltip: "",
          required: true,
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
      },
    }),
  firstName: Yup.string()
    .label("First Name")
    .required("Please enter your first name.")
    .test(
      "is-valid-firstName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "First Name",
          description: "",
          name: "firstName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  lastName: Yup.string()
    .label("Last Name")
    .required("Please enter your last name.")
    .test(
      "is-valid-lastName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Last Name",
          description: "",
          name: "lastName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  dateOfBirth: Yup.string()
    .label("Date of Birth")
    .required("Please enter your date of birth.")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "dateOfBirth",
          label: "Your Date of Birth",
          required: true,
          description: "You must be over 55 to get quotes.",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1930,
          endYear: new Date().getFullYear() - 50,
        },
      },
    }),

  postcode: Yup.string()
    .label("Postcode")
    .required("Please enter a valid UK postcode.")
    .test("is-valid-postcode", "Please enter a valid UK postcode.", (value) =>
      fieldValidation(value, "postcode", undefined, undefined, true)
    )
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Your Postcode",
          description: "",
          name: "postcode",
          required: true,
          placeholder: "",
          tooltip: {
            button: (
              <a
                className="btn btn-small btn-outline form-text"
                data-bs-toggle="collapse"
                data-bs-target="#postcodeTooltip"
                aria-expanded="false"
                aria-controls="postcodeTooltip"
              >
                Why?
              </a>
            ),
            notice: (
              <div className="collapse pb-5 pt-3" id="postcodeTooltip">
                <Notice
                  noticeClasses={"bg-light-warning"}
                  icon={undefined}
                  iconClasses={null}
                  header={undefined}
                  content={
                    <div>
                      Where you live can have an impact on the figures in the
                      quotes.
                    </div>
                  }
                  button={null}
                />
              </div>
            ),
          },
          inputType: "text",
          prefix: undefined,
          addSeparatorBelow: true,
          formatType: "postcode",
        },
      },
    }),

  medicalInformation: Yup.object().shape({
    regularDrinkerYesNo: Yup.string()
      .label("Regular Drinker")
      .required("Please specify if you are a regular drinker.")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Are you or have you ever been a regular drinker?",
            description: "",
            name: "medicalInformation.regularDrinkerYesNo",
            tooltip: {
              button: (
                <a
                  className="btn btn-small btn-outline form-text"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularDrinkerYesNo"
                  aria-expanded="false"
                  aria-controls="regularDrinkerYesNo"
                >
                  Why?
                </a>
              ),
              notice: (
                <div className="collapse pb-5 pt-3" id="regularDrinkerYesNo">
                  <Notice
                    noticeClasses={"bg-light-warning"}
                    icon={undefined}
                    iconClasses={null}
                    header={undefined}
                    content={
                      <div>
                        If you drink regularly, this can increase your quotes.
                        Find out more about Enhanced Annuities{" "}
                        <a
                          href="https://www.pense.co.uk/enhanced-annuities"
                          target="_blank"
                        >
                          here
                        </a>
                        .{" "}
                      </div>
                    }
                    button={null}
                  />
                </div>
              ),
            },
          },
        },
      }),
    regularSmoker: Yup.string()
      .label("Regular Smoker")
      .required("Please specify if you are a regular smoker.")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Are you or have you ever been a regular smoker?",
            description: "",
            name: "medicalInformation.regularSmoker",
            tooltip: {
              button: (
                <a
                  className="btn btn-small btn-outline form-text"
                  data-bs-toggle="collapse"
                  data-bs-target="#regularSmoker"
                  aria-expanded="false"
                  aria-controls="regularSmoker"
                >
                  Why?
                </a>
              ),
              notice: (
                <div className="collapse pb-5 pt-3" id="regularSmoker">
                  <Notice
                    noticeClasses={"bg-light-warning"}
                    icon={undefined}
                    iconClasses={null}
                    header={undefined}
                    content={
                      <div>
                        If you smoke regularly or have done in the past, this
                        can increase your quotes. Find out more about Enhanced
                        Annuities{" "}
                        <a
                          href="https://www.pense.co.uk/enhanced-annuities"
                          target="_blank"
                        >
                          here
                        </a>
                        .{" "}
                      </div>
                    }
                    button={null}
                  />
                </div>
              ),
            },
          },
        },
      }),
    weightIssuesYesNo: Yup.string()
      .label("Weight Problems")
      .required(
        "Please specify if you have difficulty maintaining a healthy weight."
      )
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Do you struggle with maintaining a healthy weight?",
            description: "",
            name: "medicalInformation.weightIssuesYesNo",
            tooltip: {
              button: (
                <a
                  className="btn btn-small btn-outline form-text"
                  data-bs-toggle="collapse"
                  data-bs-target="#weightIssuesYesNo"
                  aria-expanded="false"
                  aria-controls="weightIssuesYesNo"
                >
                  Why?
                </a>
              ),
              notice: (
                <div className="collapse pb-5 pt-3" id="weightIssuesYesNo">
                  <Notice
                    noticeClasses={"bg-light-warning"}
                    icon={undefined}
                    iconClasses={null}
                    header={undefined}
                    content={
                      <div>
                        If you struggle to maintain a healthy weight, this can
                        increase your quotes. Find out more about Enhanced
                        Annuities{" "}
                        <a
                          href="https://www.pense.co.uk/enhanced-annuities"
                          target="_blank"
                        >
                          here
                        </a>
                        .{" "}
                      </div>
                    }
                    button={null}
                  />
                </div>
              ),
            },
          },
        },
      }),
  }),
});

export { header, description, schema, stepperDescription };
