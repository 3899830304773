import * as Yup from "yup";
import { typeFormatters } from "../../Formatters";
import { fieldValidation } from "../../Validators";

export const medicalConditionSchema = Yup.object().shape({
  // General
  conditionType: Yup.string()
    .label("Condition Type")
    .required("Please specify what type of condition this is."),

  conditionName: Yup.string()
    .label("Condition Name")
    .nullable()
    .when(["conditionType", "heartConditionType"], {
      is: ([conditionType, heartConditionType]: [string, string]) =>
        ["208", "212", "210", "214"].includes(conditionType) &&
        (heartConditionType === "6004" || heartConditionType === null),
      then: Yup.string()
        .required("Please specify the name of this condition.")
        .notOneOf(["0", ""], "Please select an option"),
    })
    .when(["conditionType", "neurologicalType"], {
      is: (conditionType: string, neurologicalType: string) =>
        conditionType === "216" && neurologicalType === "6019",
      then: Yup.string()
        .required("Please specify the name of this condition.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  dateDiagnosed: Yup.string()
    .label("Date Diagnosed")
    .nullable()
    .when("conditionType", {
      is: (val: string) =>
        [
          "208",
          "210",
          "211",
          "212",
          "213",
          "214",
          "215",
          "218",
          "219",
        ].includes(val),
      then: Yup.string().required(
        "Please specify when this condition was diagnosed."
      ),
    }),

  lastSufferedSymptoms: Yup.string()
    .label("When did you last suffer symptoms?")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "208",
      then: Yup.string()
        .required(
          "Please provide the date that you last suffered any symptoms from this condition."
        )
        .notOneOf(["0", ""], "Please select an option"),
    }),

  customerProvidedMedicalConditionName: Yup.string()
    .label("Condition Name")
    .nullable()
    .when("conditionName", {
      is: (val: string) => val === "Unknown",
      then: Yup.string().required("Please enter the name of the condition."),
    }),

  numberOfHospitalAdmissions: Yup.string()
    .label("Number Of Hospital Admissions")
    .nullable(),

  subTypeID: Yup.string()
    .label("Sub Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "215",
      then: Yup.string()
        .required("Please specify the sub-type of the condition.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  // High Blood Pressure
  lastSystolicReading: Yup.string()
    .label("Last Systolic Reading")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "218",
      then: Yup.string()
        .required(
          "Please provide the Systolic reading value (This should be the higher of the two readings)."
        )
        .transform(typeFormatters["integer"])
        .test(
          "is-valid-systolicReading",
          "Please enter a valid reading value between 100 - 300.",
          (value) => fieldValidation(value, "integer", 100, 300, true)
        ),
    }),

  lastDiastolicReading: Yup.string()
    .label("Last Diastolic Reading")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "218",
      then: Yup.string()
        .required(
          "Please provide the Diastolic reading value (This should be the lower of the two readings)."
        )
        .transform(typeFormatters["integer"])
        .test(
          "is-valid-diastolicReading",
          "Please enter a valid reading value between 50 - 300.",
          (value) => fieldValidation(value, "integer", 50, 300, true)
        ),
    }),

  // High Cholesterol
  readingMmol: Yup.string()
    .label("Last Cholesterol Reading (mmol)")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "219",
      then: Yup.string()
        .transform(typeFormatters["decimal"])
        .test(
          "is-valid-cholesterol",
          "Please enter a valid mmol reading (e.g. between 1 and 30).",
          (value) =>
            !value ||
            fieldValidation(value, "decimal", 1, 30, false) ||
            value === ""
        ),
    }),

  // Heart Condition
  heartConditionType: Yup.string()
    .label("Heart Condition Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "210",
      then: Yup.string().required(
        "Please specify what type of heart condition this is."
      ),
    }),

  noOfOccurrences: Yup.string()
    .label("Number of Occurrences")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "210",
      then: Yup.string()
        .required("Please specify how many times this has occurred.")
        .transform(typeFormatters["integer"])
        .test(
          "is-valid-occurrences",
          "Please enter a valid integer (e.g. 1–99).",
          (value) => fieldValidation(value, "integer", 0, 99, true)
        ),
    }),

  conditionOngoing: Yup.string()
    .label("Is the condition ongoing?")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "210",
      then: Yup.string().required(
        "Please specify if the heart condition is ongoing."
      ),
    }),

  // Diabetes
  diabetesType: Yup.string()
    .label("Diabetes Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string()
        .required("Please tell us what type of Diabetes it is.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  diabetesControlMethod: Yup.string()
    .label("How Is It Controlled?")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string()
        .required("Please tell us how you control your Diabetes.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  diabetesTestingFrequencyID: Yup.string()
    .label("Testing Frequency")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  readingHbA1c: Yup.string()
    .label("Last HbA1c Reading")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string()
        .transform(typeFormatters["decimal"])
        .test(
          "is-valid-hba1c",
          "Please enter a valid HbA1c reading if known.",
          (value) =>
            !value ||
            fieldValidation(value, "decimal", 0.1, 200, false) ||
            value === ""
        ),
    }),

  hbA1cUnitID: Yup.string()
    .label("HbA1c Unit")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  diabetesNoOfTests: Yup.string()
    .label("Number of Blood Glucose Tests")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string()
        .transform(typeFormatters["integer"])
        .test(
          "is-valid-num-tests",
          "Please enter a valid number of tests if known.",
          (value) =>
            !value ||
            fieldValidation(value, "integer", 1, 99, false) ||
            value === ""
        ),
    }),

  penultimateReadingKnown: Yup.string()
    .label("Penultimate Reading Known")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().required(
        "Please specify if you know your reading prior to the last one."
      ),
    }),

  penultimateReadingHbA1c: Yup.string()
    .label("Penultimate HbA1c Reading")
    .nullable()
    .when(["conditionType", "PenultimateReadingKnown"], {
      is: (conditionType: string, known: string) =>
        conditionType === "211" && known === "true",
      then: Yup.string()
        .transform(typeFormatters["decimal"])
        .test(
          "is-valid-penult-hba1c",
          "Please enter a valid HbA1c reading.",
          (value) =>
            !value ||
            fieldValidation(value, "decimal", 0.1, 200, false) ||
            value === ""
        ),
    }),

  penultimateReading: Yup.string()
    .label("Penultimate Reading Date")
    .nullable()
    .when(["conditionType", "PenultimateReadingKnown"], {
      is: (conditionType: string, known: string) =>
        conditionType === "211" && known === "true",
      then: Yup.string().nullable(),
    }),

  heartDisease: Yup.string()
    .label("Heart Disease")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  eyeDisease: Yup.string()
    .label("Retinopathy")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  kidneyDisease: Yup.string()
    .label("Kidney Disease")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  amputation: Yup.string()
    .label("Amputation")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  circulation: Yup.string()
    .label("Circulation Issues")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  neuropathy: Yup.string()
    .label("Neuropathy")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  ulceration: Yup.string()
    .label("Ulceration")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "211",
      then: Yup.string().nullable(),
    }),

  // Cancer
  tumourLocation: Yup.string()
    .label("Tumour Location")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string()
        .required("Please specify the location of the tumour.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  tumourType: Yup.string()
    .label("Tumour Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string()
        .required("Please specify the type of tumour.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  generalGradeID: Yup.string()
    .label("General Grade")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string()
        .required("Please specify the grade of the tumour.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  stageTypeID: Yup.string()
    .label("Classification Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string()
        .required("Please specify the classification type.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  spreadTypeID: Yup.string()
    .label("Nature Of Tumour")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string()
        .required("Please specify the nature of the tumour.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  discharged: Yup.string()
    .label("Have you been discharged?")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string().required(
        "Please specify if you have been discharged."
      ),
    }),

  surgeryDate: Yup.string()
    .label("Surgery Date")
    .nullable()
    .when(["conditionType", "surgery"], {
      is: (conditionType: string, surgery: string) =>
        conditionType === "212" && surgery === "true",
      then: Yup.string().required("Please specify your surgery date."),
    }),

  typeOfSurgery: Yup.string()
    .label("Type of Surgery")
    .nullable()
    .when(["conditionType", "surgery"], {
      is: (conditionType: string, surgery: string) =>
        conditionType === "212" && surgery === "true",
      then: Yup.string().required("Please specify the surgery type."),
    }),

  generalStageTypeID: Yup.string()
    .label("General Stage Type")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5964" && conditionType === "212",
      then: Yup.string()
        .required("Please select a General Stage Type")
        .notOneOf(["0", ""], "Please select a valid General Stage Type"),
    }),

  generalSubStageTypeID: Yup.string()
    .label("General Sub-Stage Type")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5964" && conditionType === "212",
      then: Yup.string()
        .required("Please select a General Sub-Stage Type")
        .notOneOf(["0", ""], "Please select a valid General Sub-Stage Type"),
    }),

  annArborClassificationID: Yup.string()
    .label("Ann Arbor Classification")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5963" && conditionType === "212",
      then: Yup.string()
        .required("Please select an Ann Arbor Classification")
        .notOneOf(["0", ""], "Please select a valid Ann Arbor Classification"),
    }),

  breslowThicknessID: Yup.string()
    .label("Breslow Thickness")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5962" && conditionType === "212",
      then: Yup.string()
        .required("Please select a Breslow Thickness")
        .notOneOf(["0", ""], "Please select a valid Breslow Thickness"),
    }),

  clarkLevelID: Yup.string()
    .label("Clark Level")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5961" && conditionType === "212",
      then: Yup.string()
        .required("Please select a Clark Level")
        .notOneOf(["0", ""], "Please select a valid Clark Level"),
    }),

  dukesClassificationID: Yup.string()
    .label("Dukes Classification")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5957" && conditionType === "212",
      then: Yup.string()
        .required("Please select a Dukes Classification")
        .notOneOf(["0", ""], "Please select a valid Dukes Classification"),
    }),

  fIGOClassificationID: Yup.string()
    .label("FIGO Classification")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5960" && conditionType === "212",
      then: Yup.string()
        .required("Please select a FIGO Classification")
        .notOneOf(["0", ""], "Please select a valid FIGO Classification"),
    }),

  mACClassificationID: Yup.string()
    .label("MAC Classification")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5959" && conditionType === "212",
      then: Yup.string()
        .required("Please select a MAC Classification")
        .notOneOf(["0", ""], "Please select a valid MAC Classification"),
    }),

  tNMTStageTypeID: Yup.string()
    .label("TNM T Stage")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5958" && conditionType === "212",
      then: Yup.string()
        .required("Please select a TNM T Stage")
        .notOneOf(["0", ""], "Please select a valid TNM T Stage"),
    }),

  tNMTStageSubTypeID: Yup.string()
    .label("TNM T Stage Sub-Type")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5958" && conditionType === "212",
      then: Yup.string()
        .required("Please select a TNM T Stage Sub-Type")
        .notOneOf(["0", ""], "Please select a valid TNM T Stage Sub-Type"),
    }),

  tNMNStageTypeID: Yup.string()
    .label("TNM N Stage")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5958" && conditionType === "212",
      then: Yup.string()
        .required("Please select a TNM N Stage")
        .notOneOf(["0", ""], "Please select a valid TNM N Stage"),
    }),

  tNMNStageSubTypeID: Yup.string()
    .label("TNM N Stage Sub-Type")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5958" && conditionType === "212",
      then: Yup.string()
        .required("Please select a TNM N Stage Sub-Type")
        .notOneOf(["0", ""], "Please select a valid TNM N Stage Sub-Type"),
    }),

  tNMMStageTypeID: Yup.string()
    .label("TNM M Stage")
    .nullable()
    .when(["stageTypeID", "conditionType"], {
      is: (stageTypeID: string, conditionType: string) =>
        stageTypeID === "5958" && conditionType === "212",
      then: Yup.string()
        .required("Please select a TNM M Stage")
        .notOneOf(["0", ""], "Please select a valid TNM M Stage"),
    }),

  radiotherapy: Yup.string()
    .label("Radiotherapy")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string().nullable(),
    }),

  radiotherapyStartDate: Yup.string()
    .label("Radiotherapy Start Date")
    .nullable()
    .when(["conditionType", "radiotherapy"], {
      is: (conditionType: string, therapy: string) =>
        conditionType === "212" && therapy === "true",
      then: Yup.string().nullable(),
    }),

  radiotherapyEndDate: Yup.string()
    .label("Radiotherapy End Date")
    .nullable()
    .when(["conditionType", "radiotherapy"], {
      is: (conditionType: string, therapy: string) =>
        conditionType === "212" && therapy === "true",
      then: Yup.string().nullable(),
    }),

  chemotherapy: Yup.string()
    .label("Chemotherapy")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string().nullable(),
    }),

  chemotherapyStartDate: Yup.string()
    .label("Chemotherapy Start Date")
    .nullable()
    .when(["conditionType", "chemotherapy"], {
      is: (conditionType: string, chemo: string) =>
        conditionType === "212" && chemo === "true",
      then: Yup.string().nullable(),
    }),

  chemotherapyEndDate: Yup.string()
    .label("Chemotherapy End Date")
    .nullable()
    .when(["conditionType", "chemotherapy"], {
      is: (conditionType: string, chemo: string) =>
        conditionType === "212" && chemo === "true",
      then: Yup.string().nullable(),
    }),

  boneMarrow: Yup.string()
    .label("Bone Marrow / Stem Cell Treatment")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string().nullable(),
    }),

  boneMarrowStartDate: Yup.string()
    .label("Bone Marrow Start Date")
    .nullable()
    .when(["conditionType", "boneMarrow"], {
      is: (conditionType: string, bm: string) =>
        conditionType === "212" && bm === "true",
      then: Yup.string().nullable(),
    }),

  boneMarrowEndDate: Yup.string()
    .label("Bone Marrow End Date")
    .nullable()
    .when(["conditionType", "boneMarrow"], {
      is: (conditionType: string, bm: string) =>
        conditionType === "212" && bm === "true",
      then: Yup.string().nullable(),
    }),

  hormoneTherapy: Yup.string()
    .label("Hormone Therapy")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string().nullable(),
    }),

  hormoneTherapyStartDate: Yup.string()
    .label("Hormone Therapy Start Date")
    .nullable()
    .when(["conditionType", "hormoneTherapy"], {
      is: (conditionType: string, ht: string) =>
        conditionType === "212" && ht === "true",
      then: Yup.string().nullable(),
    }),

  hormoneTherapyEndDate: Yup.string()
    .label("Hormone Therapy End Date")
    .nullable()
    .when(["conditionType", "hormoneTherapy"], {
      is: (conditionType: string, ht: string) =>
        conditionType === "212" && ht === "true",
      then: Yup.string().nullable(),
    }),

  numberOfNodesAffected: Yup.string()
    .label("Number of Nodes Affected")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string()
        .transform(typeFormatters["integer"])
        .test(
          "is-valid-nodes",
          "Please enter a valid number if nodes are affected.",
          (value) =>
            !value ||
            fieldValidation(value, "integer", 1, 99, false) ||
            value === ""
        ),
    }),

  nodeLocationsID: Yup.array()
    .of(Yup.string())
    .label("Node Locations")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.array().nullable(),
    }),

  anyRecurrences: Yup.string()
    .label("Any Recurrences")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "212",
      then: Yup.string().required(
        "Please specify whether you have had any recurrences."
      ),
    }),

  recurrenceDate: Yup.string()
    .label("Recurrence Date")
    .nullable()
    .when(["conditionType", "anyRecurrences"], {
      is: (conditionType: string, rec: string) =>
        conditionType === "212" && rec === "true",
      then: Yup.string().required("Please specify the date of recurrence."),
    }),

  pSALevelID: Yup.string()
    .label("PSA Level ID")
    .nullable()
    .when(["conditionType", "conditionName"], {
      is: (conditionType: string, conditionName: string) =>
        conditionType === "212" &&
        [
          "Malignant Neoplasm Of Prostate",
          "Malignant Neoplasm Prostate",
          "Prostate Calculus",
          "Prostate Cancer",
          "Prostate Enlarged",
          "Prostate Hyperplasia",
          "Prostate Hypertrophy",
          "Prostate Neoplasm",
          "Prostate Neoplasm Malignant",
          "Prostate Neoplasm Unknown",
          "Inflammatory Prostate Disease",
          "Hyperplasia Of Prostate",
          "Enlarged Prostate",
          "Enlarge Prostate",
          "Carcinoma Prostate",
          "Carcinoma Of Prostate",
          "Carcinoma In Situ Of Prostate",
          "Cancer Of Prostate",
          "Ca Prostate",
          "Benign Tumor Of Prostate",
          "Benign Prostate Tumour",
          "Benign Neoplasm Of Prostate",
          "Adenocarcinoma Prostate",
          "Adeno Carcinoma Prostate",
          "Adeno CA Prostate",
          "Prostate Tuberculosis",
        ].includes(conditionName),
      then: Yup.string().nullable(),
    }),

  pSALevelResult: Yup.string()
    .label("PSA Level Result")
    .nullable()
    .when(["conditionType", "conditionName"], {
      is: (conditionType: string, conditionName: string) =>
        conditionType === "212" &&
        [
          "Malignant Neoplasm Of Prostate",
          "Malignant Neoplasm Prostate",
          "Prostate Calculus",
          "Prostate Cancer",
          "Prostate Enlarged",
          "Prostate Hyperplasia",
          "Prostate Hypertrophy",
          "Prostate Neoplasm",
          "Prostate Neoplasm Malignant",
          "Prostate Neoplasm Unknown",
          "Inflammatory Prostate Disease",
          "Hyperplasia Of Prostate",
          "Enlarged Prostate",
          "Enlarge Prostate",
          "Carcinoma Prostate",
          "Carcinoma Of Prostate",
          "Carcinoma In Situ Of Prostate",
          "Cancer Of Prostate",
          "Ca Prostate",
          "Benign Tumor Of Prostate",
          "Benign Prostate Tumour",
          "Benign Neoplasm Of Prostate",
          "Adenocarcinoma Prostate",
          "Adeno Carcinoma Prostate",
          "Adeno CA Prostate",
          "Prostate Tuberculosis",
        ].includes(conditionName),
      then: Yup.string()
        .transform(typeFormatters["decimal"])
        .test(
          "is-valid-psa",
          "Please enter a valid PSA reading if known.",
          (value) =>
            !value ||
            fieldValidation(value, "decimal", 0.1, 1000, false) ||
            value === ""
        ),
    }),

  pSALevelDate: Yup.string()
    .label("PSA Level Date")
    .nullable()
    .when(["conditionType", "conditionName"], {
      is: (conditionType: string, conditionName: string) =>
        conditionType === "212" &&
        [
          "Malignant Neoplasm Of Prostate",
          "Malignant Neoplasm Prostate",
          "Prostate Calculus",
          "Prostate Cancer",
          "Prostate Enlarged",
          "Prostate Hyperplasia",
          "Prostate Hypertrophy",
          "Prostate Neoplasm",
          "Prostate Neoplasm Malignant",
          "Prostate Neoplasm Unknown",
          "Inflammatory Prostate Disease",
          "Hyperplasia Of Prostate",
          "Enlarged Prostate",
          "Enlarge Prostate",
          "Carcinoma Prostate",
          "Carcinoma Of Prostate",
          "Carcinoma In Situ Of Prostate",
          "Cancer Of Prostate",
          "Ca Prostate",
          "Benign Tumor Of Prostate",
          "Benign Prostate Tumour",
          "Benign Neoplasm Of Prostate",
          "Adenocarcinoma Prostate",
          "Adeno Carcinoma Prostate",
          "Adeno CA Prostate",
          "Prostate Tuberculosis",
        ].includes(conditionName),
      then: Yup.string().nullable(),
    }),

  gleasonScoreDate: Yup.string()
    .label("Gleason Score Date")
    .nullable()
    .when(["conditionType", "conditionName"], {
      is: (conditionType: string, conditionName: string) =>
        conditionType === "212" &&
        [
          "Malignant Neoplasm Of Prostate",
          "Malignant Neoplasm Prostate",
          "Prostate Calculus",
          "Prostate Cancer",
          "Prostate Enlarged",
          "Prostate Hyperplasia",
          "Prostate Hypertrophy",
          "Prostate Neoplasm",
          "Prostate Neoplasm Malignant",
          "Prostate Neoplasm Unknown",
          "Inflammatory Prostate Disease",
          "Hyperplasia Of Prostate",
          "Enlarged Prostate",
          "Enlarge Prostate",
          "Carcinoma Prostate",
          "Carcinoma Of Prostate",
          "Carcinoma In Situ Of Prostate",
          "Cancer Of Prostate",
          "Ca Prostate",
          "Benign Tumor Of Prostate",
          "Benign Prostate Tumour",
          "Benign Neoplasm Of Prostate",
          "Adenocarcinoma Prostate",
          "Adeno Carcinoma Prostate",
          "Adeno CA Prostate",
          "Prostate Tuberculosis",
        ].includes(conditionName),
      then: Yup.string().nullable(),
    }),

  gleasonScoreResult: Yup.string()
    .label("Gleason Score Result")
    .nullable()
    .when(["conditionType", "conditionName"], {
      is: (conditionType: string, conditionName: string) =>
        conditionType === "212" &&
        [
          "Malignant Neoplasm Of Prostate",
          "Malignant Neoplasm Prostate",
          "Prostate Calculus",
          "Prostate Cancer",
          "Prostate Enlarged",
          "Prostate Hyperplasia",
          "Prostate Hypertrophy",
          "Prostate Neoplasm",
          "Prostate Neoplasm Malignant",
          "Prostate Neoplasm Unknown",
          "Inflammatory Prostate Disease",
          "Hyperplasia Of Prostate",
          "Enlarged Prostate",
          "Enlarge Prostate",
          "Carcinoma Prostate",
          "Carcinoma Of Prostate",
          "Carcinoma In Situ Of Prostate",
          "Cancer Of Prostate",
          "Ca Prostate",
          "Benign Tumor Of Prostate",
          "Benign Prostate Tumour",
          "Benign Neoplasm Of Prostate",
          "Adenocarcinoma Prostate",
          "Adeno Carcinoma Prostate",
          "Adeno CA Prostate",
          "Prostate Tuberculosis",
        ].includes(conditionName),
      then: Yup.string()
        .transform(typeFormatters["decimal"])
        .test(
          "is-valid-gleason",
          "Please enter a valid Gleason score (e.g. 2.0 – 10.0).",
          (value) =>
            !value ||
            fieldValidation(value, "decimal", 2, 10, false) ||
            value === ""
        ),
    }),

  // Stroke
  strokeType: Yup.string()
    .label("Stroke Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "213",
      then: Yup.string()
        .required("Please specify the type of stroke you suffered.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  bodyPartAffected: Yup.string()
    .label("Part Of Body Affected By Stroke")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "213",
      then: Yup.string().nullable(),
    }),

  // Respiratory
  respiratoryType: Yup.string()
    .label("Respiratory Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "214",
      then: Yup.string()
        .required("Please specify the type of respiratory condition.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  // Neurological
  neurologicalType: Yup.string()
    .label("Neurological Type")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "216",
      then: Yup.string()
        .required("Please specify the type of neurological condition.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  mmseScoreKnown: Yup.string()
    .label("MMSE Score Known")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "216",
      then: Yup.string().required(
        "Please tell us whether you know the MMSE score or not (this should be a value between 0 and 30)."
      ),
    }),

  mmseScore: Yup.string()
    .label("MMSE Score")
    .nullable()
    .when("mmseScoreKnown", {
      is: (val: string) => val === "true",
      then: Yup.string()
        .required(
          "Please tell us the MMSE score, it should be a value between 0 and 30."
        )
        .test(
          "is-valid-mmseScore",
          "Please enter a numeric value between 0 and 30.",
          (value) => fieldValidation(value, "integer", 0, 30, true)
        ),
    }),

  falls: Yup.string()
    .label("Falls")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "216",
      then: Yup.string()
        .required(
          "Please tell us if you have had any falls with this condition."
        )
        .notOneOf(["0", ""], "Please select an option"),
    }),

  pressureSores: Yup.string()
    .label("Pressure Sores")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "216",
      then: Yup.string()
        .required("Please tell us if you have had any pressure sores.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  seizures: Yup.string()
    .label("Seizures")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "216",
      then: Yup.string()
        .required("Please tell us if you have had any seizures.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  tremors: Yup.string()
    .label("Tremors")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "216",
      then: Yup.string()
        .required("Please tell us if you have had any tremors.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  // Other
  lastTreatmentDate: Yup.string()
    .label("lastTreatmentDate")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "208",
      then: Yup.string()
        .required(
          "Please tell us the last time you had any treatment for this condition."
        )
        .notOneOf(["0", ""], "Please select an option"),
    }),

  renalDialysis: Yup.string()
    .label("Renal Dialysis")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "208",
      then: Yup.string().required(
        "Please specify if you've had renal dialysis for this condition."
      ),
    }),

  otherTreatment: Yup.string()
    .label("Other Treatment")
    .nullable()
    .when("conditionType", {
      is: (val: string) => val === "208",
      then: Yup.string().required(
        "Please specify if you've had any other treatments for this condition."
      ),
    }),

  // Fields across multiple conditions
  lastConsultationDate: Yup.string()
    .label("Last Consultation Date")
    .nullable()
    .when("conditionType", {
      is: (val: string) => ["212", "210"].includes(val),
      then: Yup.string().required(
        "Please specify your last consultation date."
      ),
    }),

  surgery: Yup.string()
    .label("Surgery")
    .nullable()
    .when("conditionType", {
      is: (val: string) => ["208", "212"].includes(val),
      then: Yup.string().required(
        "Please tell us whether you've had surgery for this condition."
      ),
    }),

  lastHospitalised: Yup.string()
    .label("Last Hospitalised Date")
    .nullable()
    .when("conditionType", {
      is: (val: string) => ["208", "210", "211", "214"].includes(val),
      then: Yup.string().nullable(),
    }),

  lastReading: Yup.string()
    .label("Last Reading Date")
    .nullable()
    .when("conditionType", {
      is: (val: string) => ["218", "219", "211"].includes(val),
      then: Yup.string().nullable(),
    }),

  durationTime: Yup.string()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .when("conditionType", {
      is: (val: string) => ["213"].includes(val),
      then: Yup.string()
        .nullable()
        .test(
          "is valid durationtime",
          "Please enter a numeric value between 0 and 1000.",
          (value) => {
            console.log("value", value);
            if (
              value === null ||
              value === undefined ||
              value.trim() === "" ||
              value === "undefined"
            ) {
              return true;
            }
            return fieldValidation(value, "integer", 0, 1000, true);
          }
        ),
    }),

  recoveryTime: Yup.string()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .when("conditionType", {
      is: (val: string) => ["213"].includes(val),
      then: Yup.string()
        .nullable()
        .test(
          "is valid recoveryTime",
          "Please enter a numeric value between 0 and 1000.",
          (value) => {
            console.log("value", value);
            if (
              value === null ||
              value === undefined ||
              value.trim() === "" ||
              value === "undefined"
            ) {
              return true;
            }
            return fieldValidation(value, "integer", 0, 1000, true);
          }
        ),
    }),
});
