import { ErrorMessage, useField, useFormikContext } from "formik";
import { ReactNode, useEffect, useMemo, useState } from "react";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { IGroupedOption, IOption } from "../../../models/LookupWithGroupsData";

interface IProps {
  groupFilterField?: string;
  label: string;
  description: string;
  name: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  prefix: ReactNode;
  values: IGroupedOption[];
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
  allowFreeType?: boolean;
  unknownEntryValue?: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

export const LookupWithSearchAndGroups = (props: IProps) => {
  const {
    label,
    description,
    name,
    required,
    tooltip,
    prefix,
    className,
    addSeparatorBelow,
    addSeparatorAbove,
    allowFreeType = false, // defaults to false
    unknownEntryValue = "",
  } = props;

  const [initialValue, setInitialValue] = useState<IOption>({
    label: "",
    value: "Please Select",
  });

  const [field, meta, helpers] = useField(name);

  // Update initialValue to match whatever the Formik field has
  useEffect(() => {
    if (field.value) {
      // Find the option that corresponds to the field value and set it as the initial value
      const foundOption = props.values
        .flatMap((group) => group.options)
        .find((option) => option.value === field.value);

      if (foundOption) {
        setInitialValue(foundOption);
      } else {
        // If we can't find the option, set the label/value using the typed input
        setInitialValue({
          label: initialValue.label,
          value: initialValue.value,
        });
      }
    }
  }, [field.value, props.values, initialValue]);

  // For minimum input length in searching
  const minInput = 3;
  const filterOption = (item: any, input: any) => {
    return input.length >= minInput && createFilter({})(item, input);
  };

  const noOptionsMessage = (input: any) =>
    input.length >= minInput
      ? "No options"
      : "Please start typing (minimum of 3 characters)...";

  //below is for filtering search groups
  const { values } = useFormikContext<any>();
  const groupFilter = returnGroupFilterFieldValue();

  function returnGroupFilterFieldValue() {
    if (props.groupFilterField) {
      const getNestedValue = (obj: any, path: string) => {
        const pathParts = path.split(".");
        return pathParts.reduce((accumulator, current) => {
          return accumulator && accumulator[current];
        }, obj);
      };
      const fieldValue = getNestedValue(values, props.groupFilterField);
      return fieldValue;
    } else {
      return undefined;
    }
  }

  const filterGroupOptions = (
    groupedOptions: IGroupedOption[],
    filterGroup?: string
  ) => {
    if (!filterGroup) return groupedOptions;
    return groupedOptions
      .filter(
        (group) => group.groupValue.toLowerCase() === filterGroup.toLowerCase()
      )
      .map((group) => ({
        ...group,
        options: group.options,
      }));
  };

  // Memoize the filtered options
  const memoizedFilteredOptions = useMemo(() => {
    return filterGroupOptions(props.values, groupFilter);
  }, [props.values, groupFilter]);

  // Conditionally choose which Select component to render
  const SelectComponent = allowFreeType ? CreatableSelect : Select;

  return (
    <div className={className ? className : "mb-9"}>
      {addSeparatorAbove && <hr className="py-5" />}

      <div className="d-flex flex-column fv-row">
        <div className="mb-2">
          <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
            {/* Label text */}
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>

            {/* Button (tooltips, icons, etc.) */}
            <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
              {props.tooltip?.button && props.tooltip.button}
            </div>
          </label>
          {tooltip?.notice && tooltip.notice}
          {description && <div className="form-text">{description}</div>}
        </div>

        <div className="input-group">
          {prefix && (
            <div className="input-group-prepend">
              <div className="input-group-text border-0 me-2 bg-transparent bolder">
                {prefix}
              </div>
            </div>
          )}

          <SelectComponent<IOption, false, IGroupedOption>
            name={name}
            options={memoizedFilteredOptions}
            filterOption={filterOption}
            noOptionsMessage={noOptionsMessage}
            className="input-group fs-5"
            value={initialValue}
            onChange={(option: IOption | null) => {
              if (!option) {
                helpers.setValue("");
                return setInitialValue({ label: "", value: "" });
              }
              helpers.setValue(option.value);
              setInitialValue(option);
            }}
            // This callback is only used by CreatableSelect
            onCreateOption={(inputValue: string) => {
              // If user typed something not in the list
              const newOption: IOption = {
                label: inputValue,
                value: unknownEntryValue,
              };
              helpers.setValue(newOption.value);
              setInitialValue(newOption);
            }}
          />
        </div>

        <div className="text-danger mt-2">
          <ErrorMessage name={name} />
        </div>
      </div>

      {addSeparatorBelow && <hr className="py-5" />}
    </div>
  );
};
