import { ReactElement } from "react";

interface IProps {
  fileType: string;
  fileName: string;
  docusignRedirectURL: string;
  children: ReactElement;
}

const DocumentDigitalSignature = (props: IProps) => {
  return (
    <div className="d-flex justify-content-center text-center flex-column p-8 mw-250px">
      <a    
        href={props.docusignRedirectURL}
        target="_blank"
        rel="noreferrer"
      >
        <div>{props.children}</div>
      </a>
    </div>
  );
};

export default DocumentDigitalSignature;
