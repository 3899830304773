import * as Yup from "yup";
import { fieldValidation } from "../../Validators";

export const MedicalConditionSharedInfoSchema = (
  prefix: string,
  isPerson2: boolean
) => ({
  underCareOfConsultant: Yup.string()
    .label(
      `Are ${
        isPerson2 ? "they" : "you"
      }, currently under the care of a cardiologist?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.underCareOfConsultant`,
          label: `Are ${
            isPerson2 ? "they" : "you"
          }, Currently under the care of a cardiologist?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  breathlessnessWalkingRoomtoRoomID: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      }, get breathlessness when walking from room to room?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["210", "214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us if you feel any breathlessness when walking from room to room."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.breathlessnessWalkingRoomtoRoomID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, get breathlessness when walking from room to room?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210", "214"],
          },
        ],
      },
    }),

  breathlessnessClimbingStairsID: Yup.string()
    .label(
      `Do ${isPerson2 ? "they" : "you"} get breathlessness climbing stairs?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["210", "214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us if you feel any breathlessness when climbing the stairs."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.breathlessnessClimbingStairsID`,
          label: "Breathlessness Climbing Stairs",
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210", "214"],
          },
        ],
      },
    }),

  breathlessnessWhenLyingFlatID: Yup.string()
    .label(
      `Do ${isPerson2 ? "they" : "you"} get breathlessness when laying flat?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us if you feel any breathlessness when laying flat."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.breathlessnessWhenLyingFlatID`,
          label: "Breathlessness When Laying Flat",
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  needForCPAPBreathingMachineID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} need a CPAP Breathing Machine?`)
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us if you need a CPAP Breathing Machine."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.needForCPAPBreathingMachineID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, need a CPAP Breathing Machine?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  needForHomeOxygenID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} need home oxygen?`)
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required("Please tell us if you need home oxygen."),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.needForHomeOxygenID`,
          label: `Do ${isPerson2 ? "they" : "you"} need home oxygen?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  oralSteroidsID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} need oral steroids?`)
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required("Please tell us if you need oral steroids."),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.oralSteroidsID`,
          label: `Do ${isPerson2 ? "they" : "you"} need oral steroids?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  recurrentChestInfectionsID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} have recurrent chest infections?`)
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "214"
        );
      },
      then: Yup.string().required(
        `Do ${isPerson2 ? "they" : "you"} have recurrent chest infections?`
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          // Ensure this name matches the key in your form values.
          name: `${prefix}.recurrentChestInfectionsID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have recurrent chest infections?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: "5870" },
            { label: "Some Of The Time", value: "5871" },
            { label: "Always", value: "5872" },
            { label: "Most Of The Time", value: "9935" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  signsOfCorPulmonaleID: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have signs of cor pulmonale (right heart failure due to lung disease)?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us if you have signs of cor pulmonale (right heart failure due to lung disease)."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.signsOfCorPulmonaleID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, you have signs of cor pulmonale (right heart failure due to lung disease)?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  lungFunctionID: Yup.string()
    .label(`How is  ${isPerson2 ? "their" : "your"} lung function?`)
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["214"].includes(item.conditionType)
        );
      },
      then: Yup.string().required("Please tell us about your lung function."),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.lungFunctionID`,
          label: `How is  ${isPerson2 ? "their" : "your"} lung function?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Minimally Impaired (FEV1 >70%)", value: 5867 },
            { label: "Moderately Impaired (FEV1 50-70%)", value: 5868 },
            { label: "Severely Impaired (FEV1 <50%)", value: 5869 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["214"],
          },
        ],
      },
    }),

  chestPainsOnModerateActivityID: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      }, get Chest pains doing moderate activity?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "210"
        );
      },
      then: Yup.string().required(
        "Please tell us if you feel any chest pains during moderate activity."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.chestPainsOnModerateActivityID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, get Chest pains doing moderate activity?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  chestPainsOnSevereExertionID: Yup.string()
    .label(
      `Do ${isPerson2 ? "they" : "you"} get chest pains on severe exertion?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "210"
        );
      },
      then: Yup.string().required(
        "Please tell us if you feel any chest pains during severe exertion."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.chestPainsOnSevereExertionID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, get chest pains on severe exertion?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  swollenAnklesID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} Get swollen ankles?`)
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "210"
        );
      },
      then: Yup.string().required("Please tell us if you have swollen ankles."),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.swollenAnklesID`,
          label: `Do ${isPerson2 ? "they" : "you"} Get swollen ankles?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  episodesOfDizzinessID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} experience episodes of dizziness?`)
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "210"
        );
      },
      then: Yup.string().required(
        "Please tell us if you experience any episodes of dizziness."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.episodesOfDizzinessID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, experience episodes of dizziness?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  episodesOfBlackoutsID: Yup.string()
    .label(`Do ${isPerson2 ? "they" : "you"} experience episodes of blackouts?`)
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "210"
        );
      },
      then: Yup.string().required(
        "Please tell us if you experience any episodes of blackouts."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.episodesOfBlackoutsID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, experience episodes of blackouts?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  symptomsAtRestID: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      }, experience symptoms of your conditions while at rest?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some(
          (item: any) => item.conditionType === "210"
        );
      },
      then: Yup.string().required(
        "Please tell us if you experience any of the symptoms of your condition whilst at rest."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.symptomsAtRestID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, experience symptoms of your conditions while at rest?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Never", value: 5870 },
            { label: "Some Of The Time", value: 5871 },
            { label: "Always", value: 5872 },
            { label: "Most Of The Time", value: 9935 },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  transferringID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      } transferring ability? (Problems with sitting unaided)?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your transferring ability."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.transferringID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          } transferring ability? (Problems with sitting unaided)?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Unable, no sitting balance", value: "5856" },
            { label: "Major Help", value: "5857" },
            { label: "Minor help, can sit unaided", value: "5858" },
            { label: "Independent", value: "5859" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  mobilityID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      } mobility?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your mobility."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.mobilityID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          } mobility?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Independent (needs no assistance)", value: "5815" },
            {
              label: "Walks with assistance (frame/stick etc.)",
              value: "5816",
            },
            { label: "Wheelchair use - permanent", value: "5817" },
            { label: "In need of daily nursing care", value: "5818" },
            { label: "Bedridden", value: "5819" },
            { label: "Wheelchair use - non permanent", value: "100992" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  feedingID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      } ability to feed each day?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your ability to feed each day."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.feedingID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          } ability to feed each day?`,
          required: "true",
          values: [
            { label: "", value: null },
            {
              label: "Unable (naso-gastric tube/PEG tube in place)",
              value: "5850",
            },
            {
              label: "Needs some help cutting, spreading butter, etc",
              value: "5851",
            },
            { label: "Independent", value: "5852" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  dressingID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      } ability to dress each day? (Zips, laces, buttons, general dressing)?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your ability to dress each day."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.dressingID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          } ability to dress each day? (Zips, laces, buttons, general dressing)?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Dependent, requires full assistance", value: "5838" },
            {
              label: "Needs help, but can do about half unaided",
              value: "5839",
            },
            {
              label: "Independent (including buttons, zips, laces, etc)",
              value: "5840",
            },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  bowelsID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      } bowel control`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your bowel control."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.bowelsID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          } bowel control`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Incontinent (or requires enema)", value: "5847" },
            {
              label: "Occasional accident (once a week)",
              value: "5848",
            },
            { label: "Continent", value: "5849" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  bladderID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      } bladder control?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your bladder control."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.bladderID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          } bladder control?`,
          required: "true",
          values: [
            { label: "", value: null },
            {
              label: "Incontinent/catheterised/unable to manage alone",
              value: "5844",
            },
            {
              label: "Occasional accident (once a week)",
              value: "5845",
            },
            { label: "Continent", value: "5846" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  bathingID: Yup.string()
    .label(
      `Which of the following most closely reflects ${
        isPerson2 ? "their" : "your"
      }  ability to bathe each day?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us what most closely reflects your ability to bathe each day."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.bathingID`,
          label: `Which of the following most closely reflects ${
            isPerson2 ? "their" : "your"
          }  ability to bathe each day?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Dependent", value: "5841" },
            { label: "Needs some assistance", value: "5842" },
            { label: "Independent", value: "5843" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  progressionID: Yup.string()
    .label(
      `In relation to ${
        isPerson2 ? "their" : "your"
      } condition(s), has their been any change in the last five years?`
    )
    .nullable()
    .when(`medicalConditionList`, {
      is: (medicalConditionList: any) => {
        if (!Array.isArray(medicalConditionList)) {
          return false;
        }
        return medicalConditionList.some((item: any) =>
          ["213", "214", "215", "216"].includes(item.conditionType)
        );
      },
      then: Yup.string().required(
        "Please tell us if there has been any change in the last five years relating to your health conditions."
      ),
    })
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.progressionID`,
          label: `In relation to ${
            isPerson2 ? "their" : "your"
          } condition(s), has their been any change in the last five years?`,
          required: "true",
          values: [
            { label: "", value: null },
            { label: "Stable (no/minimal change)", value: "5853" },
            {
              label:
                "Deteriorating (impact to 2 or more activities above/acute episodes)",
              value: "5854",
            },
            { label: "Rapid Deterioration", value: "5855" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213", "214", "215", "216"],
          },
        ],
      },
    }),

  futureTreatmentPlannedID: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      }, have any of the following surgeries or treatments planned?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: `${prefix}.futureTreatmentPlannedID`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, have any of the following surgeries or treatments planned?`,
          required: false,
          values: [
            { label: "No Treatment Planned", value: "6013" },
            { label: "Coronary artery bypass graft (CABG)", value: "6005" },
            { label: "Coronary angioplasty/stents", value: "6006" },
            { label: "Aortic valve replacement", value: "6007" },
            { label: "Mitral valve replacement", value: "6008" },
            { label: "Tricuspid valve replacement", value: "6009" },
            { label: "Pacemaker", value: "6010" },
            { label: "Cardioversion/ablation", value: "6011" },
            { label: "Aortic aneurysm repair", value: "6012" },
          ],
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  lastECGDate: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      }, have the latest date of any stress (exercise) ECG test e.g. using a bicycle or treadmill?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.lastECGDate`,
          label: `Do ${
            isPerson2 ? "they" : "you"
          }, have the latest date of any stress (exercise) ECG test e.g. using a bicycle or treadmill?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  coronaryArteryBypassCABG: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, had a Coronary Artery Bypass Graft (CABG)?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.coronaryArteryBypassCABG`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had a Coronary Artery Bypass Graft (CABG)?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  cABGDate: Yup.string()
    .label(`When did ${isPerson2 ? "they" : "you"} have the bypass?`)
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.cABGDate`,
          label: `When did ${isPerson2 ? "they" : "you"} have the bypass?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.coronaryArteryBypassCABG`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  cABGNoOfArteries: Yup.string()
    .label(`Number of arteries affected in the bypass?`)
    .nullable()
    .test(
      "is-valid-numberOfAteries",
      "Please enter the number of arteries affected, upto a maximum of 10.",
      (value) => fieldValidation(value, "integer", 0, 10, false)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: `Number of arteries affected in the bypass?`,
          description: "",
          name: `${prefix}.cABGNoOfArteries`,
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "number",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.coronaryArteryBypassCABG`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  coronaryAngioplastyStents: Yup.string()
    .label(`Have ${isPerson2 ? "they" : "you"} had Angioplasty or stents?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.coronaryAngioplastyStents`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had Angioplasty or stents?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  stentsDate: Yup.string()
    .label(
      `When did ${isPerson2 ? "they" : "you"} have the Stents/Angioplasty?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.stentsDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Stents/Angioplasty?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.coronaryAngioplastyStents`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  stentsNoOfArteries: Yup.string()
    .label(`Number of arteries affected due to the Stents/Angioplasty?`)
    .nullable()
    .test(
      "is-valid-numberOfAteries",
      "Please enter the number of arteries affected, upto a maximum of 10.",
      (value) => fieldValidation(value, "integer", 0, 10, false)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: `Number of arteries affected due to the Stents/Angioplasty?`,
          description: "",
          name: `${prefix}.stentsNoOfArteries`,
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "number",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.coronaryAngioplastyStents`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  aorticValveReplacement: Yup.string()
    .label(
      `Have ${isPerson2 ? "they" : "you"} had an Aortic valve replacement?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.aorticValveReplacement`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had an Aortic valve replacement?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  aVRDate: Yup.string()
    .label(
      `When did ${
        isPerson2 ? "they" : "you"
      }, have the  Aortic valve replacement?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.aVRDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Aortic valve replacement?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.aorticValveReplacement`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  aVRSuccessful: Yup.string()
    .label(`Was the Aortic valve replacement successful?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Was the Aortic valve replacement successful?`,
          description: "",
          name: `${prefix}.aVRSuccessful`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.aorticValveReplacement`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  mitralValveReplacement: Yup.string()
    .label(`Have ${isPerson2 ? "they" : "you"} had a Mitral valve replacement?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.mitralValveReplacement`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had a Mitral valve replacement?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  mVRDate: Yup.string()
    .label(
      `When did ${
        isPerson2 ? "they" : "you"
      }, have the Mitral valve replacement?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.mVRDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Mitral valve replacement?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.mitralValveReplacement`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  mVRSuccessful: Yup.string()
    .label(`Was the Mitral valve replacement successful?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Was the Mitral valve replacement successful?`,
          description: "",
          name: `${prefix}.mVRSuccessful`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.mitralValveReplacement`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  tricuspidValveReplacement: Yup.string()
    .label(
      `Have ${isPerson2 ? "they" : "you"} had a Tricuspid valve replacement?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.tricuspidValveReplacement`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had a Tricuspid valve replacement?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  tVRDate: Yup.string()
    .label(
      `When did ${
        isPerson2 ? "they" : "you"
      }, have the Tricuspid valve replacement?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.tVRDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Tricuspid valve replacement?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.tricuspidValveReplacement`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  tVRSuccessful: Yup.string()
    .label(`Was the Tricuspid valve replacement successful?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Was the Tricuspid valve replacement successful?`,
          description: "",
          name: `${prefix}.tVRSuccessful`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.tricuspidValveReplacement`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  pacemaker: Yup.string()
    .label(`Have ${isPerson2 ? "they" : "you"} had a Pacemaker fitted?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.pacemaker`,
          label: `Have ${isPerson2 ? "they" : "you"} had a Pacemaker fitted?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  pacemakerDate: Yup.string()
    .label(`When did ${isPerson2 ? "they" : "you"} have the Pacemaker fitted?`)
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.pacemakerDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Pacemaker fitted?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.pacemaker`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  pacemakerSuccessful: Yup.string()
    .label(`Was the Pacemaker fitted successfully`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Was the Pacemaker fitted successfully`,
          description: "",
          name: `${prefix}.pacemakerSuccessful`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.pacemaker`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  cardioversionAblation: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, had a Cardioversion or Ablation procedure?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.cardioversionAblation`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had a Cardioversion or Ablation procedure?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  cardioversionDate: Yup.string()
    .label(
      `When did ${
        isPerson2 ? "they" : "you"
      }, have the Cardioversion or Ablation procedure?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.cardioversionDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Cardioversion or Ablation procedure?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.cardioversionAblation`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  cardioversionSuccessful: Yup.string()
    .label(`Was the Pacemaker Cardioversion or Ablation procedure successful`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Was the Pacemaker Cardioversion or Ablation procedure successful`,
          description: "",
          name: `${prefix}.cardioversionSuccessful`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.cardioversionAblation`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  aorticAneurysmRepair: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, had a procedure to repair an Aortic Aneurysm?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          name: `${prefix}.aorticAneurysmRepair`,
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, had a procedure to repair an Aortic Aneurysm?`,
          required: false,
        },
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
        ],
      },
    }),

  aARDate: Yup.string()
    .label(
      `When did ${
        isPerson2 ? "they" : "you"
      }, have the Aortic Aneurysm repair procedure?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "DateMMYYYY",
        props: {
          name: `${prefix}.aARDate`,
          label: `When did ${
            isPerson2 ? "they" : "you"
          }, have the Aortic Aneurysm repair procedure?`,
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.aorticAneurysmRepair`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  aARSuccessful: Yup.string()
    .label(`Was the Aortic Aneurysm repair procedure successful?`)
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Was the Aortic Aneurysm repair procedure successful?`,
          description: "",
          name: `${prefix}.aARSuccessful`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["210"],
          },
          {
            fieldName: `${prefix}.aorticAneurysmRepair`,
            fieldValue: ["true"],
          },
        ],
      },
    }),

  visionImpairment: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, suffered from vision impairment due to the stroke(s)?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, suffered from vision impairment due to the stroke(s)?`,
          description: "",
          name: `${prefix}.visionImpairment`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213"],
          },
        ],
      },
    }),

  speechDifficulties: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, suffered from speech difficulties due to the stroke(s)?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, suffered from speech difficulties due to the stroke(s)?`,
          description: "",
          name: `${prefix}.speechDifficulties`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213"],
          },
        ],
      },
    }),

  paralysisLeg: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, suffered from paralysis in the legs due to the stroke(s)?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, suffered from paralysis in the legs due to the stroke(s)?`,
          description: "",
          name: `${prefix}.paralysisLeg`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213"],
          },
        ],
      },
    }),

  paralysisArm: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, suffered from paralysis in the arms due to the stroke(s)?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, suffered from paralysis in the arms due to the stroke(s)?`,
          description: "",
          name: `${prefix}.paralysisArm`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213"],
          },
        ],
      },
    }),

  memoryLoss: Yup.string()
    .label(
      `Have ${
        isPerson2 ? "they" : "you"
      }, suffered from memory Loss due to the stroke(s)?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Have ${
            isPerson2 ? "they" : "you"
          }, suffered from memory Loss due to the stroke(s)?`,
          description: "",
          name: `${prefix}.memoryLoss`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["213"],
          },
        ],
      },
    }),

  bladderIncontinence: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have bladder incontinence due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have bladder incontinence due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.bladderIncontinence`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),

  impairedSpeech: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have impaired speech due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have impaired speech due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.impairedSpeech`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),

  impairedVision: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have impaired vision due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have impaired vision due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.impairedVision`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),

  limbParalysis: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have limb paralysis due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have limb paralysis due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.limbParalysis`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),

  mentalDeterioration: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have mental deterioration due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have mental deterioration due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.mentalDeterioration`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),

  secondaryInfection: Yup.string()
    .label(
      `Do ${
        isPerson2 ? "they" : "you"
      } have secondary infections due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } have secondary infections due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.secondaryInfection`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),

  useOfSteroids: Yup.string()
    .label(
      `Do ${isPerson2 ? "they" : "you"} use steroids due to Multiple Sclerosis?`
    )
    .nullable()
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: `Do ${
            isPerson2 ? "they" : "you"
          } use steroids due to Multiple Sclerosis?`,
          description: "",
          name: `${prefix}.useOfSteroids`,
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          className: "",
          addSeparatorBelow: false,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          {
            fieldName: `${prefix}.medicalConditionList.[].conditionType`,
            fieldValue: ["215"],
          },
        ],
      },
    }),
});
