import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../_metronic/helpers";
import { usePortal } from "../../../context/PortalContext";
import { auth } from "../../../config/firebase";
import { GetFormFieldsWithHeaderAndFields } from "../../forms/Helpers";
import { ScrollToError } from "./ScrollToError";
import useDynamicSchemas from "../../../custom_hooks/useDynamicSchemas";
import { formatFieldsInCurrentSchema } from "../../forms/Formatters";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  formSchemas: FormSchema[];
  data?: Record<string, any>;
  handleSubmit: (data: any) => void;
  navigateAfterSubmitTo: string | undefined;
  TopContent?: ReactNode;
  FormTopContent?: ReactNode;
  FormFootercontent?: ReactNode;
  SaveAndExitButton?: boolean;
  initialStep?: number;
}

interface IDynamicSchemaCondition {
  fieldName: string;
  fieldValue: any;
}

interface FormSchema {
  header: string;
  description: string;
  stepperDescription?: string;
  schema: Schema;
  isPerson2: boolean;
  pageNumber: number;
  dynamicSchemaConditions?: Array<IDynamicSchemaCondition>;
}

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

export const StepperForm = (props: IProps) => {
  const navigate = useNavigate();
  const { clientData, setClientData }: any = usePortal();
  const [user] = useAuthState(auth);

  const [shouldSaveAndExit, setShouldSaveAndExit] = useState(false);
  const [initialStep, setInitialStep] = useState(props.initialStep ?? 0);
  const isLoggedIn = user !== null;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  // Keep track of `stepIndex` ourselves in the form values.
  const initialValues = {
    ...(props.data || {}),
    stepIndex: initialStep || 0, // Track which step the user is on
  };

  // The form's "current schema" will dynamically switch based on the filtered schemas and stepIndex.
  const [currentSchema, setCurrentSchema] = useState<
    Yup.ObjectSchema<any> | undefined
  >(undefined);

  const formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      submitStep(values);
    },
    enableReinitialize: true,
  });

  const dynamicSchemas = useDynamicSchemas(props.formSchemas, formik.values);

  // We'll mirror these schemas in state so we can rely on them in effects and rendering.
  const [filteredSchemas, setFilteredSchemas] = useState(dynamicSchemas);

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, []);

  useEffect(() => {
    setFilteredSchemas(dynamicSchemas);

    const safeStepIndex = Math.min(
      formik.values.stepIndex,
      dynamicSchemas.length - 1
    );

    if (safeStepIndex !== formik.values.stepIndex) {
      formik.setFieldValue("stepIndex", safeStepIndex, false);
    }

    // Set the validation schema for this step
    const newCurrentSchema = dynamicSchemas[safeStepIndex]
      ?.schema as Yup.ObjectSchema<any>;
    setCurrentSchema(newCurrentSchema);
  }, [dynamicSchemas, formik, formik.values.stepIndex]);

  useEffect(() => {
    if (shouldSaveAndExit) {
      navigate("/");
    }
  }, [shouldSaveAndExit, navigate]);

  useEffect(() => {
    // Update the total stepper number of steps based on the filteredSchemas
    if (stepper && stepperRef.current && stepper.current !== null) {
      stepper.current.totatStepsNumber = filteredSchemas.length;
    }
  }, [filteredSchemas.length]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );

    if (!stepper.current) {
      return;
    }

    stepper.current.totatStepsNumber = filteredSchemas.length;

    // If there's an initialStep prop, try to validate steps up to that index, if any errors found then stop at the step with errors
    if (initialStep && initialStep > 0) {
      for (let i = 0; i < initialStep; i++) {
        const schemaForStep = filteredSchemas[i]
          .schema as Yup.ObjectSchema<any>;

        try {
          formatFieldsInCurrentSchema(schemaForStep, props.data);
          schemaForStep.validateSync(initialValues, { abortEarly: false });
        } catch (err) {
          // Log the error
          console.log(err);

          // Set the stepper index at the error, reset the initial step and set the current schema
          stepper.current.currentStepIndex = i;
          setInitialStep(i);
          setCurrentSchema(filteredSchemas[i].schema as Yup.ObjectSchema<any>);
          stepper.current.goNext();

          return;
        }
      }

      stepper.current.currentStepIndex = initialStep;
      setCurrentSchema(
        filteredSchemas[initialStep].schema as Yup.ObjectSchema<any>
      );
      stepper.current.goNext();
    }
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    formik.setFieldValue("stepIndex", Math.max(formik.values.stepIndex - 1, 0));

    // Scroll to top
    document.getElementById("stepper")?.scrollIntoView();
  };

  const submitStep = (values: any) => {
    if (!stepper.current) {
      return;
    }

    // If not on the last step, go next
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
      formik.setFieldValue("stepIndex", formik.values.stepIndex + 1);

      // Scroll to top offset
      const yOffset = -100;
      const element = document.getElementById("stepper");

      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    } else {
      // Final submission
      submitBtnRef.current?.setAttribute("data-kt-indicator", "on");
      submitBtnRef.current?.setAttribute("disabled", "true");

      props.handleSubmit(values);

      if (props.navigateAfterSubmitTo) {
        navigate(props.navigateAfterSubmitTo);
      }
    }
  };

  const saveAndExit = async () => {
    await formik.handleSubmit();
    // Update context
    setClientData(formik.values);
    setShouldSaveAndExit(true);
  };

  // Build the "form fields" for each step
  const FormFields: JSX.Element[] = filteredSchemas.map((schema, index) => (
    <div
      key={index}
      // Show only the current step index; hide others or style them differently
      className={index === formik.values.stepIndex ? "current" : ""}
      data-kt-stepper-element="content"
    >
      {GetFormFieldsWithHeaderAndFields(
        schema.schema,
        schema.header,
        schema.description,
        schema.isPerson2,
        formik.values
      )}
    </div>
  ));

  const flattenErrors = (errors: any): string[] => {
    let errorList: string[] = [];
    Object.values(errors).forEach((value) => {
      if (typeof value === "string") {
        errorList.push(value);
      } else if (typeof value === "object" && value !== null) {
        errorList = errorList.concat(flattenErrors(value));
      }
    });
    return errorList;
  };

  return (
    <FormikProvider value={formik}>
      {props.TopContent && props.TopContent}

      <div
        ref={stepperRef}
        className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
        id="stepper"
      >
        <div className="card card-rounded d-flex flex-row-fluid flex-center bg-body rounded me-md-9 mb-5">
          <form
            onSubmit={formik.handleSubmit}
            className=" py-20 w-100  px-4 px-lg-9"
            noValidate
            id="stepperForm"
          >
            {props.FormTopContent && props.FormTopContent}

            <div>{FormFields}</div>

            {!formik.isValid ? (
              <div className="text-danger mt-2 d-flex justify-content-end">
                Please correct the above errors.
              </div>
            ) : null}

            {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
              <div className="text-danger mt-2">
                <ul>
                  {flattenErrors(formik.errors).map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="d-flex flex-stack pt-10">
              <div className="mr-2">
                <button
                  onClick={prevStep}
                  type="button"
                  className="btn btn-lg btn-light-primary me-3"
                  data-kt-stepper-action="previous"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr063.svg"
                    className="svg-icon-4 me-1"
                  />
                  Back
                </button>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-lg btn-primary me-3"
                  ref={submitBtnRef}
                  onClick={async () => {
                    await formik.validateForm(formik.values);

                    if (!formik.isValid) {
                      ScrollToError();
                    }

                    await formik.handleSubmit();
                  }}
                >
                  <span className="indicator-label">
                    {stepper.current?.currentStepIndex !==
                      stepper.current?.totatStepsNumber && "Continue"}
                    {stepper.current?.currentStepIndex ===
                      stepper.current?.totatStepsNumber && "Submit"}
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr064.svg"
                      className="svg-icon-3 ms-2 me-0"
                    />
                  </span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>

            {isLoggedIn && props.SaveAndExitButton === true && (
              <div className="d-flex flex-stack">
                <div></div>
                <div className="ml-auto">
                  <div className="separator my-10"></div>
                  <button
                    onClick={() => saveAndExit()}
                    type="submit"
                    className="btn btn-sm btn-light-primary"
                  >
                    <KTSVG
                      path="/media/icons/duotune/files/fil012.svg"
                      className="svg-icon-4 me-1"
                    />
                    Save & Exit
                  </button>
                </div>
              </div>
            )}
            {props.FormFootercontent && props.FormFootercontent}
          </form>
        </div>

        {/*Stepper Navigation*/}
        <div className="card card-rounded d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px mb-5">
          <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
            <div className="stepper-nav">
              {filteredSchemas.map((schema, index) => {
                let stepClass =
                  index === formik.values.stepIndex
                    ? "stepper-item current"
                    : "stepper-item";
                stepClass =
                  index < formik.values.stepIndex
                    ? (stepClass += " completed")
                    : stepClass;

                return (
                  <div
                    key={index}
                    className={stepClass}
                    data-kt-stepper-element="nav"
                  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">{index + 1}</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">{schema.header}</h3>
                        <div className="stepper-desc fw-semibold">
                          {schema.description}
                        </div>
                      </div>
                    </div>
                    {index !== filteredSchemas.length - 1 && (
                      <div className="stepper-line h-40px"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};
