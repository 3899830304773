import { IGroupedOption } from "./LookupWithGroupsData";
import { occupationLookup } from "../modules/forms/Lookups";

//use below query to get data

// select '{value: "' + cast(System_AccountID as nvarchar) + '", label: "' + system_name + '",},'
// from service_account where system_organisationalunitId = 100104 and isnull(System_deleted,0)=0 and isnull(system_name, '') <> ''
// and isnull(System_Name,'') not like '%do not%'
// and isnull(System_Name,'') not like '%"%'

interface IEmployment {
  occupation?: string;
  employmentType?: string;
  employmentStatus?: string;
  dateStarted?: string;
  dateEnded?: string;
}

export type { IEmployment }

export const groupedOptions: IGroupedOption[] = [
  {
    label: "Occupations",
    groupValue: "occupations",
    options: occupationLookup,
  },
];
