import { useEffect, useState } from "react";
import { usePortal } from "../../context/PortalContext";
import CalendlyWidget from "../../modules/common/other/CalendlyWidget";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { Link } from "react-router-dom";
import { Loader } from "../../modules/common/modals/LoaderModal";
import { useNavigate } from "react-router-dom";

interface IProps {
  calendlyURL: string;
}

export const BookingFull = (props: IProps) => {
  const { returnClientData, clientData }: any = usePortal();
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [factFindCompleted, setFactFindCompleted] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    setClientName(
      clientData?.title +
        " " +
        clientData?.firstName +
        " " +
        clientData?.lastName
    );
    setClientEmail(clientData?.email);
    //formats phone number to uk
    setClientPhone(
      clientData?.primaryPhone
        ?.replace(/\s+/g, "")
        ?.replace(/(.)(\d{4})(\d)/, "+44 ($1)$2 $3")
    );
  }, [clientData]);

  useEffect(() => {
    const fetchClientData = async () => {
      let responseCode = await returnClientData();

      if (responseCode !== 200) {
        navigate(`/error/${responseCode}`);
      }
    };

    if (!clientData) {
      fetchClientData();
    }
  }, []);

  return (
    <>
      {clientData ? (
        <div>
          <Link to="/overview" className="btn btn-primary mb-10">
            <i className="ki-duotone ki-arrow-right fs-2 text-warning">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            Back to overview
          </Link>
          <div className="row">
            <div className="col-12 col-md-6 mb-5">
              <div className="card card-rounded" style={{ height: "800px" }}>
                <div className="card-body">
                  <h1 className="fs-1 fw-bold mb-10 mt-10">
                    Booking an IFA appointment
                  </h1>
                  <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
                    <h3>What to expect in the appointment</h3>
                    <div>
                      During your appointment, our Independent Financial Adviser
                      (IFA) will provide personalised advice on your retirement
                      options, covering pensions, investments, and tax
                      implications. If you choose to proceed, we will handle the
                      entire application process for you, including all of the
                      paperwork and transferring of funds to your new provider.
                      This session is a no-obligation, informative discussion
                      aimed at simplifying your retirement planning journey and
                      providing you with a recommendation that fits your needs.
                    </div>
                  </div>
                  <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
                    <h3>How to prepare</h3>
                    <div>
                      To make the most of your appointment, we recommend having
                      key information at your fingertips, such as your current
                      income, savings, investments, and details of any existing
                      pension plans. Also, take a moment to think about your
                      retirement goals and jot down any questions you might
                      have. Since we'll be speaking either over the phone or via
                      a video call, try to find a quiet, comfortable spot where
                      you can talk freely and easily access your documents. This
                      way, we can ensure a productive and focused discussion.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-5">
              <div
                className="overflow-hidden d-flex card card-rounded"
                style={{ height: "800px" }}
              >
                <CalendlyWidget
                  url={
                    clientData?.primaryPhone
                      ? props.calendlyURL +
                        `&location=${clientData?.primaryPhone
                          ?.replace(/\s+/g, "")
                          ?.replace(/(.)(\d{4})(\d)/, "+44 ($1)$2 $3")}`
                      : props.calendlyURL
                  }
                  prefill={{
                    name:
                      clientData?.title +
                      " " +
                      clientData?.firstName +
                      " " +
                      clientData?.lastName,
                    email: clientData?.email,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </>
  );
};
