const name = /^[a-zA-Z][a-z]*$/;
const number = /^[0-9]*$/;
const postcode =
  /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i;
const niNumber = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/;
const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const currency = /^\d{1,3}(,\d{3})*(\.\d{2})?$/;
const decimalRegex = /^\d+(\.\d+)?$/;

export function fieldValidation(
  value: any,
  type: string | undefined,
  min?: number,
  max?: number,
  required?: boolean
) {
  if (!value && value !== 0 && (required === null || required === undefined || !required)) {
    return true;
  }

  switch (type) {
    case "currency": 
      if (!value || !currency.test(value)) {
        return false;
      }

      const numericValue = parseFloat(value.replace(/,/g, ''));

      if (min !== undefined && !Number.isNaN(numericValue) && numericValue < min) {
        return false;
      }

      if (max !== undefined && !Number.isNaN(numericValue) && numericValue > max) {
        return false;
      }

      return true;

    case "decimal":
      return value && decimalRegex.test(value);

    case "email": 
      return value && email.test(value);

    case "name":
      if (!name.test(value)) {
        return false;
      }

      if (min !== undefined && value && value.length < min) {
        return false;
      }

      if (max !== undefined && value && value.length > max) {
        return false;
      }

      return true;
      
    case "niNumber":
      return value && niNumber.test(value);

    case "integer":
      if (!number.test(value)) {
        return false;
      }

      const integerValue = parseInt(value);

      if (min !== undefined && !Number.isNaN(integerValue) && integerValue < min) {
        return false;
      }

      if (max !== undefined && !Number.isNaN(integerValue) && integerValue > max) {
        return false;
      }

      return true;

    case "postcode":
      return value && postcode.test(value);
  }
}
