import React, { FC } from "react"
import { Navigate } from "react-router-dom"
import { usePortal } from "../context/PortalContext"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../config/firebase"

interface ProtectedRouteProps {
  // If you have different conditions for different steps, 
  // you can pass props such as `requireFullQuoteCompleted?: boolean`
  requireFullQuote?: boolean
  requireFactfindCompleted?: boolean
  children: JSX.Element
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  requireFullQuote,
  requireFactfindCompleted,
  children
}) => {
  const [user, loading] = useAuthState(auth)
  const { clientData } = usePortal()

  // If we're still checking auth state, you might render a loader or return null
  if (loading) {
    return null
  }

  // If there's no user, definitely not allowed - send them to login
  if (!user) {
    return <Navigate to="/auth/login" replace />
  }

  // If you need certain steps done before allowing them in:
  if (requireFullQuote) {
    const hasFullQuote = clientData?.annuity?.fullQuoteCompleted === "true"
    if (!hasFullQuote) {
      // If they haven't completed full quote, redirect them
      return <Navigate to="/NextSteps" replace />
    }
  }

  if (requireFactfindCompleted) {
    const hasFactfindCompleted = clientData?.annuity?.factfindCompleted === "true"
    if (!hasFactfindCompleted) {
      // If they haven't completed factfind, redirect
      return <Navigate to="/NextSteps" replace />
    }
  }

  // If we got here, conditions are all met
  return children
}

export default ProtectedRoute
