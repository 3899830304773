import { ErrorMessage, Field, useField, useFormikContext } from "formik";

import PropTypes from "prop-types";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";

export const Lookup = (props: IProps) => {
  const [field, meta, helpers] = useField(props.name);
  const [values, setValues] = useState<string[] | IValuesObject[]>([]);

  let currentFormikContext = useFormikContext().values;

  const formData = props.passedInFormData
    ? props.passedInFormData
    : currentFormikContext;

  if (props.passedInFormData) {
    debugger;
  }

  useEffect(() => {
    if (props.valuesFunction) {
      setValues(props.valuesFunction(formData, props.isPerson2));
    } else if (props.values) {
      setValues(props.values);
    }
  }, [formData]);

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="d-flex flex-column mb-7 fv-row">
        <div className="mb-2">
          <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
            {/* Label text */}
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>

            {/* Button (tooltips, icons, etc.) */}
            <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
              {props.tooltip?.button && props.tooltip.button}
            </div>
          </label>
          {props.tooltip?.notice && props.tooltip.notice}
          {props.description != "" && (
            <div className="form-text">{props.description}</div>
          )}
        </div>

        <div className="input-group">
          {props.prefix && (
            <div className="input-group-prepend">
              <div className="input-group-text border-0 me-2 bg-transparent bolder">
                {props.prefix}
              </div>
            </div>
          )}

          <Field
            as="select"
            name={props.name}
            className="form-control form-control-solid form-select form-select-solid"
            autoComplete="off"
          >
            {values.map((value, index) =>
              (() => {
                if (typeof value === "object") {
                  return (
                    <option key={index} value={value.value}>
                      {value.label}
                    </option>
                  );
                }
                if (typeof value === "string") {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                }
              })()
            )}
          </Field>
        </div>

        <div className="text-danger mt-2">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  name: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  prefix: ReactNode;
  values?: string[] | IValuesObject[];
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
  valuesFunction?: (value: any, isPerson2: boolean) => string[];
  isPerson2: boolean;
  passedInFormData?: any;
}

interface IValuesObject {
  label: string;
  value: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
