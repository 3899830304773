import React from "react";

interface Logo {
  src: string;
  alt?: string;
}

interface LogoBannerProps {
  header: string;
  images: Logo[];
}

const LogoBanner: React.FC<LogoBannerProps> = ({ header, images }) => {
  return (
    <div className="card card-rounded">
      <div className="card-body">
        {/* Header displayed above the logos */}
        <h1 className="text-dark fs-2qx fw-bold mb-3 mt-5 text-center">
          {header}
        </h1>
        {/* Flex container for logos that wraps on smaller screens */}
        <div className="d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center p-10">
          {images.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt || "Company Logo"}
              className="img-fluid m-2"
              style={{ width: "120px" }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoBanner;
