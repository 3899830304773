import { ObjectShape } from "yup/lib/object";

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

export const typeFormatters: Record<
  string,
  (rawValue: string) => string
> = {
  integer: (val: string) => {
    if (val === undefined || val === null) {
      return val;
    }

    const integerValue = parseInt(val);

    if (isNaN(integerValue)) {
      return val;
    }

    return String(integerValue);
  },
  // If its undefined or null just return the unformatted value
  // Convert value to a float
  // Check if it's a number
  // Limit to two decimals and insert commas every 3 digits
  currency: (val: string) => {
    if (val === undefined || val === null) {
      return val;
    }
  
    const numericValue = parseFloat(val.replace(/,/g, ''));
    const floatVal = parseFloat(String(numericValue));
  
    if (isNaN(floatVal)) {
      return val;
    }
  
    return floatVal.toLocaleString('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  },
  // If its undefined or null just return the unformatted value
  // Convert value to a float
  // Limit to two decimals
  decimal: (val: string) => {
    if (val === undefined || val === null) {
      return val;
    }

    const floatVal = parseFloat(String(val));
  
    if (isNaN(floatVal)) {
      return val;
    }

    return floatVal.toFixed(2);
  },
  // If its undefined or null just return the unformatted value
  // Remove non-digits
  phone: (val: string) => {
    if (val === undefined || val === null) {
      return val;
    }

    const digits = String(val).replace(/\D+/g, "");
    return digits;
  },
  // If its undefined or null just return the unformatted value
  // Parse float and clamp 0-100
  // Store it as a numeric fraction (like 23 => "23.00")
  percentage: (val: string) => {
    if (val === undefined || val === null) {
      return val;
    }

    const floatVal = parseFloat(String(val));
    
    if (isNaN(floatVal)) {
        return val;
    }

    if (floatVal > 100) {
        return "100";
    } 

    if (floatVal < 0) {
        return "0";
    }

    return floatVal.toFixed(2);
  },
  // No transform. Just return the value
  text: (val: string) => {
    return String(val);
  },
  // Remove whitespace and convert to uppercase
  // Format to a valid UK postcode with a space between outer and inner regions
  postcode: (val: string) => {    
    const cleaned = val.replace(/\s+/g, "").toUpperCase();

    // Input must be a minimum of 5 chars to be a valid postcode
    if (cleaned.length < 5) {
      return cleaned;
    }

    // Split the last 3 characters
    let outward = cleaned.slice(0 , -3);
    let inward = cleaned.slice(-3);

    // Check if outward contains at least one digit and inward starts with a digit and only contains a single digit
    if (/[0-9]/.test(outward) && /^[0-9][^0-9]*$/.test(inward)) {
      return outward + " " + inward;
    }

    // If the inward contains two digits then shift the first one over to the outward
    if (/[0-9]/.test(inward[0]) && /[0-9]/.test(inward[1])) {
      outward = outward + inward[0];
      inward = inward.slice(1);

      return outward + " " + inward;
    }

    return cleaned;
  }
};

export const formatFieldsInCurrentSchema = (
  schema: Schema,
  data: any,
) => {
  // Iterate over the top-level fields in the schema
  Object.keys(schema.fields).forEach((fieldKey) => {
    const fieldSchema = schema.fields[fieldKey];
    // Return if we don't have nested fields - There would be no controls on the page
    if (!fieldSchema.fields) {
      return;
    }

    // Iterate over the inner fields
    Object.keys(fieldSchema.fields).forEach((innerKey) => {
      const innerFieldSchema = fieldSchema.fields[innerKey];
      const formatType =
        innerFieldSchema?.spec?.meta?.control?.props?.formatType;

      // If formatType is present, call the corresponding formatter and update the field value
      if (formatType && typeFormatters[formatType]) {
        if (data) {
          let fieldValue = data[fieldKey][innerKey];
          data[fieldKey][innerKey] = typeFormatters[formatType](
            String(fieldValue)
          );
        }
      }
    });
  });
};

export const formatFieldsInCurrentControlArray = (
  schema: Schema,
  data: any,
  schemaKey: string,
) => {
  const controls = schema.fields[schemaKey].spec.meta.control;

  Object.keys(controls).forEach((key: string) => {
    const controlItem = controls[key];
    const formatType = controlItem?.props?.formatType;

    if (formatType && typeFormatters[formatType]) {
      if (data) {
        const fieldValue = data[controlItem.props.name];
        data[controlItem.props.name] = typeFormatters[formatType](String(fieldValue));
      }
    }
  });
};