import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./_metronic/layout/core";
import { MasterInit } from "./_metronic/layout/MasterInit";
import { Drawer } from "./modules/common/other/Drawer";
import { FixedTermAnnuity } from "./pages/Info_articles/FixedTermAnnuity";
import { LifetimeAnnuity } from "./pages/Info_articles/LifetimeAnnuity";
import { FlexibleAccessDrawdown } from "./pages/Info_articles/FlexibleAccessDrawdown";
import { Booking } from "./pages/booking/Booking";
import { QuoteError } from "./pages/Info_articles/QuoteError";
import { usePortal } from "./context/PortalContext";
import { Banner } from "./modules/common/other/Banner";

const App = () => {
  const { isSlow }: any = usePortal();

  return (
    <LayoutProvider>
      {/*Slow connection banner*/}
      <Banner
        show={isSlow}
        bannerMessage={{
          text: "Sorry, your request is taking longer than usual to process. We apologise for the delay...",
          cssClasses: "fw-bold text-center",
        }}
        colour="229, 67, 67"
        opacity="0.95"
        icon={{
          path: "../public/media/icons/duotune/general/gen012.svg",
          cssClasses: "svg-icon-2hx text-danger",
        }}
      />

      {/* add info drawers that can be used throughout app */}
      <Drawer
        id="whatIsALifeTimeAnnuity"
        title="What is a Lifetime Annuity?"
        content={<LifetimeAnnuity />}
      />
      <Drawer
        id="whatIsAFixedTermAnnuity"
        title="What is a Fixed Term Annuity?"
        content={<FixedTermAnnuity />}
      />
      <Drawer
        id="whatIsFlexibleAccessDrawdown"
        title="What is Flexible Access Drawdown?"
        content={<FlexibleAccessDrawdown />}
      />
      <Drawer id="booking" title="Book an appointment" content={<Booking />} />
      <Drawer
        id="quoteError"
        title="Why couldn't we get all quotes?"
        content={<QuoteError />}
      />
      <Outlet />
      <MasterInit />
    </LayoutProvider>
  );
};

export { App };
