import { useMemo } from "react";
import { ObjectShape } from "yup/lib/object";

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

interface IDynamicSchemaCondition {
  fieldName: string;
  fieldValue: any ;
}

interface FormSchema {
  header: string;
  description: string;
  stepperDescription?: string;
  schema: Schema;
  isPerson2: boolean;
  pageNumber: number;
  dynamicSchemaConditions?: Array<IDynamicSchemaCondition>;
}

function getNestedValue(obj: any, path: string) {
  const pathParts = path.split(".");
  
  return pathParts.reduce((accumulator, current) => {
    return accumulator && accumulator[current];
  }, obj);
}

const useDynamicSchemas = (
  formSchemas: FormSchema[],
  values: Record<string, any>,
) => {
  const filteredSchemas = useMemo(() => {
    let combinedSchemas: FormSchema[] = [];

    formSchemas?.forEach((formSchema) => {
      // Default to true so that schemas without conditions always show
      let showSchema = true;

      // If the schema has conditions to determine whether it should show
      if (formSchema && formSchema?.dynamicSchemaConditions) {
        showSchema = formSchema?.dynamicSchemaConditions.some((field) => {
          const fieldValue = getNestedValue(values, field.fieldName);
          
          if (Array.isArray(field.fieldValue)) {
            return field.fieldValue.includes(fieldValue);
          }
  
          return fieldValue === field.fieldValue;
        });
      }

      // If the schema should show then push it into the combined schemas
      if (showSchema) {
        combinedSchemas.push(formSchema);
      }
    })

    // Return schemas sorted by page number
    return combinedSchemas.sort((schemaA, schemaB) => schemaA.pageNumber - schemaB.pageNumber);
  }, [formSchemas, values]);

  return filteredSchemas;
};

export default useDynamicSchemas;
