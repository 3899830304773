import { getMedicalInfoSchema } from "../Shared/MedicalInfoSchema";
const header = "Further Health & Lifestyle";

const description = "A few more details about your health and lifestyle";
const stepperDescription = "A few more details about your health and lifestyle";

const person1MedicalSchema = getMedicalInfoSchema(false);
const person2MedicalSchema = getMedicalInfoSchema(true);

export { header, description, person1MedicalSchema, person2MedicalSchema };
