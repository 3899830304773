import React, { useState, useRef, useEffect } from "react";
import {
  FieldArray,
  useField,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik";
import { Modal } from "react-bootstrap";
import { ReturnElement } from "../Helpers";
import * as Yup from "yup";
import { formatFieldsInCurrentControlArray } from "../Formatters";
import { ObjectShape } from "yup/lib/object";

interface YupSchema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  fieldArrayName: string;
  fieldArrayLabel: string;
  fieldArrayDescription: string;
  itemTypeName?: string;
  controls: any;
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
  required?: boolean;
  tooltip?: ITooltip;
  isPerson2: boolean;
  formikValues: Record<string, any>;
  // If provided, use the value of this field as the title if getItemTitle is not provided.
  itemTitleField?: string;
  // New: if provided, this function is used to generate the title from an item.
  getItemTitle?: (item: any, index: number) => string;
  fullSchema: YupSchema;
  schemaKey: string;
}

interface ITooltip {
  button: React.ReactNode;
  notice: React.ReactNode;
}

interface ItemModalFormProps {
  show: boolean;
  initialValues: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
  controls: any;
  isPerson2: boolean;
  modalIndex: number | null;
  modalSchema: YupSchema;
  itemTypeName?: string;
  outerFormikFormData?: any;
}

const ItemModalForm: React.FC<ItemModalFormProps> = ({
  show,
  initialValues,
  onClose,
  onSubmit,
  controls,
  isPerson2,
  modalIndex,
  modalSchema,
  itemTypeName,
  outerFormikFormData,
}) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: modalSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const flattenErrors = (errors: any): string[] => {
    let errorList: string[] = [];
    Object.values(errors).forEach((value) => {
      if (typeof value === "string") {
        errorList.push(value);
      } else if (typeof value === "object" && value !== null) {
        errorList = errorList.concat(flattenErrors(value));
      }
    });
    return errorList;
  };

  // Force a reset when initialValues change.
  useEffect(() => {
    formik.resetForm({ values: initialValues });
  }, [initialValues]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdropClassName="modal-backdrop-confirmation"
      backdrop="static"
      className="modal-lg"
      // Optional: ensure the modal is rendered at the top level
      container={document.body}
    >
      <FormikProvider value={formik}>
        {/* Stop event propagation to prevent bubbling to the parent form */}
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            formik.handleSubmit(e);
          }}
          noValidate
        >
          <div className="modal-header">
            <h5 className="modal-title">
              {modalIndex === null ? "New " + itemTypeName : itemTypeName}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body px-10">
            {controls.map((element: any, elementMapKey: number) => {
              let updatedElementProps = {
                ...element.props,
                name: element.props.name,
              };
              if (element.props.valuesFunction) {
                // Pass the outer Formik values as needed.
                updatedElementProps = {
                  ...updatedElementProps,
                  passedInFormData: outerFormikFormData,
                };
              }
              const updatedElement = { ...element, props: updatedElementProps };
              return (
                <React.Fragment
                  key={`${modalIndex}_${elementMapKey}_${element.props.name}`}
                >
                  {ReturnElement(updatedElement, isPerson2, formik.values)}
                </React.Fragment>
              );
            })}
          </div>
          <div className="modal-footer">
            {!formik.isValid ? (
              <div className="text-danger mt-2 d-flex justify-content-end">
                Please correct the above errors.
              </div>
            ) : null}
            {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
              <div className="text-danger mt-2">
                <ul>
                  {flattenErrors(formik.errors).map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Done
            </button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

const defaultTitle = (item: any, index: number): string => {
  const keys = Object.keys(item).filter(
    (key) => key !== "id" && item[key] && item[key].toString().trim() !== ""
  );
  if (keys.length > 0) {
    return keys
      .slice(0, 2)
      .map((key) => item[key])
      .join(" ");
  }
  return `Item ${index + 1}`;
};

export const FieldArrayModalComponent: React.FC<IProps> = (props) => {
  const formData = useFormikContext().values;
  const {
    fieldArrayName,
    controls,
    itemTitleField,
    getItemTitle,
    fullSchema,
    schemaKey,
  } = props;
  const [field] = useField(fieldArrayName);
  const counter = useRef(0);

  const fieldArraySchema = fullSchema as Yup.ObjectSchema<any>;
  const modalSchema = fieldArraySchema.fields[schemaKey].innerType;

  // Create a new row object for new items.
  const createNewRowObject = () => {
    const newRowObject: any = { id: counter.current++ };
    controls.forEach((element: any) => {
      newRowObject[element.props.name] = "";
    });
    return newRowObject;
  };

  // Manage modal state.
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const [modalInitialValues, setModalInitialValues] = useState<any>({});

  const openNewItemModal = () => {
    const newObj = createNewRowObject();
    setModalIndex(null);
    setModalInitialValues(newObj);
    setModalOpen(true);
  };

  const openEditModal = (
    index: number,
    item: any,
    formatSchema: YupSchema,
    schemaKey: string
  ) => {
    formatFieldsInCurrentControlArray(formatSchema, item, schemaKey);
    setModalIndex(index);
    setModalInitialValues(item);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5" />}
      <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
        {/* Label text */}
        <span className={props.required ? "required" : ""}>
          {props.fieldArrayLabel}
        </span>

        {/* Button (tooltips, icons, etc.) */}
        <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
          {props.tooltip?.button && props.tooltip.button}
        </div>
      </label>
      {props.tooltip?.notice}
      <div className="form-text mb-5">{props.fieldArrayDescription}</div>
      <FieldArray name={fieldArrayName}>
        {({ push, remove, replace }) => (
          <>
            <div className="row">
              {field.value?.length > 0 &&
                field.value.map((item: any, index: number) => {
                  const title =
                    getItemTitle && typeof getItemTitle === "function"
                      ? getItemTitle(item, index)
                      : itemTitleField && item[itemTitleField]
                      ? item[itemTitleField]
                      : defaultTitle(item, index);
                  return (
                    <div className="col-md-4 mb-3" key={`${index}_${item.id}`}>
                      <div className="card card-rounded h-100">
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{title}</h5>
                          <div className="mt-auto">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary me-2"
                              onClick={() =>
                                openEditModal(
                                  index,
                                  item,
                                  fullSchema,
                                  schemaKey
                                )
                              }
                            >
                              Open
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <button
              type="button"
              className="btn btn-sm btn-primary mb-5 mt-5"
              onClick={openNewItemModal}
            >
              <i className="la la-plus"></i>{" "}
              {props.itemTypeName
                ? "Add " + props.itemTypeName
                : "Add New Item"}
            </button>
            {modalOpen && (
              <ItemModalForm
                key={JSON.stringify(modalInitialValues)}
                show={modalOpen}
                initialValues={modalInitialValues}
                onClose={handleCloseModal}
                onSubmit={(values) => {
                  if (modalIndex === null) {
                    push(values);
                  } else {
                    replace(modalIndex, values);
                  }
                  setModalOpen(false);
                }}
                controls={controls}
                isPerson2={props.isPerson2}
                modalIndex={modalIndex}
                modalSchema={modalSchema}
                itemTypeName={props.itemTypeName}
                outerFormikFormData={formData}
              />
            )}
          </>
        )}
      </FieldArray>
      {props.addSeparatorBelow && <hr className="py-5" />}
    </div>
  );
};

export default FieldArrayModalComponent;
