import * as Yup from "yup";
import { fieldValidation } from "../../Validators";
import { typeFormatters } from "../../Formatters";
import { residenceTypeLookup, ownershipLookup } from "../../Lookups";

export const propertySchema = Yup.object().shape({
  propertyDetails: Yup.array()
    .of(
      Yup.object().shape({
        address: Yup.string().label("Address").nullable(),
        city: Yup.string().label("City").nullable(),
        postcode: Yup.string()
          .label("Postcode")
          .nullable()
          .test(
            "is-valid-postcode",
            "Please enter a valid UK postcode.",
            (value) =>
              fieldValidation(value, "postcode", undefined, undefined, true)
          ),
        estimatedValue: Yup.string()
          .label("Estimated Value")
          .required("Please enter the approximate property value.")
          .transform(typeFormatters["currency"])
          .test(
            "is-valid-propertyValue",
            "Please enter a valid estimated property value between £10,000 and £10,000,000",
            (value) => fieldValidation(value, "currency", 10000, 10000000, true)
          ),
        ownershipID: Yup.string()
          .label("Ownership")
          .required("Please tell us who owns this property.")
          .notOneOf(["0", ""], "Please select an option"),
        residenceTypeID: Yup.string()
          .label("Type of residence")
          .required("Please tell us what type of residence this is.")
          .notOneOf(["0", ""], "Please select an option"),
      })
    )
    .label("Properties")
    .meta({
      fieldArrayName: "properties",
      fieldArrayDescription:
        "Please add details for any properties you have below",
      addSeparatorAbove: false,
      itemTypeName: "Property",
      itemTitleField: "address",
      control: [
        {
          componentType: "InputBox",
          props: {
            name: "address",
            label: "What is the address?",
            required: true,
            description: "Please enter your house name/number and street",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "city",
            label: "What is the city?",
            required: true,
            description: "Please enter the city",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "Postcode",
          props: {
            label: "What is the postcode?",
            description: "",
            name: "postcode",
            required: true,
            placeholder: "",
            tooltip: "",
            inputType: "text",
            prefix: undefined,
            addressLine1FieldName: "",
            cityFieldName: "",
            includeFindAddress: false,
            formatType: "postcode"
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "estimatedValue",
            label: "What is the estimated value of your property?",
            required: true,
            description: "Please enter the estimated value of your property",
            placeholder: "",
            inputType: "text",
            tooltip: "",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: false,
            formatType: "currency",
          },
        },
        {
          componentType: "Lookup",
          props: {
            name: "ownershipID",
            label: "Who owns the property?",
            required: true,
            description: "Please tell us who owns this property",
            placeholder: "",
            inputType: "text",
            tooltip: "",
            addSeparatorBelow: false,
            values: ownershipLookup,
          },
        },
        {
          componentType: "Lookup",
          props: {
            name: "residenceTypeID",
            label: "What type of residence is it?",
            required: true,
            description: "Please tell us what type of residence it is",
            placeholder: "",
            inputType: "text",
            tooltip: "",
            addSeparatorBelow: false,
            values: residenceTypeLookup,
          },
        },
      ],
    }),
});
