import { CTA1 } from "../../modules/common/cards/CTA1";
import { CTA1NoCard } from "../../modules/common/cards/CTA1NoCard";

export const LifetimeAnnuity = () => {
  return (
    <>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>What is it?</h3>
        <div>
          A lifetime annuity is a financial product that offers a steady income
          stream for individuals throughout their retirement. By investing a
          lump sum upon retirement, the purchaser secures regular payouts for
          the rest of their life. This arrangement can be particularly
          beneficial for those who want to ensure they have a reliable income
          source during their golden years and avoid outliving their savings.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>When might this be ideal?</h3>
        <div>
          Consider Jane, a 65-year-old woman who has recently retired from her
          career as a nurse. She has a history of chronic health issues, such as
          diabetes and high blood pressure, which could potentially shorten her
          life expectancy. In Jane's case, a lifetime annuity with an enhanced
          annuity option might be the best choice. Enhanced annuities take into
          account an individual's health conditions and lifestyle factors, and
          they often offer higher payouts than standard annuities due to the
          potentially shorter life expectancy. By opting for an enhanced
          lifetime annuity, Jane can secure a higher regular income during her
          retirement, allowing her to maintain her desired standard of living
          while ensuring financial stability for the rest of her life.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <CTA1NoCard
          title={<div className="mb-5 ms-20">Need help?</div>}
          backGroundImageSrc="media/illustrations/bw/png/question.png"
          button={[
            {
              to: "#",
              label: "Request A call back",
              className: "btn btn-primary fw-semibold ms-20 px-6 py-3",
              function: undefined,
              id: "booking",
            },
          ]}
        />
      </div>
    </>
  );
};
