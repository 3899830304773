import {
    employmentStatusLookup,
    employmentTypeLookup,
    occupationLookup,
  } from "../../forms/Lookups";
import {
    ExtractLabelFromGroupedOptionsBasedOnValue,
    ExtractLabelFromLookupOptionsBasedOnValue,
} from "../../../modules/forms/Helpers";
import { groupedOptions as groupedOccupations } from "../../../models/EmploymentData";

interface IEmployment {
    occupation?: string;
    employmentType?: string;
    employmentStatus?: string;
    dateStarted?: string;
    dateEnded?: string;
}

export function getEmployment(item: any, index: number): string {
  const employmentName = ExtractLabelFromGroupedOptionsBasedOnValue(
    item.occupation,
    groupedOccupations
  );

  const employmentType = ExtractLabelFromLookupOptionsBasedOnValue(
    item.employmentType,
    employmentTypeLookup
  );

  if (employmentName && employmentType) {
    return `${employmentType} - ${employmentName}`;
  }

  // Fallback: return a default title if no match is found.
  return `Occupation ${index + 1}`;
}

export const calculateEmploymentDisplayValues = (employment: IEmployment) => {
  // The CRM uses "Retired" instead of an ID for retired and "" for Unemployed
  // Handle this and return UI friendly labels
  if (employment?.occupation === "Retired" || employment?.occupation === "") {
    let employmentDisplayValues: string[] = [
      employment?.occupation !== "" ? employment.occupation : "Unemployed",
      "N/A",
      employment?.occupation !== "" ? employment.occupation : "Unemployed",
      employment.dateStarted ?? "Unknown",
      employment.occupation === "Retired" ? "N/A" : employment.dateEnded ?? "Unknown" 
    ];

    return employmentDisplayValues;
  }

  // Otherwise its a known occupcation and we can process it and define the correct labels
  let occupationSearch = occupationLookup.find(
    (item) => item.value === employment.occupation
  );

  let occupation = occupationSearch?.label ?? "Unknown";

  let employmentType: string;
  let employmentStatus: string;
  let employmentStatusSearch: {value: string, label: string} | undefined;

  if (!["3243", "3585"].includes(occupationSearch?.value ?? "")){
    let employmentTypeSearch= employmentTypeLookup.find(
      (item) => item.value === employment.employmentType
    );

    employmentStatusSearch = employmentStatusLookup.find(
      (item) => item.value === employment.employmentStatus
    );

    employmentType = employmentTypeSearch?.label ?? "Unknown";
    employmentStatus = employmentStatusSearch?.label ?? "Unknown";
  } else {
    employmentType = occupationSearch?.value === "3243" ? "Retired" : "Unemployed";
    employmentStatus = occupationSearch?.value === "3243" ? "Retired" : "Unemployed";
  }

  let dateEnded = occupationSearch?.value === "3585"
    || (employmentStatusSearch && !["3663", "3664"].includes(employmentStatusSearch?.value ?? ""))
    ? employment.dateEnded ?? "" : "N/A";

  let employmentDisplayValues: string[] = [
    occupation,
    employmentType,
    employmentStatus,
    employment.dateStarted ?? "Unknown",
    dateEnded
  ];

  return employmentDisplayValues;
}