import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import { ReactNode } from "react";

export const Radios = (props: IProps) => {
  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="mb-10 fv-row">
        <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
          {props.label}
          <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
            {props.tooltip?.button && props.tooltip.button}
          </div>
        </label>

        {props.tooltip?.notice && props.tooltip.notice}
        {props.description != "" && (
          <small className="form-text">{props.description}</small>
        )}
        <div className="row mb-2 mt-4" data-kt-buttons="true">
          {props.items.map((item, index) => {
            return (
              <>
                {/* begin::Input row */}
                <div className="d-flex fv-row">
                  {/* begin::Radio */}
                  <div className="form-check form-check-custom form-check-solid">
                    {/* begin::Input */}
                    <Field
                      type="radio"
                      className="form-check-input me-"
                      name={props.name}
                      value={item.value}
                      id={props.name + "-" + index}
                      disabled={item.disabled}
                      checked={item.checked}
                      defaultChecked={item.defaultChecked}
                    />
                    {/* end::Input */}
                    {/* begin::Label */}
                    <label
                      className="form-check-label"
                      htmlFor={props.name + "-" + index}
                    >
                      <div className="fw-bolder text-gray-800">
                        {item.label}
                      </div>
                      <div className="text-gray-600">{item.description}</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                {/* end::Input row */}
                {index < props.items.length - 1 && (
                  <div className="separator separator-dashed my-5"></div>
                )}
              </>
            );
          })}
        </div>

        <div className="text-danger mt-5">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  tooltip?: ITooltip;
  items: IItem[];
  name: string;
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
}

interface IItem {
  label: string;
  value: string;
  description?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
