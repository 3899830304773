import { ErrorMessage, Field, useField } from "formik";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { typeFormatters } from "../Formatters";

const InputBox = (props: IProps) => {
  const [field, meta, helpers] = useField(props.name);
  const [inputValue, setInputValue] = useState(props.initialValue);

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const handleBlur = (e: any) => {
    // Run formatters to format input
    let formattedValue = inputValue;
    let formatter = typeFormatters[props.formatType ?? ""];

    if (formatter !== null && formatter !== undefined) {
      formattedValue = formatter(inputValue);
    }

    helpers.setValue(formattedValue);
  };

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="d-flex flex-column mb-7 fv-row">
        <div className="mb-2">
          <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
            {/* Label text */}
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>

            {/* Button (tooltips, icons, etc.) */}
            <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
              {props.tooltip?.button && props.tooltip.button}
            </div>
          </label>
          {props.tooltip?.notice && props.tooltip.notice}
          {props.description != "" && (
            <div className="form-text">{props.description}</div>
          )}
        </div>

        <div className="input-group input-group-solid mt-5">
          {props.prefix && (
            <div className="input-group-text">{props.prefix}</div>
          )}

          <Field
            type={props.inputType}
            className="form-control form-control-solid"
            placeholder={props.placeholder}
            name={props.name}
            value={inputValue}
            onChange={handleChange}
            onBlur={(e: any) => handleBlur(e)} // Trigger re-render on blur
            autoComplete="off"
            key={props.name}
          />
        </div>

        <div className="text-danger mt-2 field-error">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  name: string;
  initialValue: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  inputType: string;
  prefix: ReactNode;
  className?: string;
  addSeparatorAbove?: boolean;
  addSeparatorBelow?: boolean;
  formatType?: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

export { InputBox };
export type { IProps };
