import * as Yup from "yup";
import { pensionSchema } from "../Shared/PensionSchema";
import { propertySchema } from "../Shared/PropertySchema";
import { PersonalDetailsSchema } from "./PersonalDetailsSchema";
import { getMedicalInfoSchema } from "./MedicalInfoSchema";
import { employmentSchema } from "./EmploymentSchema";

const medInfoSchema = getMedicalInfoSchema(false);

const AllData = Yup.object().shape({
  //add all shared yup schemas
  PersonalDetailsSchema,
  medInfoSchema,
  pensionSchema,
  propertySchema,
  employmentSchema,
});

export { AllData };
