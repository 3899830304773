import React from "react"
import { KTSVG } from "../../../_metronic/helpers"

interface IBannerProps {
  show: boolean;
  bannerMessage: IBannerMessage;
  colour: string;
  opacity: string;
  icon: IIcon;
};

interface IIcon {
    path: string;
    cssClasses: string;
}

interface IBannerMessage {
    text: string;
    cssClasses: string;
}

export const Banner: React.FC<IBannerProps> = ({ show, bannerMessage, colour, opacity, icon }) => {
  return (
    <div
      className="position-fixed top-0 start-0 w-100 p-4 rounded-bottom"
      style={{
        zIndex: 9999,
        backgroundColor: `rgba(${colour}, ${opacity})`,
        transform: show ? "translateY(0)" : "translateY(-150%)",
        transition: "transform 1s ease-in-out",
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div className="me-3">
          <KTSVG
            path={icon.path}
            className={icon.cssClasses}
          />
        </div>
        <div className={bannerMessage.cssClasses}
        >  
          {bannerMessage.text}
        </div>
      </div>
    </div>
  )
};
