import * as Yup from "yup";
import { fieldValidation } from "../../Validators";

export const PersonalDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .label("Title")
    .required("Please specify your title.")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Title",
          description: "",
          name: "title",
          tooltip: "",
          required: true,
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
      },
    }),
  firstName: Yup.string()
    .label("First Name")
    .required("Please enter your first name.")
    .test(
      "is-valid-firstName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "First Name",
          description: "",
          name: "firstName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  middleName: Yup.string()
    .label("Middle Name")
    .nullable()
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Middle Name",
          description: "",
          name: "middleName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  lastName: Yup.string()
    .label("Last Name")
    .required("Please enter your last name.")
    .test(
      "is-valid-lastName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Last Name",
          description: "",
          name: "lastName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  hasPreviousNamesYesNo: Yup.string()
    .label("Do you have any previous names?")
    .required(
      "Please specify if you have previsouly been known by another name."
    )
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Do you have any previous names?",
          description:
            "We need to know this to ensure we have accurate historical data and can correctly match any records.",
          name: "hasPreviousNamesYesNo",
          tooltip: "",
          required: true,
        },
      },
    }),

  previousNames: Yup.string()
    .label("Previous Names")
    .nullable()
    .test(
      "is-valid-previousName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, false)
    )
    .when("hasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().required(
        "Please enter the previous name you have been known by."
      ),
    })
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Previous Names",
          description: "",
          name: "previousNames",
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "hasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),
  previousNamesDateChanged: Yup.string()
    .label("Date changed")
    .nullable()
    .when("hasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().required(
        "Please specify the date you changed your name."
      ),
    })
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "previousNamesDateChanged",
          label: "Date changed",
          required: true,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear(),
        },
        dependsOnFields: [
          { fieldName: "hasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  sex: Yup.string()
    .label("Sex")
    .required("Please specify your sex.")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          required: true,
          label: "Sex",
          description: "",
          name: "sex",
          tooltip: "",
          items: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ],
        },
      },
    }),

  dateOfBirth: Yup.string()
    .label("Date of Birth")
    .required("Please enter your date of birth.")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "dateOfBirth",
          label: "Your Date of Birth",
          required: true,
          description: "You must be over 55 to get quotes.",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear() - 4,
        },
      },
    }),

  maritalStatus: Yup.string()
    .label("Marital Status")
    .required("Please specify your marital status.")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          required: true,
          label: "Marital Status",
          description:
            "Your marital status can affect your pension benefits, especially if you wish to add protection to your product which will provide income to your spouse or partner in the event of your death.",
          name: "maritalStatus",
          tooltip: "",
          values: [
            "",
            "Single",
            "Married",
            "Divorced",
            "Widowed",
            "Separated",
            "Co-habiting",
            "Civil Partnership",
            "Intend To Marry",
            "Intend To Form Civil Partnership",
            "Dissolved Civil Partnership",
          ],
        },
      },
    }),

  niNumber: Yup.string()
    .label("NI Number")
    .required("Please enter your national insurance number.")
    // .test(
    //   "is-valid-niNumber",
    //   "Please enter a valid UK national insurance number.",
    //   (value) => fieldValidation(value, "niNumber", undefined, undefined, false)
    // )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "NI Number",
          description:
            "This is needed for identification purposes and to access any relevant pension data",
          name: "niNumber",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  email: Yup.string()
    .label("Email")
    .test("is-valid-email", "Please enter a valid email address.", (value) =>
      fieldValidation(value, "email", 0, 50, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your Email",
          description: "",
          name: "email",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  postcode: Yup.string()
    .label("Postcode")
    .nullable()
    .test("is-valid-postcode", "Please enter a valid UK postcode.", (value) =>
      fieldValidation(value, "postcode", undefined, undefined, true)
    )
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Your Postcode",
          description: "",
          name: "postcode",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addressLine1FieldName: "address",
          cityFieldName: "city",
          includeFindAddress: true,
          formatType: "postcode",
        },
      },
    }),

  address: Yup.string()
    .label("address")
    .required("Please enter the first line of your address.")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your Address",
          description: "",
          name: "address",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  city: Yup.string()
    .label("city")
    .required("Please enter the city which you live in.")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your City",
          description: "",
          name: "city",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  client1OrJoint: Yup.string()
    .label("Would you like to add a spouse or partner?")
    .required(
      "Please specify if you would like to add your spouse/partners details."
    )
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Would you like to add a spouse or partner?",
          description: "",
          name: "client1OrJoint",
          tooltip: "",
          items: [
            { label: "Yes", value: "1496" },
            { label: "No", value: "1494" },
          ],
        },
      },
    }),

  person2Title: Yup.string()
    .label("person2Title")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required("Please enter their title."),
    })
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Their Title",
          description: "",
          name: "person2Title",
          tooltip: "",
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2FirstName: Yup.string()
    .label("person2FirstName")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required("Please enter their first name."),
    })
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their First Name",
          description: "",
          name: "person2FirstName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2MiddleName: Yup.string()
    .label("person2MiddleName")
    .nullable()
    .test(
      "is-valid-middleName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, false)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Middle Name",
          description: "",
          name: "person2MiddleName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2LastName: Yup.string()
    .label("person2LastName")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required("Please enter their last name."),
    })
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Last Name",
          description: "",
          name: "person2LastName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2HasPreviousNamesYesNo: Yup.string()
    .label("person2HasPreviousNamesYesNo")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required(
        "Please specify if they have been known by any previous names."
      ),
    })
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Do they have any previous names?",
          description: "",
          name: "person2HasPreviousNamesYesNo",
          tooltip: "",
          required: true,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2PreviousNames: Yup.string()
    .label("person2PreviousNames")
    .nullable()
    .when("person2HasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string()
        .required("Please enter their previous name.")
        .test(
          "is-valid-previousName",
          "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
          (value) => fieldValidation(value, "name", 3, 25, true)
        ),
    })
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Previous Names",
          description: "",
          name: "person2PreviousNames",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          { fieldName: "client1OrJoint", fieldValue: "1496" },
          { fieldName: "person2HasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2PreviousNamesDateChanged: Yup.string()
    .label("person2PreviousNamesDateChanged")
    .nullable()
    .when("person2HasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().required(
        "Please tell us the date they changed their name."
      ),
    })
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "person2PreviousNamesDateChanged",
          label: "Date changed",
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear(),
        },
        dependsOnConditional: "and",
        dependsOnFields: [
          { fieldName: "client1OrJoint", fieldValue: "1496" },
          { fieldName: "person2HasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2Sex: Yup.string()
    .label("person2Sex")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required("Please tell us their sex."),
    })
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Their Sex",
          description: "",
          name: "person2Sex",
          tooltip: "",
          items: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ],
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2DateOfBirth: Yup.string()
    .label("person2DateOfBirth")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required("Please enter their date of birth."),
    })
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "person2DateOfBirth",
          label: "Their Date of Birth",
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear() - 4,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2NINumber: Yup.string()
    .label("person2NINumber")
    .nullable()
    .when("client1OrJoint", {
      is: (val: string) => val === "true",
      then: Yup.string().required(
        "Please enter their national insurance number."
      ),
    })
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their NI Number",
          description: "",
          name: "person2NINumber",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2PostCode: Yup.string()
    .label("person2PostCode")
    .nullable()
    .test("is-valid-postcode", "Please enter a valid UK postcode.", (value) =>
      fieldValidation(value, "postcode", undefined, undefined, false)
    )
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Their Postcode",
          description: "",
          name: "person2PostCode",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addressLine1FieldName: "person2Address",
          cityFieldName: "person2City",
          includeFindAddress: true,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2Address: Yup.string()
    .label("person2Address")
    .nullable()
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Address",
          description: "",
          name: "person2Address",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),

  person2City: Yup.string()
    .label("person2City")
    .nullable()
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their City",
          description: "",
          name: "person2City",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [{ fieldName: "client1OrJoint", fieldValue: "1496" }],
      },
    }),
});
