import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { App } from "../App";
import { Home } from "../pages/Home";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { AuthPage } from "../modules/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import DashboardWrapper from "../pages/portal_home/Dashboard";
import { UserAuthContextProvider } from "../context/AuthContext";
import { PortalContextProvider } from "../context/PortalContext";
import { QuickQuote } from "../pages/annuity_quote/quick_quote/QuickQuote";
import { AnnuityQuotes } from "../pages/annuity_quote/AnnuityQuotes";
import { GetStarted } from "../pages/GetStarted";
import { FullQuote } from "../pages/annuity_quote/full_quote/FullQuote";
import { Factfind } from "../pages/factfind/Factfind";
import { FormTestPage } from "../pages/FormTestPage";
import { BookingFull } from "../pages/booking/BookingFull";
import { FactfindCompleted } from "../pages/factfind/FactfindCompleted";
import { NextSteps } from "../pages/NextSteps";
import ProtectedRoute from "./ProtectedRoute";
import RequireAuth from "./RequireAuth";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const [user] = useAuthState(auth);
  
  const loggedInRoutes = (
    <>
    {/*If you are logged in the '/' will take you to the dashboard otherwise it will take you to the homepage*/}
      {user ? (<Route path="/*" element={<DashboardWrapper />} />) : null}

      <Route path="/dashboard/*" element={<DashboardWrapper />} />
      <Route path="/portal/*" element={<DashboardWrapper />} />

      <Route path="/fullquote" element={<FullQuote />} />
      <Route
        path="/annuityquotes"
        element={
            <AnnuityQuotes refreshOnLoad={false} />
      } />
      <Route
        path="/annuityquotes/refresh"
        element={<AnnuityQuotes refreshOnLoad={true} />} />
      <Route path="/FormTestPage" element={<FormTestPage />} />
      <Route path="/NextSteps" element={<NextSteps />} />   

      {/*Protected routes - You must have complete a previous step to get here*/}
      <Route path="/factfind" element={
        <ProtectedRoute 
          requireFullQuote        
        >
          <Factfind />
        </ProtectedRoute>
      } />
      <Route path="/factfindcompleted" element={
        <ProtectedRoute 
          requireFullQuote 
          requireFactfindCompleted
        >
          <FactfindCompleted />
        </ProtectedRoute>
      } />      
      <Route
        path="/bookifacall"
        element={
          <ProtectedRoute 
            requireFullQuote 
            requireFactfindCompleted
          >
            <BookingFull
              calendlyURL={
                "https://calendly.com/ifa-5-g/ifa-appointment?hide_event_type_details=1&hide_gdpr_banner=1&branding=false"
              }
            />
          </ProtectedRoute>
      }/>  
    </>
  );

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <UserAuthContextProvider>
        <PortalContextProvider>
          <Routes>
            <Route element={<App />}>
              <Route element={<MasterLayout />}>
                <Route element={<RequireAuth />}>
                  {loggedInRoutes}
                </Route>

                {/*If you are logged in the '/' will take you to the dashboard otherwise it will take you to the homepage*/}
                {!user ? (<Route path="/" element={<Home />} />) : null}
                <Route path="/home" element={<Home />}></Route>
                <Route path="/quickquote" element={<QuickQuote />}></Route>
                <Route path="/getstarted" element={<GetStarted />}></Route>
                <Route
                  path="/quickquote/annuityquotes"
                  element={<AnnuityQuotes refreshOnLoad />}
                ></Route>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="error/*" element={<ErrorsPage />} />
              </Route>
            </Route>
          </Routes>
        </PortalContextProvider>
      </UserAuthContextProvider>
    </BrowserRouter>
  );
};

export { AppRoutes };
