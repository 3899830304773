import React from "react";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { CheckboxBoxes } from "./form_fields/CheckboxBoxes";
import { DateDDMMYYYY } from "./form_fields/DateDDMMYYYY";
import { DateMMYYYY } from "./form_fields/DateMMYYYY";
import { FieldArrayModalComponent } from "./form_fields/FieldArrayModalComponent";
import { FieldArrayComponent } from "./form_fields/FieldArrayComponent";
import { InputBox } from "./form_fields/InputBox";
import { Postcode } from "./form_fields/Postcode";
import { Lookup } from "./form_fields/Lookup";
import { LookupWithSearchAndGroups } from "./form_fields/LookupWithSearchAndGroups";
import { RadioBoxes } from "./form_fields/RadioBoxes";
import { YesNoBoxes } from "./form_fields/YesNoBoxes";
import WithVisibility from "./WithVisibility";

interface YupSchema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

function GetFormFieldsWithHeaderAndFields(
  schema: YupSchema,
  header: string,
  description: string,
  isPerson2: boolean,
  values: Record<string, any>
) {
  const formFields = GetFormFields(schema, isPerson2, values);

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder d-flex align-items-center text-dark">
          {header}
        </h2>

        <div className="text-gray-400 fw-bold fs-6">{description}</div>
      </div>
      <div className="fv-row">
        <div className="row align-items-end">{formFields}</div>
      </div>
    </div>
  );
}

function ReturnElement(
  control: any,
  isPerson2: boolean,
  formikValues: Record<string, any>
) {
  const label = control.props.label && control.props.label;
  const description = control.props.description && control.props.description;
  const name = control.props.name && control.props.name;
  const initialValue = control.props.initialValue && control.props.initialValue;
  const required = control.props.required && control.props.required;
  const placeholder = control.props.placeholder && control.props.placeholder;
  const tooltip = control.props.tooltip && control.props.tooltip;
  const inputType = control.props.inputType && control.props.inputType;
  const prefix = control.props.prefix && control.props.prefix;
  const items = control.props.items && control.props.items;
  const startYear = control.props.startYear && control.props.startYear;
  const endYear = control.props.endYear && control.props.endYear;
  const controlValues = control.props.values && control.props.values;
  const className = control.props.className && control.props.className;
  const addSeparatorAbove =
    control.props.addSeparatorAbove && control.props.addSeparatorAbove;
  const addSeparatorBelow =
    control.props.addSeparatorBelow && control.props.addSeparatorBelow;
  const dependsOnFields = control.dependsOnFields && control.dependsOnFields;
  const dependsOnConditional =
    control.dependsOnConditional && control.dependsOnConditional;
  const groupFilterField =
    control.props.groupFilterField && control.props.groupFilterField;
  const addressLine1FieldName =
    control.props.addressLine1FieldName && control.props.addressLine1FieldName;
  const CityFieldName =
    control.props.cityFieldName && control.props.cityFieldName;
  const IncludeFindAddress =
    control.props.includeFindAddress && control.props.includeFindAddress;
  const ValuesFunction =
    control.props.valuesFunction && control.props.valuesFunction;
  const allowFreeType =
    control.props.allowFreeType && control.props.allowFreeType;
  const unknownEntryValue =
    control.props.unknownEntryValue && control.props.unknownEntryValue;
  const formatType = control.props.formatType && control.props.formatType;
  const passedInFormData =
    control.props.passedInFormData && control.props.passedInFormData;

  const InputBoxWithVisibility = WithVisibility(InputBox, formikValues);
  const PostcodeWithVisibility = WithVisibility(Postcode, formikValues);
  const CheckboxBoxesWithVisibility = WithVisibility(
    CheckboxBoxes,
    formikValues
  );
  const DateDDMMYYYYWithVisibility = WithVisibility(DateDDMMYYYY, formikValues);
  const DateMMYYYYWithVisibility = WithVisibility(DateMMYYYY, formikValues);
  const LookupWithVisibility = WithVisibility(Lookup, formikValues);
  const RadioBoxesWithVisibility = WithVisibility(RadioBoxes, formikValues);
  const YesNoBoxesWithVisibility = WithVisibility(YesNoBoxes, formikValues);
  const LookupWithSearchAndGroupsWithVisibility = WithVisibility(
    LookupWithSearchAndGroups,
    formikValues
  );

  return (
    <>
      {control.componentType === "InputBox" && (
        <InputBoxWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          placeholder={placeholder}
          tooltip={tooltip}
          inputType={inputType}
          prefix={prefix}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          initialValue={initialValue}
          formatType={formatType}
        />
      )}
      {control.componentType === "Postcode" && (
        <PostcodeWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          placeholder={placeholder}
          tooltip={tooltip}
          inputType={inputType}
          prefix={prefix}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          initialValue={initialValue}
          cityFieldName={CityFieldName}
          addressLine1FieldName={addressLine1FieldName}
          includeFindAddress={IncludeFindAddress}
          formatType={formatType}
        />
      )}
      {control.componentType === "CheckboxBoxes" && (
        <CheckboxBoxesWithVisibility
          label={label}
          description={description}
          tooltip={tooltip}
          items={items}
          name={name}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          required={required}
        />
      )}
      {control.componentType === "DateDDMMYYYY" && (
        <DateDDMMYYYYWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          startYear={startYear}
          endYear={endYear}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
        />
      )}
      {control.componentType === "DateMMYYYY" && (
        <DateMMYYYYWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          startYear={startYear}
          endYear={endYear}
          placeholder={placeholder}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
        />
      )}
      {control.componentType === "Lookup" && (
        <LookupWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          placeholder={placeholder}
          values={controlValues}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          valuesFunction={ValuesFunction}
          isPerson2={isPerson2}
          passedInFormData={passedInFormData}
        />
      )}
      {control.componentType === "RadioBoxes" && (
        <RadioBoxesWithVisibility
          label={label}
          description={description}
          tooltip={tooltip}
          items={items}
          name={name}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          required={required}
        />
      )}
      {control.componentType === "YesNoBoxes" && (
        <YesNoBoxesWithVisibility
          label={label}
          description={description}
          tooltip={tooltip}
          name={name}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          required={required}
        />
      )}
      {control.componentType === "LookupWithSearchAndGroups" && (
        <LookupWithSearchAndGroupsWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          placeholder={placeholder}
          values={controlValues}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          groupFilterField={groupFilterField}
          allowFreeType={allowFreeType}
          unknownEntryValue={unknownEntryValue}
        />
      )}
    </>
  );
}

function IterateThroughFieldsInSchema(
  yupSchema: YupSchema,
  isPerson2: boolean,
  formikValues: Record<string, any>,
  onDependencyChange?: (fieldName: string) => void
) {
  return Object.entries(yupSchema.fields).map(([key, value]) => {
    // If it's a schema within a schema (annuity within client) then get the fields from the sub schema
    if (value.fields) {
      return (
        <React.Fragment key={key}>
          {IterateThroughFieldsInSchema(
            value,
            isPerson2,
            formikValues,
            onDependencyChange
          )}
        </React.Fragment>
      );
    }

    // Changes for depends on
    const dependsOnFields =
      value.spec.meta.dependsOnFields && value.spec.meta.dependsOnFields;
    const control = value.spec.meta.control;
    // If it's not an array, just map each field normally
    if (!Array.isArray(control)) {
      return (
        <React.Fragment key={key}>
          {ReturnElement(control, isPerson2, formikValues)}
        </React.Fragment>
      );
    }
    // If it's an array of controls, then add as a fieldArray
    else {
      const fieldArrayName = value.spec.meta.fieldArrayName;
      const fieldArrayLabel = value.spec.label;
      const fieldArrayDescription = value.spec.meta.fieldArrayDescription;
      const className = value.spec.meta.className;
      const addSeparatorBelow = value.spec.meta.addSeparatorBelow;
      const addSeparatorAbove = value.spec.meta.addSeparatorAbove;
      const dependsOnConditional = value.spec.meta.dependsOnConditional;
      const itemTypeName = value.spec.meta.itemTypeName;
      //const FieldArrayComponentWithVisibility = WithVisibility(
      //  FieldArrayComponent,
      //  formikValues,
      //);
      const FieldArrayModalComponentWithVisibility = WithVisibility(
        FieldArrayModalComponent,
        formikValues
      );
      const itemTitleField = value.spec.meta.itemTitleField;
      const getItemTitle = value.spec.meta.getItemTitle;

      return (
        <FieldArrayModalComponentWithVisibility
          key={fieldArrayName}
          fieldArrayName={fieldArrayName}
          fieldArrayLabel={fieldArrayLabel}
          fieldArrayDescription={fieldArrayDescription}
          controls={control}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          className={className}
          dependsOnFields={dependsOnFields}
          dependsOnConditional={dependsOnConditional}
          isPerson2={isPerson2}
          formikValues={formikValues}
          itemTitleField={itemTitleField}
          getItemTitle={getItemTitle}
          fullSchema={yupSchema}
          schemaKey={key}
          itemTypeName={itemTypeName}
        />
        // <FieldArrayComponentWithVisibility
        //   key={fieldArrayName}
        //   fieldArrayName={fieldArrayName}
        //   fieldArrayLabel={fieldArrayLabel}
        //   fieldArrayDescription={fieldArrayDescription}
        //   controls={control}
        //   addSeparatorAbove={addSeparatorAbove}
        //   addSeparatorBelow={addSeparatorBelow}
        //   className={className}
        //   dependsOnFields={dependsOnFields}
        //   dependsOnConditional={dependsOnConditional}
        //   isPerson2={isPerson2}
        //   formikValues={formikValues}
        //   itemTitleField={itemTitleField}
        // />
      );
    }
  });
}

const GetFormFields = (
  yupSchema: YupSchema,
  isPerson2: boolean,
  formikValues: Record<string, any>,
  onDependencyChange?: (fieldName: string) => void
) => {
  return IterateThroughFieldsInSchema(
    yupSchema,
    isPerson2,
    formikValues,
    onDependencyChange
  );
};

function extractKeysFromDescription(
  description: any,
  parentPath: string = ""
): string[] {
  let keys: string[] = [];
  if (description.fields) {
    for (const key in description.fields) {
      const fullKey = parentPath ? `${parentPath}.${key}` : key;
      keys.push(fullKey);
      const nested = description.fields[key];
      if (nested.type === "object" && nested.fields) {
        keys = keys.concat(extractKeysFromDescription(nested, fullKey));
      }
    }
  }
  return keys;
}

// This function is used to extract all the keys from a Yup schema
export function getAllPropertyNames(schema: Yup.AnySchema): string[] {
  const description = schema.describe() as any;
  if (description && description.fields) {
    return Object.keys(description.fields);
  }
  return [];
}

interface IOption {
  value: string;
  label: string;
}

interface IGroupedOption {
  label: string;
  groupValue: string;
  options: readonly IOption[];
}

function ExtractLabelFromGroupedOptionsBasedOnValue(
  value: string,
  groups: IGroupedOption[]
): string | null {
  // Iterate through each group in the provided groupedOptions array.
  for (const group of groups) {
    // Find the option with the matching value.
    const foundOption = group.options.find((option) => option.value === value);
    if (foundOption) {
      // Return the label if found.
      return foundOption.label;
    }
  }
  // Return null if no matching option is found.
  return null;
}

function ExtractLabelFromLookupOptionsBasedOnValue(
  value: string,
  options: IOption[]
): string | null {
  // Iterate through each option
  for (const option of options) {
    // Find the option with the matching value.

    if (option.value === value) {
      return option.label;
    }
  }
  return null;
}

export {
  GetFormFields,
  GetFormFieldsWithHeaderAndFields,
  ReturnElement,
  ExtractLabelFromGroupedOptionsBasedOnValue,
  ExtractLabelFromLookupOptionsBasedOnValue,
};
