import { useEffect, useState } from "react";
import { usePortal } from "../../context/PortalContext";
import { IJournalDocument, IPension } from "../../models/ClientData";
import { Notice } from "../../modules/common/cards/Notice";
import { DocumentsCollection } from "../../modules/common/other/DocumentsCollection";
import { KTSVG } from "../../_metronic/helpers";
import { Loader } from "../../modules/common/modals/LoaderModal";
import { useNavigate } from "react-router-dom";

export const MyDocuments = () => {
  const { clientData, returnClientData }: any = usePortal();
  const [portalDocsSignatures, setPortalDocumentsSignatures] = useState(
    Array<IJournalDocument>()
  );
  const [portalDocs, setPortalDocuments] = useState(Array<IJournalDocument>());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      let responseCode = await returnClientData();

      if (responseCode !== 200) {
        navigate(`/error/${responseCode}`);
      }
    };

    if (!clientData) {
      fetchClientData();
    }
  }, []);

  useEffect(() => {
    // Docs that require a signature
    let docsRequiringSignature = Array<IJournalDocument>();

    clientData?.journal?.forEach((clientDoc: IJournalDocument) => {
      if (clientDoc.requiresSignature) {
        docsRequiringSignature.push(clientDoc);
      }
    });

    clientData?.annuity?.journalDocuments?.forEach(
      (annuityDoc: IJournalDocument) => {
        if (annuityDoc.requiresSignature) {
          docsRequiringSignature.push(annuityDoc);
        }
      }
    );

    clientData?.pensions?.forEach((pension: IPension) => {
      if (
        pension &&
        Array.isArray(pension.journalDocuments) &&
        pension.journalDocuments.length > 0
      ) {
        pension?.journalDocuments?.forEach((pensionDoc: IJournalDocument) => {
          if (pensionDoc.requiresSignature) {
            docsRequiringSignature.push(pensionDoc);
          }
        });
      }
    });

    setPortalDocumentsSignatures(docsRequiringSignature);

    // Docs that don't require a signature
    let docsNoSignature = Array<IJournalDocument>();

    clientData?.journalDocuments.forEach((clientDoc: IJournalDocument) => {
      if (!clientDoc.requiresSignature) {
        docsNoSignature.push(clientDoc);
      }
    });

    clientData?.annuity?.journalDocuments.forEach(
      (annuityDoc: IJournalDocument) => {
        if (!annuityDoc.requiresSignature) {
          docsNoSignature.push(annuityDoc);
        }
      }
    );

    clientData?.pensions?.forEach((pension: IPension) => {
      if (
        pension &&
        Array.isArray(pension.journalDocuments) &&
        pension.journalDocuments.length > 0
      ) {
        pension?.journalDocuments?.forEach((pensionDoc: IJournalDocument) => {
          if (!pensionDoc.requiresSignature) {
            docsNoSignature.push(pensionDoc);
          }
        });
      }
    });

    setPortalDocuments(docsNoSignature);
  }, [clientData]);

  return (
    <>
      {clientData ? (
        <>
          <div className="flex-wrap flex-stack mb-6">
            {Array.isArray(portalDocsSignatures) &&
            portalDocsSignatures.length > 0 ? (
              <>
                <h3 className="fw-bolder my-2">
                  Documents That Require Your Signature
                </h3>
                <DocumentsCollection
                  notice={
                    <Notice
                      noticeClasses={"mb-10 bg-light-warning border-warning"}
                      icon={
                        <KTSVG
                          path="media/icons/duotune/art/art008.svg"
                          className="svg-icon svg-icon-3x svg-icon-dark"
                        />
                      }
                      iconClasses={""}
                      header={"Signature Required"}
                      content={
                        <>
                          <div className="mt-5">
                            Please review and sign these documents so that we
                            can progress with your case.
                          </div>
                        </>
                      }
                      button={null}
                    />
                  }
                  documents={[
                    ...portalDocsSignatures.map(
                      (item: IJournalDocument, index) => ({
                        name: item.name,
                        createdDate: item.date,
                        data: item.file,
                        type: item.type,
                        docusignRedirectURL: item.redirectURL,
                        requiresSignature: item.requiresSignature,
                      })
                    ),
                  ]}
                />
              </>
            ) : null}
          </div>
          <h3 className="fw-bolder my-2">All Documents</h3>
          <div className="flex-wrap flex-stack mb-6">
            {Array.isArray(portalDocs) && portalDocs.length > 0 ? (
              <>
                <DocumentsCollection
                  documents={[
                    ...portalDocs.map((item: IJournalDocument, index) => ({
                      name: item.name,
                      createdDate: item.date,
                      data: item.file,
                      type: item.type,
                      docusignRedirectURL: item.redirectURL,
                      requiresSignature: item.requiresSignature,
                    })),
                  ]}
                />
              </>
            ) : (
              <Notice
                noticeClasses={
                  "notice d-flex bg-light-warning rounded border-dark border-dashed mt-5 mb-5 p-6"
                }
                button={null}
                header={"No documents found"}
                content={"You don't have any documents here yet..."}
                icon={null}
                iconClasses={null}
              />
            )}
          </div>
        </>
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </>
  );
};
