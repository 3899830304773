import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";

interface IProps {
  personName?: string;
  personJobTitle?: string;
  personNote?: string;
  imageSrc?: string;
  imageBase64?: string;
  buttons?: IButtons[] | null;
}

interface IButtons {
  label: string;
  to: string;
  id: string;
  function: () => void | null;
  className: string | null;
}

export const Person = (props: IProps) => {
  return (
    <>
      <div className="card card-rounded mb-5" dir="ltr">
        <div className="card-body d-flex flex-column flex-center">
          <div className="mb-2">
            <div className="fs-1 text-center lh-lg">
              Meet
              <span className="fw-bolder"> {props.personName}</span>
            </div>

            <div className="py-10 text-center">
              {props.imageBase64 ? (
                <img
                  src={"data:image/png;base64," + props.imageBase64}
                  className="w-200px rounded-circle"
                  alt=""
                />
              ) : (
                <img
                  src={props.imageSrc}
                  className="w-200px rounded-circle"
                  alt=""
                />
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start mb-10 ">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex align-items-center mb-2">
                <div className="symbol  symbol-35px symbol-circle ">
                  {props.imageBase64 ? (
                    <img
                      src={"data:image/png;base64," + props.imageBase64}
                      alt=""
                    />
                  ) : (
                    <img src={props.imageSrc} alt="" />
                  )}
                </div>

                <div className="ms-3">
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >
                    {props.personName}
                  </a>
                  <br />
                  <span className="text-muted fs-7 mb-1">
                    {props.personJobTitle}
                  </span>
                </div>
              </div>
              <div
                className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                <Typewriter
                  options={{
                    strings: [`${props.personNote}`],
                    autoStart: true,
                    loop: false,
                    delay: 30,
                    deleteSpeed: 100000000000,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="text-center mb-5 ">
            {props.buttons?.map((item, index) => {
              if (!item.function) {
                return (
                  <Link
                    id={item.id}
                    key={item.label}
                    to={item.to}
                    className={
                      item.className
                        ? item.className
                        : "btn btn-primary align-self-center"
                    }
                  >
                    {item.label}
                  </Link>
                );
              } else {
                return (
                  <a
                    id={item.id}
                    key={item.label}
                    href="#"
                    onClick={() => item.function()}
                    className={
                      item.className
                        ? item.className
                        : "btn btn-primary align-self-center"
                    }
                  >
                    {item.label}
                  </a>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};
