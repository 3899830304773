import React, { useEffect, useRef, useState } from "react";
import { usePortal } from "../../../context/PortalContext";

interface IProps {
  url: string;
  prefill?: IPrefill;
}

interface IPrefill {
  name?: string;
  email?: string;
}

const CalendlyWidget = (props: IProps) => {
  const [url] = useState(props.url);
  const [prefill] = useState(props.prefill);

  const { clientData, apiUpdateClientData } = usePortal();

  const hasLoaded = useRef(false);

  useEffect(() => {
    window.removeEventListener('message', handleCalendlyMessage );
    window.addEventListener(
      'message',
      handleCalendlyMessage
    );

    async function handleCalendlyMessage (e: any) {
      if (isCalendlyEvent(e) && e.data.event === "calendly.event_scheduled" && url.includes('ifa-appointment')) {
        let updatedClientData = {
          ...clientData,
          annuity: {
            ...clientData?.annuity,
            bookIFAAppointment: "true",
          },
        };
  
        if (apiUpdateClientData && updatedClientData) {
          await apiUpdateClientData(updatedClientData);
        }
      }
    };
  }, [apiUpdateClientData, clientData, url]);

  useEffect(() => {
    if (!hasLoaded.current && (window as any).Calendly) {
      hasLoaded.current = true;

      (window as any).Calendly.initInlineWidget({
        url: url,
        parentElement: document.querySelector(".calendly-inline-widget"),
        prefill: prefill,
        branding: false,
        utm: {},
      });
    }
    // Optionally, add props.url to the dependency array if the URL might change:
  }, [url, prefill]);

  function isCalendlyEvent(e: any) {
    return e.data.event &&
           e.data.event.indexOf('calendly') === 0;
  };

  return (
    <div
      className="calendly-inline-widget"
      style={{ width: "100%", border: "none", height: "1500px" }} // Removed height: "100%" so it can grow naturally.
      data-auto-load="false"
    />
  );
};

export default CalendlyWidget;
