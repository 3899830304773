import * as Yup from "yup";
import { fieldValidation } from "../../Validators";

const header = "About You";

const description =
  "Tell us a little about yourself (no contact details needed!).";

const stepperDescription =
  "Tell us a little about yourself (no contact details needed!).";

const schema = Yup.object().shape({
  title: Yup.string()
    .label("Title")
    .required("Please specify your title.")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Title",
          description: "",
          name: "title",
          tooltip: "",
          required: true,
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
      },
    }),
  firstName: Yup.string()
    .label("First Name")
    .required("Please enter your first name.")
    .test(
      "is-valid-firstName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "First Name",
          description: "",
          name: "firstName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  lastName: Yup.string()
    .label("Last Name")
    .required("Please enter your last name.")
    .test(
      "is-valid-lastName",
      "Please enter a minimum of 3 characters and no more than 25 characters. Do not enter any special characters, or numbers.",
      (value) => fieldValidation(value, "name", 3, 25, true)
    )
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Last Name",
          description: "",
          name: "lastName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  dateOfBirth: Yup.string()
    .label("Date of Birth")
    .required("Please enter your date of birth.")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "dateOfBirth",
          label: "Your Date of Birth",
          required: true,
          description: "You must be over 55 to get quotes.",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1943,
          endYear: new Date().getFullYear() - 50,
        },
      },
    }),
  postcode: Yup.string()
    .label("Postcode")
    .required("Please enter a valid UK postcode.")
    .test("is-valid-postcode", "Please enter a valid UK postcode.", (value) =>
      fieldValidation(value, "postcode", undefined, undefined, true)
    )
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Your Postcode",
          description: "",
          name: "postcode",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addSeparatorBelow: true,
          formatType: "postcode",
        },
      },
    }),
});

export { header, description, schema, stepperDescription };
