import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { GetFormFields } from "../../forms/Helpers";
import { KTSVG } from "../../../_metronic/helpers";
import { Notice } from "../cards/Notice";
import { ScrollToError } from "../other/ScrollToError";
import { CloseConfirmationModal } from "./CloseConfirmationModal";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  handleClose: () => void;
  show: boolean;
  schema: Yup.ObjectSchema<any>;
  data?: Record<string, any>;
  modalHeader: string;
  handleSubmit: (data: any) => Promise<void> | void;
  fullscreen?: boolean;
  recordLocked?: boolean;
  isPerson2: boolean;
  enableReinitialize?: boolean;
}

export const EditDataModal = (props: ModalProps) => {
  const { enableReinitialize, show, handleClose, handleSubmit, data, schema } =
    props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  const initialValues = data || {};

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: enableReinitialize,
    onSubmit: async (values: any) => {
      submitBtnRef.current?.setAttribute("data-kt-indicator", "on");
      submitBtnRef.current?.setAttribute("disabled", "true");

      await handleSubmit(values);
      handleClose();
    },
  });

  const formFields = GetFormFields(schema, props.isPerson2, formik.values);

  const close = () => {
    setShowConfirmationModal(true);
  };

  const flattenErrors = (errors: any): string[] => {
    let errorList: string[] = [];

    Object.values(errors).forEach((value) => {
      if (typeof value === "string") {
        errorList.push(value);
      } else if (typeof value === "object" && value !== null) {
        errorList = errorList.concat(flattenErrors(value));
      }
    });

    return errorList;
  };

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationDiscard = () => {
    handleSubmit(formik.initialValues); // Reset all the form fields to their original values
    setShowConfirmationModal(false);
    handleClose();
  };

  const handleConfirmationSubmit = async () => {
    formik.handleSubmit();
    setShowConfirmationModal(false);
  };

  return (
    <FormikProvider value={formik}>
      <Modal
        id="editDataModal"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName={
          props.fullscreen
            ? "modal-dialog modal-dialog-centered modal-fullscreen"
            : "modal-dialog modal-dialog-centered mw-900px"
        }
        show={show}
        onHide={close}
        backdrop={true}
      >
        <div className="modal-header">
          <h2>{props.modalHeader}</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={close}
          >
            <KTSVG
              className="svg-icon-1"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
          {/* end::Close */}
        </div>
        <div className="modal-body px-0 py-lg-10 px-lg-10">
          <div className="flex-row-fluid py-lg-5 px-lg-15">
            <form
              className={
                props.fullscreen
                  ? "py-5 w-100 px-5 container-xl"
                  : "py-5 w-100 w-xl-700px px-5"
              }
              id="ModalForm"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {formFields}
            </form>
          </div>
        </div>

        <div className="modal-footer">
          {!formik.isValid ? (
            <div className="text-danger mt-2 d-flex justify-content-end">
              Please correct the above errors.
            </div>
          ) : null}
          {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
            <div className="text-danger mt-2">
              <ul>
                {flattenErrors(formik.errors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}

          {!props.recordLocked ? (
            <button
              type="button"
              className="btn btn-lg btn-primary me-3"
              ref={submitBtnRef}
              onClick={async () => {
                // Manually call validation and scroll to first error if there are any
                await formik.validateForm();

                if (!formik.isValid) {
                  ScrollToError();
                }

                // Validate then call the onSubmit property function for formik
                await formik.submitForm();
              }}
            >
              <span className="indicator-label">Save</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          ) : (
            <Notice
              noticeClasses="mb-10 bg-light-warning border-warning"
              icon={
                <KTSVG
                  path="/media/icons/duotune/general/gen047.svg"
                  className="svg-icon svg-icon-3x svg-icon-dark"
                />
              }
              iconClasses=""
              header="Record locked"
              content={
                <div className="mt-5">
                  If you need to make any changes to your details, please
                  contact us on{" "}
                  <span className="text-dark text-bolder">0808 281 2558.</span>
                </div>
              }
              button={null}
            />
          )}
        </div>
      </Modal>

      {/*Close confirmation modal*/}
      <CloseConfirmationModal
        show={showConfirmationModal}
        modalHeader="Are you sure?"
        modalMessage="Are you sure you want to close without saving? This will discard any changes you have made."
        handleClose={handleConfirmationClose}
        handleSubmit={handleConfirmationSubmit}
        handleDiscard={handleConfirmationDiscard}
      />
    </FormikProvider>
  );
};
