// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// //dev
const firebaseConfig = {
  apiKey: "AIzaSyBKdnM-CswcvnUzowBcXxNl4c1rcPzHTfk",
  authDomain: "fwsite-808e2.firebaseapp.com",
  projectId: "fwsite-808e2",
  storageBucket: "fwsite-808e2.firebasestorage.app",
  messagingSenderId: "202244532376",
  appId: "1:202244532376:web:afb5f3f15cf505030248ed"
};

//live
// const firebaseConfig = {
//   apiKey: "AIzaSyBvboFA2RyXyPVlXNZeFC5zJpeZawIWRhE",
//   authDomain: "penseportallive.firebaseapp.com",
//   projectId: "penseportallive",
//   storageBucket: "penseportallive.firebasestorage.app",
//   messagingSenderId: "666943355766",
//   appId: "1:666943355766:web:0c80bd1f892dc52c78d857",
//   measurementId: "G-0YBXG48EMJ"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
//export const facebookAuthProvider = new FacebookAuthProvider();
