import { Navigate, Outlet } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../config/firebase"

/**
 * This component ensures the user is logged in.
 * If not, it redirects them to /auth/login by default.
 */
export default function RequireAuth() {
  const [user, loading] = useAuthState(auth)

  // While we check Firebase Auth, you might render a loading spinner or just return null
  if (loading) {
    return null
  }

  // If there's no logged-in user, send them to the login page
  if (!user) {
    return <Navigate to="/auth/login" replace />
  }

  // If the user *is* authenticated, render the child routes
  return <Outlet />
}
