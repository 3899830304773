import { PageTitle } from "../../../_metronic/layout/core";
import { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { usePortal } from "../../../context/PortalContext";
import { useNavigate } from "react-router-dom";
import { ObjectShape } from "yup/lib/object";
import { StepperForm } from "../../../modules/common/other/StepperForm";

import {
  schema as page1Schema,
  header as page1Header,
  description as page1Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage1";

import {
  schema as page2Schema,
  header as page2Header,
  description as page2Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage2";

import {
  person1MedicalSchema,
  header as page3Header,
  description as page3Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage3";

import { person2MedicalSchema } from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage3";

import {
  schema as page5Schema,
  header as page5Header,
  description as page5Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage4";

import { Loader } from "../../../modules/common/modals/LoaderModal";
import { Notice } from "../../../modules/common/cards/Notice";
import { CTA1 } from "../../../modules/common/cards/CTA1";
import appInsights from "../../../azureAppInsights";

export const FullQuote = () => {
  <PageTitle>Retirement Quote</PageTitle>;

  const { returnClientData, clientData, apiUpdateClientData }: any =
    usePortal();

  const [formData, setFormData] = useState(undefined);

  useEffect(() => {
    const fetchClientData = async () => {
      let responseCode = await returnClientData();

      if (responseCode !== 200) {
        navigate(`/error/${responseCode}`);
      }
    };

    console.log(clientData);
    if (!clientData) {
      fetchClientData();
    } else {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (clientData) {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }

    console.log(clientData);
  }, [clientData]);

  const navigate = useNavigate();
  const formSchemas: FormSchema[] = [];

  formSchemas.push({
    header: page1Header,
    description: page1Desctiption,
    schema: page1Schema,
    isPerson2: false,
    pageNumber: 0,
  });

  formSchemas.push({
    header: page2Header,
    description: page2Desctiption,
    schema: page2Schema,
    isPerson2: false,
    pageNumber: 1,
  });

  formSchemas.push({
    header: page3Header,
    description: page3Desctiption,
    schema: person1MedicalSchema,
    isPerson2: false,
    pageNumber: 2,
  });

  formSchemas.push({
    header: "Person 2 Further Health & Lifestyle",
    description: "A few more details about your spouse/significant other",
    schema: person2MedicalSchema,
    isPerson2: true,
    pageNumber: 3,
    dynamicSchemaConditions: [
      {
        fieldName: "client1OrJoint",
        fieldValue: "1496",
      },
    ],
  });

  formSchemas.push({
    header: page5Header,
    description: page5Desctiption,
    schema: page5Schema,
    isPerson2: false,
    pageNumber: 4,
  });

  async function submit(values: any) {
    console.log(values);

    try {
      const responseCode: number = await apiUpdateClientData(values);

      if (responseCode === 200) {
        navigate("/annuityquotes/refresh");
      } else {
        navigate(`/error/${responseCode}`);
      }
    } catch (error: any) {
      console.error(error);

      let appInsightsError: Error = {
        name: "FullQuote - apiUpdateClientData Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      navigate(`/error/${errorCode}`);
    }
  }

  return (
    <>
      {formData ? (
        <StepperForm
          initialStep={2}
          data={formData}
          formSchemas={formSchemas}
          handleSubmit={submit}
          navigateAfterSubmitTo={undefined}
          SaveAndExitButton={false}
          TopContent={
            <div className="mb-5">
              <CTA1
                title={<div className="mb-5">Tailored quote</div>}
                content={
                  <>
                    <div className="fs-4 text-gray-800">
                      Add further details on your health & lifestyle to
                      potentially
                      <br />
                      increase the amount you could recieve.
                    </div>
                  </>
                }
                imageSrc="media/illustrations/bw/png/profit.png"
              />
            </div>
          }
          FormFootercontent={
            clientData?.annuity?.status != "Portal" && (
              <>
                <Notice
                  noticeClasses={"mt-10 bg-light-warning border-warning"}
                  icon={
                    <KTSVG
                      path="/media/icons/duotune/general/gen047.svg"
                      className="svg-icon svg-icon-3x svg-icon-dark"
                    />
                  }
                  iconClasses={""}
                  header={"Record locked"}
                  content={
                    <>
                      <div className="mt-5">
                        Changes will not be saved, if you need to make any
                        changes to your details, please contact us on{" "}
                        <span className="text-dark text-bolder">
                          {" "}
                          0808 281 2558.
                        </span>
                      </div>
                    </>
                  }
                  button={null}
                />
              </>
            )
          }
        />
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </>
  );
};

interface FormSchema {
  header: string;
  description: string;
  stepperDescription?: string;
  schema: Schema;
  isPerson2: boolean;
  pageNumber: number;
  dynamicSchemaConditions?: Array<IDynamicSchemaCondition>;
}

interface IDynamicSchemaCondition {
  fieldName: string;
  fieldValue: any;
}

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}
