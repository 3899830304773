import React, { ReactElement, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { typeFormatters } from "../../../modules/forms/Formatters";

interface IProps {
  title: string;
  columns: TableColumn[];
  rows: IRow[];
}

interface IRow {
  data: string[];
}

interface TableColumn {
  name: string;
  dataType: string;
  formatType?: string;
  prefix?: string;
}

enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export const Table = (props: IProps) => {
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Ascending);

  const formatData = (data: string, formatType: string | undefined): string => {
    let formattedValue = data;
    let formatter = typeFormatters[formatType ?? ""];

    if (formatter !== null && formatter !== undefined) {
      formattedValue = formatter(formattedValue);
    }

    return formattedValue;
  };

  const handleSort = (columnName: string) => {
    if (sortColumn === columnName) {
      // Toggle the sort order if the same column is clicked
      setSortOrder((prevSortOrder) =>
        prevSortOrder === SortOrder.Ascending
          ? SortOrder.Descending
          : SortOrder.Ascending
      );
    } else {
      // Set the new sort column and default to ascending order
      setSortColumn(columnName);
      setSortOrder(SortOrder.Ascending);
    }
  };

  const getSortedRows = (): IRow[] => {
    if (sortColumn) {
      const columnIndex = props.columns.findIndex(
        (column) => column.name === sortColumn
      );
      if (columnIndex !== -1) {
        const sortedRows = [...props.rows].sort((a, b) =>
          a.data[columnIndex].localeCompare(b.data[columnIndex])
        );
        return sortOrder === SortOrder.Ascending
          ? sortedRows
          : sortedRows.reverse();
      }
    }
    
    return props.rows;
  };

  const sortedRows = getSortedRows();

  return (
    <>
      {props.rows && props.rows.length > 0 && (
        <div className="table-responsive">
          <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
            <thead>
              <tr className="border-0">
                {props.columns.map((column: TableColumn, columnIndex: number) => (
                  <th
                    key={columnIndex}
                    className={`text-muted fw-bolder min-w-150px ${
                      sortColumn === column.name ? "sorted" : ""
                    }`}
                    onClick={() => handleSort(column.name)}
                  >
                    {column.name}
                    {sortColumn === column.name && (
                      <span className="sort-icon">
                        {sortOrder === SortOrder.Ascending ? (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr073.svg"
                            className="svg-icon-sm svg-icon-primary ms-1"
                          />
                        ) : (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr072.svg"
                            className="svg-icon-sm svg-icon-primary ms-1"
                          />
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedRows.map((row: IRow, rowIndex: number) => (
                <tr key={rowIndex}>
                  {row.data.map((data: string, rowDataIndex: number) => (
                    <td key={rowDataIndex} className="text-dark fw-bold fs-6">
                      {props.columns[rowDataIndex].prefix && props.columns[rowDataIndex].prefix} {formatData(data, props.columns[rowDataIndex].formatType)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>    
  );
};

export default Table;
