/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { usePortal } from "../../context/PortalContext";
import { NavCard } from "../../modules/common/cards/NavCard";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { Application } from "./Application";
import { MyDetails } from "./MyDetails";
import { MyDocuments } from "./MyDocuments";
import { MyQuotes } from "./MyQuotes";
import { Overview } from "./Overview";
import { CarouselModal } from "../../modules/common/modals/CarouselModal";
import { Loader } from "../../modules/common/modals/LoaderModal";
import { Notice } from "../../modules/common/cards/Notice";

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: "Dashboard",
    path: "",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const DashboardWrapper = () => {
  const [displayName, setDisplayName] = useState<string>("");
  const [showFirstLoginModal, setShowFirstLoginModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [user, error] = useAuthState(auth);
  const { retrieveClientData, returnClientData, clientData, firstLogin }: any =
    usePortal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!clientData || !clientData?.id) {
      const fetchClientData = async () => {
        let responseCode;

        if (!clientData) {
          responseCode = await retrieveClientData();
        } else if (!clientData?.id) {
          responseCode = await returnClientData();
        }

        if (responseCode !== 200) {
          if (responseCode === 404) {
            console.log(`${responseCode} - Client data not found!`);
          } else {
            navigate(`/error/${responseCode}`);
          }
        }
      };

      fetchClientData();
    }
    if (clientData && clientData.firstName) {
      setDisplayName(clientData.firstName);
      setLoading(false);
    }
  }, [clientData]);

  useEffect(() => {
    if (firstLogin === true && loading === false) {
      setShowFirstLoginModal(true);
    }
  }, [firstLogin, loading]);

  if (loading) {
    return <Loader show={loading} text="Loading your data" />;
  } else {
    return (
      <Routes>
        <Route
          element={
            <>
              <CarouselModal
                handleClose={() => setShowFirstLoginModal(false)}
                show={showFirstLoginModal}
                modalHeader={""}
                items={[
                  {
                    title: "You're logged in!",
                    content: (
                      <div>
                        You're now logged in. The next step is to get a more
                        tailored quote, based on your lifestyle and medical
                        information. Click below to get started.
                      </div>
                    ),
                    button1Override: {
                      className: "btn btn-primary align-self-center ms-2 mb-5",
                      function: () => {},
                      to: "../../../fullquote",
                      label: "Get a tailored quote",
                      nextOrprev: null,
                    },
                    button2Override: undefined,
                    addNextPrevButtons: false,
                  },
                ]}
                imageSrc="media\illustrations\bw\png\file-manager.png"
              />

              <div className="pb-10 fs-3">
                Hello <span className="fw-bolder">{displayName}</span>, welcome
                to your portal where you can manage your retirement.
              </div>

              {
                <NavCard
                  links={[
                    {
                      label: "Overview",
                      to: "/overview",
                    },
                    {
                      label: "My Details",
                      to: "/mydetails",
                    },
                    {
                      label: "My Quotes",
                      to: "/myquotes",
                    },
                    {
                      label: "My Application",
                      to: "/myapplication",
                    },
                    {
                      label: "My Documents",
                      to: "/mydocuments",
                    },
                  ]}
                  buttons={null}
                />
              }
              {clientData?.annuity?.status != "Portal" && (
                <>
                  <Notice
                    noticeClasses={"mb-10 bg-light-warning border-warning"}
                    icon={
                      <KTSVG
                        path="/media/icons/duotune/general/gen047.svg"
                        className="svg-icon svg-icon-3x svg-icon-dark"
                      />
                    }
                    iconClasses={""}
                    header={"Record locked"}
                    content={
                      <>
                        <div className="mt-5">
                          If you need to make any changes to your details,
                          please contact us on{" "}
                          <span className="text-dark text-bolder">
                            {" "}
                            0808 281 2558.
                          </span>
                        </div>
                      </>
                    }
                    button={null}
                  />
                </>
              )}

              <Outlet />
            </>
          }
        >
          <Route
            path="overview"
            element={
              <>
                <Overview />
              </>
            }
          />
          <Route
            path="mydetails"
            element={
              <>
                <MyDetails />
              </>
            }
          />

          <Route
            path="mydocuments"
            element={
              <>
                <MyDocuments />
              </>
            }
          />

          <Route
            path="myapplication"
            element={
              <>
                <Application />
              </>
            }
          />

          <Route
            path="myquotes"
            element={
              <>
                <MyQuotes />
              </>
            }
          />

          <Route index element={<Navigate to="/overview" />} />
        </Route>
      </Routes>
    );
  }
};

export default DashboardWrapper;
