import { ErrorMessage, Field, useField } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

export const DateMMYYYY = (props: IProps) => {
  //the below updates a formik field's value after the date has been changed
  const [field, meta, helpers] = useField(props.name);

  const getInitialThisDate = () => {
    if (!field.value) {
      return {
        month: -1,
        year: 0,
      };
    }

    const formats = [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "YYYY/MM/DD",
      "DD/MM/YYYY",
      "YYYY-MM-DDTHH:mm:ss",
      "DD-MM-YYYYTHH:mm:ss",
      "YYYY/MM/DDTHH:mm:ss",
      "DD/MM/YYYYTHH:mm:ss",
      "YYYY-MM-DD HH:mm:ss",
      "DD-MM-YYYY HH:mm:ss",
      "YYYY/MM/DD HH:mm:ss",
      "DD/MM/YYYY HH:mm:ss",
    ];

    let date = moment.invalid();
    for (const format of formats) {
      date = moment(field.value, format, true);
      if (date.isValid()) {
        break;
      }
    }

    if (date.isValid()) {
      return {
        month: date.month(),
        year: date.year(),
      };
    } else {
      console.log("Invalid date format");
      return {
        month: -1,
        year: 0,
      };
    }
  };

  const [thisDate, setDate] = useState(getInitialThisDate());

  // useEffect(() => {
  //   let year = thisDate.year.toString();

  //   let month = (Number(thisDate.month) + 1).toString();
  //   month.length < 2 && (month = "0" + month);

  //   let day = "01";

  //   if (moment(year + "-" + month + "-" + day, "YYYY-MM-DD", true).isValid()) {
  //     helpers.setValue(year + "-" + month + "-" + day);
  //   } else {
  //     helpers.setValue("");
  //   }
  // }, [thisDate]);

  // const { value: formikFieldValue } = field;
  // useEffect(() => {
  //   let year = thisDate.year.toString();
  //   year.length < 2 && (year = "0" + year);

  //   let month = (Number(thisDate.month) + 1).toString();
  //   month.length < 2 && (month = "0" + month);

  //   let day = "01";
  //   day.length < 2 && (day = "0" + day);

  //   const newValue = year + "-" + month + "-" + day;

  //   if (
  //     moment(newValue, "YYYY-MM-DD", true).isValid() &&
  //     newValue !== formikFieldValue
  //   ) {
  //     helpers.setValue(newValue);
  //   } else if (
  //     !moment(newValue, "YYYY-MM-DD", true).isValid() &&
  //     formikFieldValue
  //   ) {
  //     helpers.setValue("");
  //   }
  // }, [thisDate, formikFieldValue, helpers]);

  const updateFormikFieldValue = (date: { month: number; year: number }) => {
    let year = date.year.toString();
    year.length < 2 && (year = "0" + year);

    let month = (Number(date.month) + 1).toString();
    month.length < 2 && (month = "0" + month);

    let day = "01";
    day.length < 2 && (day = "0" + day);

    const newValue = year + "-" + month + "-" + day;

    if (
      moment(newValue, "YYYY-MM-DD", true).isValid() &&
      newValue !== field.value
    ) {
      helpers.setValue(newValue);
    } else if (!moment(newValue, "YYYY-MM-DD", true).isValid() && field.value) {
      helpers.setValue("");
    }
  };

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="d-flex flex-column mb-7 fv-row">
        <div className="mb-2">
          <label className="form-label fw-bold d-flex flex-column flex-lg-row align-items-lg-center fs-3">
            {/* Label text */}
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>

            {/* Button (tooltips, icons, etc.) */}
            <div className="ms-0 ms-lg-2 mt-2 mt-lg-0">
              {props.tooltip?.button && props.tooltip.button}
            </div>
          </label>
          {props.tooltip?.notice && props.tooltip.notice}
          {props.description != "" && (
            <div className="form-text">{props.description}</div>
          )}
        </div>{" "}
        <div className="row fv-row">
          <div className="col-7 ps-0 pe-1">
            <MonthPicker
              endYearGiven
              defaultValue={
                thisDate.month >= 0 && thisDate.month < 12
                  ? moment().month(thisDate.month).format("MMMM")
                  : "Month"
              }
              year={thisDate.year}
              value={thisDate.month}
              onChange={(month: number) => {
                const newDate = { ...thisDate, month };
                setDate(newDate);
                updateFormikFieldValue(newDate);
              }}
              classes={
                "form-control form-control-solid form-select form-select-solid fs-5 arrowless-select"
              }
            />
          </div>
          <div className="col-5 ps-0 pe-1">
            <YearPicker
              reverse={true}
              defaultValue={
                thisDate.year >= props.startYear &&
                props.startYear > 1900 &&
                thisDate.year <= props.endYear &&
                props.endYear > 1900
                  ? moment().year(thisDate.year).format("YYYY")
                  : "Year"
              }
              start={props.startYear > 1900 ? props.startYear : 1900}
              end={props.endYear > 1900 ? props.endYear : 0}
              required={props.required} // default is false
              value={thisDate.year} // mandatory
              onChange={(year: number) => {
                const newDate = { ...thisDate, year };
                setDate(newDate);
                updateFormikFieldValue(newDate);
              }}
              id={"year"}
              name={"year"}
              classes={
                "form-control form-control-solid form-select form-select-solid fs-5 arrowless-select"
              }
              optionClasses={""}
            />
          </div>

          <div className="text-danger mt-2">
            <ErrorMessage name={props.name} />
          </div>
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  name: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  prefix: ReactNode;
  startYear: number;
  endYear: number;
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
