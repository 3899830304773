import * as Yup from "yup";
import { medicalConditionSchema } from "./MedicalConditionSchema";
import { medicationSchema } from "./MedicationSchema";
import { groupedOptions as groupedMedicalConditions } from "../../../../models/MedicalConditionsData";
import { groupedOptions as groupedMedications } from "../../../../models/MedicationData";
import { fieldValidation } from "../../Validators";
import { MedicalConditionSharedInfoSchema } from "./MedicalConditionSharedInfoSchema";
import { ExtractLabelFromGroupedOptionsBasedOnValue } from "../../Helpers";
import { Notice } from "../../../common/cards/Notice";

function getMedicationNameFromID(item: any, index: number): string {
  const medicationID = item.medicationName;
  const label = ExtractLabelFromGroupedOptionsBasedOnValue(
    medicationID,
    groupedMedications
  );
  return label || `Medication ${index + 1}`;
}

function getConditionTypeFromID(item: any, index: number): string {
  // Array of available condition options.
  const conditionOptions = [
    { label: "", value: "0" },
    { label: "High Blood Pressure", value: "218" },
    { label: "High Cholesterol", value: "219" },
    { label: "Diabetes", value: "211" },
    { label: "Cancer", value: "212" },
    { label: "Other", value: "208" },
    { label: "Heart", value: "210" },
    { label: "Respiratory", value: "214" },
    { label: "Neurological", value: "216" },
    { label: "Stroke", value: "213" },
    { label: "Multiple Sclerosis", value: "215" },
  ];

  // Extract the conditionName from the item.
  const conditionID = item.conditionType;

  // Look for a matching condition option.
  const matchingOption = conditionOptions.find(
    (option) => option.value === conditionID
  );

  // If a matching option is found and has a non-empty label, return that label.
  if (matchingOption && matchingOption.label) {
    return matchingOption.label;
  }

  // Fallback: return a default title if no match is found.
  return `Condition ${index + 1}`;
}

function medicationForConditions(formData: any, isPerson2: boolean) {
  let values: { label: string; value: string }[] = [{ label: "", value: "0" }];
  const conditions = isPerson2
    ? formData?.person2MedicalInformation?.medicalConditionList
    : formData?.medicalInformation?.medicalConditionList;

  if (conditions !== undefined && conditions.length > 0) {
    conditions.forEach((condition: any) => {
      let itemToAdd;
      switch (condition.conditionType) {
        case "208":
          itemToAdd = { label: "Other", value: "4163" };
          break;
        case "210":
          itemToAdd = { label: "Heart", value: "4165" };
          break;
        case "211":
          itemToAdd = { label: "Diabetes", value: "4167" };
          break;
        case "212":
          itemToAdd = { label: "Cancer/Tumour", value: "4161" };
          break;
        case "213":
          itemToAdd = { label: "Stroke", value: "4172" };
          break;
        case "214":
          itemToAdd = { label: "Respiratory", value: "4169" };
          break;
        case "215":
          itemToAdd = { label: "Multiple Sclerosis", value: "5983" };
          break;
        case "216":
          itemToAdd = { label: "Neurological", value: "4170" };
          break;
        case "218":
          itemToAdd = { label: "High Blood Pressure", value: "5873" };
          break;
        case "219":
          itemToAdd = { label: "High Cholesterol", value: "5874" };
          break;
      }
      if (itemToAdd) {
        values.push(itemToAdd);
      }
    });
  }

  return values;
}

export const getMedicalInfoSchema = (isPerson2: boolean) => {
  const prefix = isPerson2 ? "person2MedicalInformation" : "medicalInformation";

  return Yup.object().shape({
    [prefix]: Yup.object().shape({
      lifestyleAndMedicalDeclaration: Yup.string()
        .label(
          `Do ${
            isPerson2 ? "they" : "you"
          } consent to share medical and lifestyle information with providers in order to generate quotes?`
        )
        .required(
          "Please Accept or Decline the Lifestyle and Medical Declaration."
        )
        .meta({
          control: {
            componentType: "YesNoBoxes",
            props: {
              required: true,
              label: `Do ${
                isPerson2 ? "they" : "you"
              } consent to share medical and lifestyle information with providers in order to generate quotes?`,
              description: (
                <Notice
                  noticeClasses={
                    "bg-light-warning border-2 rounded border-dark mt-9 mb-9 p-10"
                  }
                  icon={undefined}
                  iconClasses={null}
                  header={<div className="fs-4">Please note:</div>}
                  content={
                    "If you do not consent to share your medical and lifestyle information, the quotes you receive will not be tailored and could result in a lower amount."
                  }
                  button={null}
                />
              ),
              name: `${prefix}.lifestyleAndMedicalDeclaration`,
              tooltip: "",
              addSeparatorAbove: false,
            },
          },
        }),

      heightFeet: Yup.number()
        .label(isPerson2 ? "Their Height" : "Your Height")
        .required("Please tell us your height in feet.")
        .test(
          "is-valid-heightFeet",
          "Please enter a value between 2 and 10.",
          (value) => fieldValidation(value, "integer", 2, 8, true)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: `What is ${isPerson2 ? "their" : "your"} height?`,
              description: "",
              name: `${prefix}.heightFeet`,
              required: true,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: <div className="small">Feet:</div>,
              className: "col-md-6 col-sm-12",
            },
          },
        }),

      heightInches: Yup.number()
        .label(isPerson2 ? "Their Height" : "Your Height")
        .required("Please tell us your height in inches.")
        .test(
          "is-valid-heightInches",
          "Please enter a value between 0 and 11.",
          (value) => fieldValidation(value, "integer", 0, 11, true)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: "",
              description: "",
              name: `${prefix}.heightInches`,
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: <div className="small">Inches:</div>,
              className: "col-md-6 col-sm-12",
            },
          },
        }),

      weightStone: Yup.number()
        .label(isPerson2 ? "Their Weight" : "Your Weight")
        .required("Please tell us your weight in stone.")
        .test(
          "is-valid-weightStone",
          "Please enter a value between 5 and 50.",
          (value) => fieldValidation(value, "integer", 5, 50, true)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: `What is ${isPerson2 ? "their" : "your"} weight?`,
              description: "",
              name: `${prefix}.weightStone`,
              required: true,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: <div className="small">Stone:</div>,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
          },
        }),

      weightPounds: Yup.number()
        .label(isPerson2 ? "Their Weight" : "Your Weight")
        .required("Please tell us your weight in pounds.")
        .test(
          "is-valid-weightPounds",
          "Please enter a value between 0 and 13.",
          (value) => fieldValidation(value, "integer", 0, 13, true)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: "",
              description: "",
              name: `${prefix}.weightPounds`,
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: <div className="small">Pounds:</div>,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
          },
        }),

      waistMeasurement: Yup.number()
        .label(isPerson2 ? "Their Waist Measurement" : "Your Waist Measurement")
        .required("Please tell us your waist measurement in inches.")
        .test(
          "is-valid-waist",
          "Please enter a value between 10 and 75.",
          (value) => fieldValidation(value, "integer", 10, 75, true)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: `What is ${
                isPerson2 ? "their" : "your"
              } waist measurement?`,
              description: "",
              name: `${prefix}.waistMeasurement`,
              required: true,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: <div className="small">Inches:</div>,
              addSeparatorBelow: false,
            },
          },
        }),

      // Smoking
      previousOrCurrentSmokerYesNo: Yup.string()
        .label(`Have ${isPerson2 ? "they" : "you"} ever been a smoker?`)
        .nullable()
        .meta({
          control: {
            componentType: "YesNoBoxes",
            props: {
              label: `Have ${isPerson2 ? "they" : "you"} ever been a smoker?`,
              description: `If ${
                isPerson2 ? "they" : "you"
              } are a smoker or have been previously, this can increase the amount you receive.`,
              name: `${prefix}.previousOrCurrentSmokerYesNo`,
              tooltip: "",
              addSeparatorAbove: true,
            },
          },
        }),

      previousSmoker: Yup.string()
        .label(`Have ${isPerson2 ? "they" : "you"} now stopped smoking?`)
        .nullable()
        .meta({
          control: {
            componentType: "YesNoBoxes",
            props: {
              label: `Have ${isPerson2 ? "they" : "you"} now stopped smoking?`,
              description: "",
              name: `${prefix}.previousSmoker`,
              tooltip: "",
              addSeparatorAbove: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      regularSmoker: Yup.string()
        .label(
          `Have ${
            isPerson2 ? "they" : "you"
          } been a regular smoker over the last 10 years?`
        )
        .nullable()
        .meta({
          control: {
            componentType: "YesNoBoxes",
            props: {
              label: `Have ${
                isPerson2 ? "they" : "you"
              } been a regular smoker over the last 10 years?`,
              description: "",
              name: `${prefix}.regularSmoker`,
              tooltip: "",
              addSeparatorAbove: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      yearMonthStartedSmoking: Yup.string()
        .label(`When did ${isPerson2 ? "they" : "you"} start smoking?`)
        .nullable()
        .when("previousOrCurrentSmokerYesNo", {
          is: (val: string) => val === "true",
          then: Yup.string()
            .required(
              `Please provide the month and year ${
                isPerson2 ? "they" : "you"
              } started smoking.`
            )
            .notOneOf(["0", "", "month", "year"], "Please select an option"),
        })
        .meta({
          control: {
            componentType: "DateMMYYYY",
            props: {
              name: `${prefix}.yearMonthStartedSmoking`,
              label: `When did ${isPerson2 ? "they" : "you"} start smoking?`,
              required: false,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      yearMonthStoppedSmoking: Yup.string()
        .label(`When did ${isPerson2 ? "they" : "you"} stop smoking?`)
        .nullable()
        .when("previousSmoker", {
          is: (val: string) => val === "true",
          then: Yup.string()
            .required(
              `Please provide the month and year ${
                isPerson2 ? "they" : "you"
              } stopped smoking.`
            )
            .notOneOf(["0", "", "month", "year"], "Please select an option"),
        })
        .meta({
          control: {
            componentType: "DateMMYYYY",
            props: {
              name: `${prefix}.yearMonthStoppedSmoking`,
              label: `When did ${isPerson2 ? "they" : "you"} stop smoking?`,
              required: false,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: false,
            },
            dependsOnConditional: "and",
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
              {
                fieldName: `${prefix}.previousSmoker`,
                fieldValue: "true",
              },
            ],
          },
        }),

      cigarettesPerDay: Yup.string()
        .label("Cigarettes Per Day")
        .nullable()
        .test(
          "is-valid-numberOfCigarettes",
          "Please enter the number of cigarettes you smoke per day.",
          (value) => fieldValidation(value, "integer", 0, 200, false)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: "Cigarettes Per Day",
              description: "",
              name: `${prefix}.cigarettesPerDay`,
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: undefined,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      cigarsPerDay: Yup.string()
        .label("Cigars Per Day")
        .nullable()
        .test(
          "is-valid-numberOfCigars",
          "Please enter the number of cigars you smoke per day.",
          (value) => fieldValidation(value, "integer", 0, 50, false)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: "Cigars Per Day",
              description: "",
              name: `${prefix}.cigarsPerDay`,
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: undefined,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      gramsOfTobaccoPerDay: Yup.string()
        .label("Grams Of Tobacco Per Day")
        .nullable()
        .test(
          "is-valid-gramsOfTobacco",
          "Please enter the amount of tobacco you smoke per day (in grams).",
          (value) => fieldValidation(value, "integer", 0, 1000, false)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: "Grams Of Tobacco Per Day",
              description: "",
              name: `${prefix}.gramsOfTobaccoPerDay`,
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: undefined,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      gramsOfPipeTobaccoPerDay: Yup.string()
        .label("Grams Of Pipe Tobacco Per Day")
        .nullable()
        .test(
          "is-valid-amountOfPipeTobacco",
          "Please enter the amount of tobacco you smoke per day (in grams).",
          (value) => fieldValidation(value, "integer", 0, 1000, false)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: "Grams Of Pipe Tobacco Per Day",
              description: "",
              name: `${prefix}.gramsOfPipeTobaccoPerDay`,
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: undefined,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.previousOrCurrentSmokerYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      alcoholUnitsPerWeek: Yup.string()
        .label(`Do ${isPerson2 ? "they" : "you"} drink alcohol?`)
        .nullable()
        .test(
          "is-valid-alcoholUnitsPerWeek",
          "Please enter the amount of alcohol you drink.",
          (value) => fieldValidation(value, "integer", 0, 50, false)
        )
        .meta({
          control: {
            componentType: "InputBox",
            props: {
              label: `How many units of alcohol do ${
                isPerson2 ? "they" : "you"
              } drink per week?`,
              description:
                "1 pint of beer is 3 units, a single shot of spirit is 1 unit and a glass of wine is 2 units.",
              name: `${prefix}.alcoholUnitsPerWeek`,
              tooltip: "",
              inputType: "number",
              addSeparatorAbove: true,
            },
          },
        }),

      // Medical Conditions
      medicalConditionsYesNo: Yup.string()
        .label(
          `Do ${isPerson2 ? "they" : "you"}, or have ${
            isPerson2 ? "they" : "you"
          } ever had any medical conditions?`
        )
        .nullable()
        .meta({
          control: {
            componentType: "YesNoBoxes",
            props: {
              label: `Do ${isPerson2 ? "they" : "you"}, or have ${
                isPerson2 ? "they" : "you"
              } ever had any medical conditions?`,
              description:
                "Medical conditions can dramatically increase the amount of money you could receive.",
              name: `${prefix}.medicalConditionsYesNo`,
              tooltip: "",
              addSeparatorAbove: true,
            },
          },
        }),

      medicalConditionList: Yup.array()
        .of(medicalConditionSchema)
        .label("Medical Conditions")
        .nullable()
        .default([])
        .when("medicalConditionsYesNo", {
          is: (val: string) => val === "true",
          then: Yup.array()
            .of(medicalConditionSchema)
            .required("Please provide details of the medical conditions.")
            .min(1, "Please add at least one condition."),
        })
        .meta({
          itemTypeName: "Medical Condition",
          fieldArrayName: `${prefix}.medicalConditionList`,
          fieldArrayDescription: `If ${
            isPerson2 ? "they" : "you"
          } have any medical conditions, these can potentially increase the amount you recieve. Use the below to add them.`,
          addSeparatorAbove: false,
          getItemTitle: getConditionTypeFromID,
          control: [
            {
              componentType: "Lookup",
              props: {
                name: "conditionType",
                label: "Condition Type",
                required: true,
                description: "",
                placeholder: "Please Select",
                tooltip: "",
                prefix: undefined,
                values: [
                  { label: "", value: "0" },
                  { label: "High Blood Pressure", value: "218" },
                  { label: "High Cholesterol", value: "219" },
                  { label: "Diabetes", value: "211" },
                  { label: "Cancer", value: "212" },
                  { label: "Other", value: "208" },
                  { label: "Heart", value: "210" },
                  { label: "Respiratory", value: "214" },
                  { label: "Neurological", value: "216" },
                  { label: "Stroke", value: "213" },
                  { label: "Multiple Sclerosis", value: "215" },
                ],
              },
            },

            {
              componentType: "Lookup",
              props: {
                name: "subTypeID",
                label: "Sub Type",
                required: true,
                description: "",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                values: [
                  { label: "", value: "0" },
                  { label: "Primary Progressive", value: "5865" },
                  { label: "Progressive Relapsing", value: "5866" },
                  { label: "Relapsing Remitting", value: "5863" },
                  { label: "Secondary Progressive", value: "5864" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["215"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                label: "What type of neurological condition is it?",
                description: "",
                name: "neurologicalType",
                tooltip: "",
                required: true,
                values: [
                  { label: "", value: "0" },
                  { label: "Alzheimer's Disease", value: "6016" },
                  { label: "Parkinson's Disease", value: "6017" },
                  { label: "Other", value: "6019" },
                  { label: "Senile Dementia", value: "100995" },
                  { label: "Vascular Dementia", value: "100996" },
                  { label: "Motor Neurone Disease", value: "100997" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                label: `What type of diabetes do ${
                  isPerson2 ? "they" : "you"
                } have?`,
                description: "",
                name: "diabetesType",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Type 1", value: "5820" },
                  { label: "Type 2", value: "5821" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["211"],
                },
              ],
            },

            //Respiratory
            {
              componentType: "Lookup",
              props: {
                label: "What type of respiratory condition is it?",
                description: "",
                name: "respiratoryType",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Chronic Obstructive Airways", value: "6020" },
                  { label: "Pulmonary Disease", value: "6021" },
                  { label: "Emphysema", value: "6022" },
                  { label: "Bronchiectasis", value: "6023" },
                  { label: "Pneumoconiosis", value: "6024" },
                  { label: "Asbestosis", value: "6025" },
                  { label: "Asthma", value: "6026" },
                  { label: "Pleural Plaques", value: "6027" },
                  { label: "Other", value: "6029" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["214"],
                },
              ],
            },

            // Heart
            {
              componentType: "Lookup",
              props: {
                name: "heartConditionType",
                label: `What type of heart condition do ${
                  isPerson2 ? "they" : "you"
                } have?`,
                required: false,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Heart Attack", value: "5997" },
                  { label: "Angina", value: "5998" },
                  { label: "Heart Failure", value: "5999" },
                  { label: "Aortic Aneurysm", value: "6000" },
                  { label: "Cardiomyopathy", value: "6001" },
                  { label: "Heart Valve Disorders", value: "6002" },
                  { label: "Atrial Fibrillation (AF)", value: "6003" },
                  { label: "Other", value: "6004" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["210"],
                },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "noOfOccurrences",
                required: true,
                label: "Number of Occurrences",
                inputType: "number",
                formatType: "integer",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["210"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                name: "conditionOngoing",
                label: "Is the condition ongoing?",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["210"],
                },
              ],
            },

            //shared
            {
              componentType: "LookupWithSearchAndGroups",
              props: {
                name: "conditionName",
                label: "What is the medical name for the condition?",
                required: true,
                description: "Search and select a condition",
                placeholder: "Please Select",
                tooltip: "",
                prefix: undefined,
                values: groupedMedicalConditions,
                groupFilterField: "conditionType",
                allowFreeType: true,
                unknownEntryValue: "Unknown",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208", "210", "212", "214", "216"],
                },
                {
                  fieldName: "heartConditionType",
                  fieldValue: [null, "6004", "0", ""],
                },
                {
                  fieldName: "respiratoryType",
                  fieldValue: [null, "6029", "0", ""],
                },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "customerProvidedMedicalConditionName",
                label: "Please free type the name of the condition.",
                required: true,
                description:
                  "Unfortunately the condition you have entered is not on the supported list for automatic quotes. Please tell us the name of the condition as we may be able to discuss this with your chosen provider later.",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionName",
                  fieldValue: "Unknown",
                },
              ],
            },
            {
              componentType: "DateMMYYYY",
              props: {
                name: "dateDiagnosed",
                label: `When were ${isPerson2 ? "they" : "you"} diagnosed?`,
                required: true,
                description: "A rough estimate will be fine.",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
                addSeparatorBelow: false,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: [
                    "210",
                    "208",
                    "212",
                    "214",
                    "216",
                    "213",
                    "218",
                    "219",
                    "211",
                    "215",
                  ],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "lastTreatmentDate",
                label: `When was the last time ${
                  isPerson2 ? "they" : "you"
                } received treatment for this condition?`,
                required: true,
                description: "",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
                addSeparatorBelow: false,
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                name: "discharged",
                label: `Have ${isPerson2 ? "they" : "you"} been discharged?`,
                required: true,
                description: "",
                tooltip: "",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212", "213"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "lastConsultationDate",
                label: `When was ${
                  isPerson2 ? "their" : "your"
                } last consultation?`,
                required: true,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212", "210"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } had any surgery for this condition?`,
                description: "",
                name: "surgery",
                tooltip: "",
                addSeparatorAbove: false,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208", "212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "surgeryDate",
                label: "Surgery Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "surgery",
                  fieldValue: "true",
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "InputBox",
              props: {
                name: "typeOfSurgery",
                label: "What Type of Surgery?",
                required: false,
                description: "",
                tooltip: "",
                placeholder: "",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "surgery",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            // High cholestrol
            {
              componentType: "InputBox",
              props: {
                name: "readingMmol",
                label: `What was ${
                  isPerson2 ? "their" : "your"
                } last reading (mmol)?`,
                required: false,
                description: "",
                placeholder: "",
                inputType: "text",
                tooltip: "",
                prefix: null,
                addSeparatorBelow: false,
                formatType: "decimal",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["219"],
                },
              ],
            },

            // High Blood Pressure
            {
              componentType: "InputBox",
              props: {
                name: "lastSystolicReading",
                label: `What was ${
                  isPerson2 ? "their" : "your"
                } last Systolic Reading?`,
                required: true,
                description: "This is the higher reading",
                placeholder: "",
                inputType: "number",
                tooltip: "",
                prefix: null,
                addSeparatorBelow: false,
                formatType: "integer",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["218"],
                },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "lastDiastolicReading",
                label: `What was ${
                  isPerson2 ? "their" : "your"
                } last Diastolic Reading?`,
                required: true,
                description: "This is the lower reading",
                placeholder: "",
                inputType: "number",
                tooltip: "",
                prefix: null,
                addSeparatorBelow: false,
                formatType: "integer",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["218"],
                },
              ],
            },

            // Other
            {
              componentType: "DateMMYYYY",
              props: {
                name: "lastSufferedSymptoms",
                label: `When did ${
                  isPerson2 ? "they" : "you"
                } last suffer symptoms?`,
                required: true,
                description: "A rough estimate will be fine.",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
                addSeparatorBelow: false,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "lastHospitalised",
                label: `If ${
                  isPerson2 ? "they" : "you"
                } have been hospitalised because of this condition, when was the last time?`,
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
                addSeparatorBelow: false,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208", "210", "211", "214", "215"],
                },
              ],
            },

            {
              componentType: "InputBox",
              props: {
                name: "numberOfHospitalAdmissions",
                label: `How many times in total have ${
                  isPerson2 ? "they" : "you"
                } been hospitalised due to this condition?`,
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                addSeparatorBelow: false,
                formatType: "integer",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208", "210", "214", "215"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } received Renal Dialysis for this condition?`,
                description: "",
                name: "renalDialysis",
                tooltip: "",
                addSeparatorAbove: false,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } had any other treatments for this condition?`,
                description: "",
                name: "otherTreatment",
                tooltip: "",
                addSeparatorAbove: false,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["208"],
                },
              ],
            },

            // Diabetus
            {
              componentType: "Lookup",
              props: {
                label: `How do ${
                  isPerson2 ? "they" : "you"
                } control your diabetes?`,
                description: "",
                name: "diabetesControlMethod",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Diet", value: "5822" },
                  { label: "Insulin", value: "5823" },
                  { label: "Tablet", value: "5824" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["211"],
                },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "diabetesNoOfTests",
                label: "How many blood glucose tests do you do?",
                required: false,
                description: "Number of Tests",
                formatType: "integer",
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "Lookup",
              props: {
                name: "diabetesTestingFrequencyID",
                label: "How often do you do these tests?",
                required: false,
                values: [
                  { label: "", value: "" },
                  { label: "As Required", value: "5833" },
                  { label: "Daily", value: "5984" },
                  { label: "Weekly", value: "5985" },
                  { label: "Fortnightly", value: "5986" },
                  { label: "Every Four Weeks", value: "5987" },
                  { label: "Monthly", value: "5988" },
                  { label: "Quarterly", value: "5989" },
                  { label: "Half Yearly", value: "5990" },
                  { label: "Yearly", value: "5991" },
                ],
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "readingHbA1c",
                label:
                  "Do you know the Last HbA1c Reading? If so, what is the Reading?",
                description:
                  "Please take care when entering your HbA1c reading. Ensure that you select the correct units as both scales can be used. Exmaple: 6.5% = 47.5mmol/mol. Values should be around 24 maximum for DCCT% reading. If you have a higher reading than this, check whether you should select the IFCC scale (mmol/mol).",
                required: false,
                formatType: "decimal",
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "Lookup",
              props: {
                name: "hbA1cUnitID",
                label: "What unit is your HbA1c measured in?",
                required: false,
                values: [
                  { label: "", value: "" },
                  { label: "DCCT (%)", value: "100642" },
                  { label: "IFCC (mmol/mol)", value: "100643" },
                ],
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "DateMMYYYY",
              props: {
                name: "lastReading",
                label: "When were these readings taken?",
                required: false,
                description: "A rough estimate will be fine.",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
                addSeparatorBelow: true,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["218", "219", "211"],
                },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "penultimateReadingKnown",
                label: "Do you know your reading prior to the last one?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "penultimateReadingHbA1c",
                label: "What was the reading prior to the last one?",
                required: false,
                formatType: "decimal",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
                { fieldName: "PenultimateReadingKnown", fieldValue: ["true"] },
              ],
            },
            {
              componentType: "DateMMYYYY",
              props: {
                name: "penultimateReading",
                label: "When was the reading prior to the last one?",
                required: false,
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
                { fieldName: "PenultimateReadingKnown", fieldValue: ["true"] },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "heartDisease",
                label: "Have you suffered from Heart Disease?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "eyeDisease",
                label:
                  "Have you suffered from Retinopathy (Excluding other eye disease)?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "kidneyDisease",
                label:
                  "Have you suffered from Kidney Disease (Protein in urine)?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "amputation",
                label: "Have you had an amputation?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              name: "circulation",
              componentType: "YesNoBoxes",
              props: {
                name: "circulation",
                label: "Have you suffered From Circulation issues?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "neuropathy",
                label: "Have you suffered from Neuropathy?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                name: "ulceration",
                label:
                  "Have you suffered from any Ulceration (Peripheral vascular disease)?",
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["211"] },
              ],
            },

            // Cancer
            {
              componentType: "Lookup",
              props: {
                label: `What type of tumour did/do ${
                  isPerson2 ? "they" : "you"
                } have?`,
                description: "",
                name: "tumourType",
                tooltip: "",
                required: true,
                values: [
                  { label: "", value: "0" },
                  { label: "Benign", value: "5860" },
                  { label: "Pre-Cancerous", value: "5861" },
                  { label: "Malignant", value: "5862" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },
            {
              componentType: "Lookup",
              props: {
                label: `Where did/do ${
                  isPerson2 ? "they" : "you"
                } have the tumour?`,
                description: "",
                name: "tumourLocation",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Bone", value: "5939" },
                  { label: "Brain and Central Nervous System", value: "5940" },
                  { label: "Breast", value: "5941" },
                  { label: "Circulatory system", value: "5942" },
                  { label: "Connective and other soft tissue", value: "5943" },
                  { label: "Digestive System", value: "5944" },
                  { label: "Liver", value: "5945" },
                  { label: "Lymph Nodes", value: "5946" },
                  { label: "Oral", value: "5947" },
                  { label: "Reproductive system", value: "5948" },
                  { label: "Respiratory system", value: "5949" },
                  { label: "Skin", value: "5950" },
                  { label: "Urinary system", value: "5951" },
                  { label: "Other", value: "5952" },
                  { label: "Not specified", value: "5953" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                name: "radiotherapy",
                label: `Have ${isPerson2 ? "they" : "you"} had Radiotherapy?`,
                required: false,
                description: "",
                tooltip: "",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "radiotherapyStartDate",
                label: "Radiotherapy Start Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "radiotherapy",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "radiotherapyEndDate",
                label: "Radiotherapy End Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "radiotherapy",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "CheckBox",
              props: {
                name: "chemotherapy",
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } had Chemotherapy for this condition?`,
                required: false,
                description: "",
                tooltip: "",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "chemotherapyStartDate",
                label: "Chemotherapy Start Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "chemotherapy",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "chemotherapyEndDate",
                label: "Chemotherapy End Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "chemotherapy",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                name: "boneMarrow",
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } had Bone Marrow or Stem Cell treatment for this condition?`,
                required: false,
                description: "",
                tooltip: "",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "boneMarrowStartDate",
                label: "Bone Marrow Start Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "boneMarrow",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "boneMarrowEndDate",
                label: "Bone Marrow End Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "boneMarrow",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                name: "hormoneTherapy",
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } had Hormone Therapy for this condtion?`,
                required: false,
                description: "",
                tooltip: "",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "hormoneTherapyStartDate",
                label: "Hormone Therapy Start Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "hormoneTherapy",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "hormoneTherapyEndDate",
                label: "Hormone Therapy End Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "hormoneTherapy",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "InputBox",
              props: {
                name: "numberOfNodesAffected",
                label: "How many nodes were/are affected?",
                required: false,
                description: "",
                tooltip: "",
                placeholder: "Enter number",
                formatType: "integer",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "CheckboxBoxes",
              props: {
                name: "nodeLocationsID",
                label: "Node Locations",
                required: false,
                description: "",
                tooltip: "",

                items: [
                  { label: "Head, face and neck", value: "6030" },
                  { label: "Chest", value: "6031" },
                  { label: "Abdomen", value: "6032" },
                  { label: "Armpit or arm", value: "6033" },
                  { label: "Groin or leg", value: "6034" },
                  { label: "Pelvic", value: "6035" },
                  { label: "Other", value: "6036" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "YesNoBoxes",
              props: {
                name: "anyRecurrences",
                label: `If this is not an on-going condition, have ${
                  isPerson2 ? "they" : "you"
                } had any recurrences?`,
                required: true,
                description: "",
                tooltip: "",
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "recurrenceDate",
                label: "Recurrence Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "anyRecurrences",
                  fieldValue: ["true"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },
            {
              componentType: "Lookup",
              props: {
                name: "generalGradeID",
                label: "General Grade",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Grade 1 (Low Grade)", value: "5954" },
                  { label: "Grade 2 (Intermediate Grade)", value: "5955" },
                  { label: "Grade 3 (High Grade)", value: "5956" },
                  { label: "Unknown", value: "5966" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "spreadTypeID",
                label: "Nature Of Tumour",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  {
                    label: "Carcinoma In Situ (Stage 0, Tis, Ta)",
                    value: "5967",
                  },
                  { label: "Only Local Tumour Growth", value: "5968" },
                  {
                    label: "Tumour Invaded Adjacent Lymph Nodes",
                    value: "5969",
                  },
                  {
                    label: "Tumour Invaded Distant Lymph Nodes",
                    value: "5970",
                  },
                  {
                    label:
                      "Tumour Spread To Distant Organs (Distant Metastases)",
                    value: "5971",
                  },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "CheckboxBoxes",
              props: {
                name: "spreadToDistantOrgansCheckBoxID",
                label: "Spread to Distant Organs",
                required: false,
                description: "",
                tooltip: "",
                items: [
                  { label: "Brain and Central Nervous system", value: "5972" },
                  { label: "Reproductive", value: "5973" },
                  { label: "Urinary", value: "5974" },
                  { label: "Respiratory", value: "5975" },
                  { label: "Skin", value: "5976" },
                  { label: "Digestive", value: "5977" },
                  { label: "Liver", value: "5978" },
                  { label: "Heart", value: "5979" },
                  { label: "Other", value: "5980" },
                  { label: "Current Level", value: "5981" },
                  { label: "Pre-Treatment Level", value: "5982" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "spreadType",
                  fieldValue: ["5971"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "stageTypeID",
                label: "Classification Type",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Dukes", value: "5957" },
                  { label: "TNM", value: "5958" },
                  { label: "MAC", value: "5959" },
                  { label: "FIGO", value: "5960" },
                  { label: "Clark Level", value: "5961" },
                  { label: "Breslow Thickness", value: "5962" },
                  { label: "Ann Arbor", value: "5963" },
                  { label: "General", value: "5964" },
                  { label: "Not Specified", value: "5965" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "generalStageTypeID",
                label: "General Stage Type",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Stage 0", value: "7421" },
                  { label: "Stage 1", value: "7422" },
                  { label: "Stage 2", value: "7423" },
                  { label: "Stage 3", value: "7424" },
                  { label: "Stage 4", value: "7425" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5964"], // General
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "generalSubStageTypeID",
                label: "General Sub-Stage Type",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "A", value: "7426" },
                  { label: "B", value: "7427" },
                  { label: "C", value: "7428" },
                  { label: "D", value: "7429" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5964"], // General
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "annArborClassificationID",
                label: "Ann Arbor Classification",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "1", value: "7498" },
                  { label: "2", value: "7499" },
                  { label: "3", value: "7500" },
                  { label: "4", value: "7501" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5963"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "breslowThicknessID",
                label: "Breslow Thickness",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "1", value: "7488" },
                  { label: "2", value: "7489" },
                  { label: "3", value: "7490" },
                  { label: "4", value: "7491" },
                  { label: "5", value: "7492" },
                  { label: "6", value: "7493" },
                  { label: "7", value: "7494" },
                  { label: "8", value: "7495" },
                  { label: "9", value: "7496" },
                  { label: "10", value: "7497" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5962"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "clarkLevelID",
                label: "Clark Level",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "1", value: "7483" },
                  { label: "2", value: "7484" },
                  { label: "3", value: "7485" },
                  { label: "4", value: "7486" },
                  { label: "5", value: "7487" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5961"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "dukesClassificationID",
                label: "Dukes Classification",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "A", value: "7441" },
                  { label: "B", value: "7442" },
                  { label: "C", value: "7443" },
                  { label: "D", value: "7444" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5957"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "fIGOClassificationID",
                label: "FIGO Classification",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "1", value: "7479" },
                  { label: "2", value: "7480" },
                  { label: "3", value: "7481" },
                  { label: "4", value: "7482" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5960"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "mACClassificationID",
                label: "MAC Classification",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "A1", value: "7467" },
                  { label: "A2", value: "7468" },
                  { label: "A3", value: "7469" },
                  { label: "B1", value: "7470" },
                  { label: "B2", value: "7471" },
                  { label: "B3", value: "7472" },
                  { label: "C1", value: "7473" },
                  { label: "C2", value: "7474" },
                  { label: "C3", value: "7475" },
                  { label: "D1", value: "7476" },
                  { label: "D2", value: "7477" },
                  { label: "D3", value: "7478" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5959"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "tNMTStageTypeID",
                label: "TNM T Stage",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Ta", value: "7445" },
                  { label: "Tis", value: "7446" },
                  { label: "T0", value: "7447" },
                  { label: "T1", value: "7448" },
                  { label: "T2", value: "7449" },
                  { label: "T3", value: "7450" },
                  { label: "T4", value: "7451" },
                  { label: "TX", value: "7452" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5958"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "tNMTStageSubTypeID",
                label: "TNM T Stage Sub-Type",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "a", value: "7453" },
                  { label: "b", value: "7454" },
                  { label: "c", value: "7455" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5958"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "tNMNStageTypeID",
                label: "TNM N Stage",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "N0", value: "7456" },
                  { label: "N1", value: "7457" },
                  { label: "N2", value: "7458" },
                  { label: "N3", value: "7459" },
                  { label: "NX", value: "7460" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5958"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "tNMNStageSubTypeID",
                label: "TNM N Stage Sub-Type",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "a", value: "7461" },
                  { label: "b", value: "7462" },
                  { label: "c", value: "7463" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5958"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "tNMMStageTypeID",
                label: "TNM M Stage",
                required: true,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "M0", value: "7464" },
                  { label: "M1", value: "7465" },
                  { label: "MX", value: "7466" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "stageTypeID",
                  fieldValue: ["5958"],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Lookup",
              props: {
                name: "pSALevelID",
                label: "PSA Level",
                required: false,
                description: "",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Current Level", value: "5981" },
                  { label: "Pre-Treatment Level", value: "5982" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionName",
                  fieldValue: [
                    "Malignant Neoplasm Of Prostate",
                    "Malignant Neoplasm Prostate",
                    "Prostate Calculus",
                    "Prostate Cancer",
                    "Prostate Enlarged",
                    "Prostate Hyperplasia",
                    "Prostate Hypertrophy",
                    "Prostate Neoplasm",
                    "Prostate Neoplasm Malignant",
                    "Prostate Neoplasm Unknown",
                    "Inflammatory Prostate Disease",
                    "Hyperplasia Of Prostate",
                    "Enlarged Prostate",
                    "Enlarge Prostate",
                    "Carcinoma Prostate",
                    "Carcinoma Of Prostate",
                    "Carcinoma In Situ Of Prostate",
                    "Cancer Of Prostate",
                    "Ca Prostate",
                    "Benign Tumor Of Prostate",
                    "Benign Prostate Tumour",
                    "Benign Neoplasm Of Prostate",
                    "Adenocarcinoma Prostate",
                    "Adeno Carcinoma Prostate",
                    "Adeno CA Prostate",
                    "Prostate Tuberculosis",
                  ],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "InputBox",
              props: {
                name: "pSALevelResult",
                label: "PSA Level Result",
                type: "number",
                required: false,
                description: "",
                tooltip: "",
                step: "0.01",
                formatType: "decimal",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionName",
                  fieldValue: [
                    "Malignant Neoplasm Of Prostate",
                    "Malignant Neoplasm Prostate",
                    "Prostate Calculus",
                    "Prostate Cancer",
                    "Prostate Enlarged",
                    "Prostate Hyperplasia",
                    "Prostate Hypertrophy",
                    "Prostate Neoplasm",
                    "Prostate Neoplasm Malignant",
                    "Prostate Neoplasm Unknown",
                    "Inflammatory Prostate Disease",
                    "Hyperplasia Of Prostate",
                    "Enlarged Prostate",
                    "Enlarge Prostate",
                    "Carcinoma Prostate",
                    "Carcinoma Of Prostate",
                    "Carcinoma In Situ Of Prostate",
                    "Cancer Of Prostate",
                    "Ca Prostate",
                    "Benign Tumor Of Prostate",
                    "Benign Prostate Tumour",
                    "Benign Neoplasm Of Prostate",
                    "Adenocarcinoma Prostate",
                    "Adeno Carcinoma Prostate",
                    "Adeno CA Prostate",
                    "Prostate Tuberculosis",
                  ],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "pSALevelDate",
                label: "PSA Level Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionName",
                  fieldValue: [
                    "Malignant Neoplasm Of Prostate",
                    "Malignant Neoplasm Prostate",
                    "Prostate Calculus",
                    "Prostate Cancer",
                    "Prostate Enlarged",
                    "Prostate Hyperplasia",
                    "Prostate Hypertrophy",
                    "Prostate Neoplasm",
                    "Prostate Neoplasm Malignant",
                    "Prostate Neoplasm Unknown",
                    "Inflammatory Prostate Disease",
                    "Hyperplasia Of Prostate",
                    "Enlarged Prostate",
                    "Enlarge Prostate",
                    "Carcinoma Prostate",
                    "Carcinoma Of Prostate",
                    "Carcinoma In Situ Of Prostate",
                    "Cancer Of Prostate",
                    "Ca Prostate",
                    "Benign Tumor Of Prostate",
                    "Benign Prostate Tumour",
                    "Benign Neoplasm Of Prostate",
                    "Adenocarcinoma Prostate",
                    "Adeno Carcinoma Prostate",
                    "Adeno CA Prostate",
                    "Prostate Tuberculosis",
                  ],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "DateMMYYYY",
              props: {
                name: "gleasonScoreDate",
                label: "Gleason Score Date",
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                startYear: new Date().getFullYear() - 100,
                endYear: new Date().getFullYear(),
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionName",
                  fieldValue: [
                    "Malignant Neoplasm Of Prostate",
                    "Malignant Neoplasm Prostate",
                    "Prostate Calculus",
                    "Prostate Cancer",
                    "Prostate Enlarged",
                    "Prostate Hyperplasia",
                    "Prostate Hypertrophy",
                    "Prostate Neoplasm",
                    "Prostate Neoplasm Malignant",
                    "Prostate Neoplasm Unknown",
                    "Inflammatory Prostate Disease",
                    "Hyperplasia Of Prostate",
                    "Enlarged Prostate",
                    "Enlarge Prostate",
                    "Carcinoma Prostate",
                    "Carcinoma Of Prostate",
                    "Carcinoma In Situ Of Prostate",
                    "Cancer Of Prostate",
                    "Ca Prostate",
                    "Benign Tumor Of Prostate",
                    "Benign Prostate Tumour",
                    "Benign Neoplasm Of Prostate",
                    "Adenocarcinoma Prostate",
                    "Adeno Carcinoma Prostate",
                    "Adeno CA Prostate",
                    "Prostate Tuberculosis",
                  ],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            {
              componentType: "Number",
              props: {
                name: "gleasonScoreResult",
                label: "Gleason Score Result",
                required: false,
                description: "",
                tooltip: "",
                step: "0.1",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionName",
                  fieldValue: [
                    "Malignant Neoplasm Of Prostate",
                    "Malignant Neoplasm Prostate",
                    "Prostate Calculus",
                    "Prostate Cancer",
                    "Prostate Enlarged",
                    "Prostate Hyperplasia",
                    "Prostate Hypertrophy",
                    "Prostate Neoplasm",
                    "Prostate Neoplasm Malignant",
                    "Prostate Neoplasm Unknown",
                    "Inflammatory Prostate Disease",
                    "Hyperplasia Of Prostate",
                    "Enlarged Prostate",
                    "Enlarge Prostate",
                    "Carcinoma Prostate",
                    "Carcinoma Of Prostate",
                    "Carcinoma In Situ Of Prostate",
                    "Cancer Of Prostate",
                    "Ca Prostate",
                    "Benign Tumor Of Prostate",
                    "Benign Prostate Tumour",
                    "Benign Neoplasm Of Prostate",
                    "Adenocarcinoma Prostate",
                    "Adeno Carcinoma Prostate",
                    "Adeno CA Prostate",
                    "Prostate Tuberculosis",
                  ],
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["212"],
                },
              ],
            },

            // Stroke
            {
              componentType: "Lookup",
              props: {
                label: "Which area has been the most affected by the stroke?",
                description: "",
                name: "bodyPartAffected",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { label: "Speech", value: "6038" },
                  { label: "Vision", value: "6039" },
                  { label: "Arm", value: "6040" },
                  { label: "Leg", value: "6041" },
                  { label: "Memory Loss", value: "6042" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["213"],
                },
              ],
            },

            //stroke

            {
              componentType: "Lookup",
              props: {
                label: `What type of stroke did ${
                  isPerson2 ? "they" : "you"
                } have?`,
                description: "",
                name: "strokeType",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  {
                    label: "CVA (Cerebrovascular Accident - Major Stroke)",
                    value: "6043",
                  },
                  { label: "Subarachnoid Haemorrhage (SAH)", value: "6044" },
                  { label: "Cerebral Haemorrhage/Bleed", value: "6045" },
                  {
                    label: "TIA (Transient Ischaemic Attack - Mini-Stroke)",
                    value: "6046",
                  },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["213"],
                },
              ],
            },
            {
              name: "durationFrequencyID",
              componentType: "Lookup",
              props: {
                name: "durationFrequencyID",
                label: "Symptoms duration time frame",
                values: [
                  { label: "", value: "0" },
                  { label: "Minutes", value: "6047" },
                  { label: "Hours", value: "6048" },
                  { label: "Days", value: "6049" },
                  { label: "Months", value: "6050" },
                  { label: "Years", value: "6051" },
                ],
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["213"] },
              ],
            },
            {
              name: "durationTime",
              componentType: "InputBox",
              props: {
                name: "durationTime",
                label:
                  "How many? (e.g. if the duration of the symptoms was 2 days, set the timeframe above to days and this to 2)",
                inputType: "number",
                formatType: "integer",
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["213"] },
              ],
            },

            {
              name: "madeFullRecovery",
              componentType: "YesNoBoxes",
              props: {
                name: "madeFullRecovery",
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } made a full recovery?`,
                required: false,
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["213"] },
              ],
            },

            {
              name: "recoveryFrequencyID",
              componentType: "Lookup",
              props: {
                name: "recoveryFrequencyID",
                label: "Recovery time frame",
                required: false,
                values: [
                  { label: "", value: "" },
                  { label: "Minutes", value: "6047" },
                  { label: "Hours", value: "6048" },
                  { label: "Days", value: "6049" },
                  { label: "Months", value: "6050" },
                  { label: "Years", value: "6051" },
                ],
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["213"] },
                { fieldName: "madeFullRecovery", fieldValue: ["true"] },
              ],
            },
            {
              name: "recoveryTime",
              componentType: "InputBox",
              props: {
                name: "recoveryTime",
                label:
                  "How many? (e.g. if fully recovered in 2 months, set the timeframe above to months and this to 2)",
                inputType: "number",
                formatType: "integer",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["213"] },
                { fieldName: "madeFullRecovery", fieldValue: ["true"] },
              ],
            },

            //multiple sclerosis
            {
              name: "noOfAttacks",
              componentType: "InputBox",
              props: {
                name: "noOfAttacks",
                label: `How many attacks have ${
                  isPerson2 ? "they" : "you"
                } had?`,
                required: false,
                formatType: "integer",
              },
              dependsOnFields: [
                { fieldName: "conditionType", fieldValue: ["215"] },
              ],
            },

            // Neurological
            {
              componentType: "YesNoBoxes",
              props: {
                label: `Do ${isPerson2 ? "they" : "you"} know ${
                  isPerson2 ? "their" : "your"
                } MMSE score?`,
                description: "",
                name: "mmseScoreKnown",
                tooltip: "",
                addSeparatorAbove: true,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                name: "mmseScore",
                label: `What is ${isPerson2 ? "their" : "your"} MMSE score?`,
                required: true,
                description: "It will be a number no higher than 30",
                placeholder: "",
                inputType: "number",
                tooltip: "",
                prefix: null,
                addSeparatorBelow: false,
                formatType: "integer",
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "mmseScoreKnown",
                  fieldValue: "true",
                },
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${isPerson2 ? "they" : "you"} had any falls?`,
                description: "",
                name: "falls",
                tooltip: "",
                addSeparatorAbove: true,
                required: true,
              },
              dependsOnConditional: "and",
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${
                  isPerson2 ? "they" : "you"
                } had any pressure sores?`,
                description: "",
                name: "pressureSores",
                tooltip: "",
                required: true,
                addSeparatorAbove: true,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${isPerson2 ? "they" : "you"} had any seizures?`,
                description: "",
                name: "seizures",
                tooltip: "",
                required: true,
                addSeparatorAbove: true,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },
            {
              componentType: "YesNoBoxes",
              props: {
                label: `Have ${isPerson2 ? "they" : "you"} had any tremors?`,
                description: "",
                name: "tremors",
                tooltip: "",
                required: true,
                addSeparatorAbove: true,
              },
              dependsOnFields: [
                {
                  fieldName: "conditionType",
                  fieldValue: ["216"],
                },
              ],
            },
          ],
          dependsOnFields: [
            {
              fieldName: `${prefix}.medicalConditionsYesNo`,
              fieldValue: "true",
            },
          ],
        }),

      // The shared info schema
      ...MedicalConditionSharedInfoSchema(prefix, isPerson2),

      // Medications
      medicationsYesNo: Yup.string()
        .label(
          `Do ${isPerson2 ? "they" : "you"}, or have ${
            isPerson2 ? "they" : "you"
          } ever taken any medications for the above conditions?`
        )
        .nullable()
        .meta({
          control: {
            componentType: "YesNoBoxes",
            props: {
              label: `Do ${isPerson2 ? "they" : "you"}, or have ${
                isPerson2 ? "they" : "you"
              } ever taken any medications for the above conditions?`,
              description: `Medications ${
                isPerson2 ? "they" : "you"
              } take can dramatically increase the amount of money you could receive.`,
              name: `${prefix}.medicationsYesNo`,
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: `${prefix}.medicalConditionsYesNo`,
                fieldValue: "true",
              },
            ],
          },
        }),

      medicationList: Yup.array()
        .of(medicationSchema)
        .label("Add current or previous medications")
        .nullable()
        .default([])
        .when("medicationsYesNo", {
          is: (val: string) => val === "true",
          then: Yup.array()
            .of(medicationSchema)
            .required("Please provide details of any medications you take.")
            .min(1, "Please add at least one medication."),
        })
        .meta({
          getItemTitle: getMedicationNameFromID,
          itemTypeName: "Medication",
          itemTitleField: "medicationName",
          fieldArrayName: `${prefix}.medicationList`,
          fieldArrayDescription: `If ${
            isPerson2 ? "they" : "you"
          } take any medications, these can potentially increase the amount you recieve. Use the below to add them`,
          addSeparatorAbove: false,
          control: [
            {
              componentType: "LookupWithSearchAndGroups",
              props: {
                name: "medicationName",
                label: "What is the name of the medication?",
                required: false,
                description: "Search and select a medication",
                placeholder: "Please Select",
                tooltip: "",
                prefix: undefined,
                className: "",
                values: groupedMedications,
              },
            },
            {
              componentType: "Lookup",
              props: {
                name: "forConditionType",
                label: `Which of ${
                  isPerson2 ? "their" : "your"
                } condition types is this medication for?`,
                required: true,
                description: "",
                placeholder: "Please Select",
                tooltip: "",
                prefix: undefined,
                isPerson2: isPerson2,
                valuesFunction: medicationForConditions,
              },
            },
            {
              componentType: "Lookup",
              props: {
                label: `How often do ${
                  isPerson2 ? "they" : "you"
                } take the medication?`,
                description: "",
                name: "frequency",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { value: "5833", label: "As Required" },
                  { value: "5984", label: "Daily" },
                  { value: "5985", label: "Weekly" },
                  { value: "5986", label: "Fortnightly" },
                  { value: "5987", label: "Every Four Weeks" },
                  { value: "5988", label: "Monthly" },
                  { value: "5989", label: "Quarterly" },
                  { value: "5990", label: "Half Yearly" },
                  { value: "5991", label: "Yearly" },
                ],
              },
            },

            {
              componentType: "YesNoBoxes",
              props: {
                label: `Do ${
                  isPerson2 ? "they" : "you"
                } know the exact dosage for this medication?`,
                description: "",
                name: "dosageKnown",
                tooltip: "",
              },
            },

            {
              componentType: "InputBox",
              props: {
                label: `What dosage do ${isPerson2 ? "they" : "you"} take?`,
                description: "",
                name: "dosage",
                required: false,
                placeholder: "",
                tooltip: "",
                inputType: "number",
                prefix: null,
                addSeparatorBelow: false,
                formatType: "decimal",
              },
              dependsOnFields: [
                {
                  fieldName: "dosageKnown",
                  fieldValue: "true",
                },
              ],
            },
            {
              componentType: "Lookup",
              props: {
                label: "Unit",
                description: "",
                name: "dosageUnit",
                tooltip: "",
                values: [
                  { label: "", value: "0" },
                  { value: "5992", label: "Gram" },
                  { value: "5993", label: "Milligram" },
                  { value: "5994", label: "Microgram" },
                  { value: "5995", label: "Millilitre" },
                  { value: "5996", label: "Unit Of Insulin" },
                ],
              },
              dependsOnFields: [
                {
                  fieldName: "dosageKnown",
                  fieldValue: "true",
                },
              ],
            },
            {
              componentType: "InputBox",
              props: {
                label: `How many do ${isPerson2 ? "they" : "you"} take?`,
                description: "",
                name: "numberTaken",
                required: false,
                placeholder: "",
                tooltip: "",
                inputType: "number",
                prefix: null,
                addSeparatorBelow: false,
                formatType: "integer",
              },
              dependsOnFields: [
                {
                  fieldName: "dosageKnown",
                  fieldValue: "true",
                },
              ],
            },

            {
              componentType: "DateDDMMYYYY",
              props: {
                name: "startDate",
                label: `When did ${
                  isPerson2 ? "they" : "you"
                } start taking the medication?`,
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: 1920,
                endYear: new Date().getFullYear(),
              },
            },

            {
              componentType: "YesNoBoxes",
              props: {
                label: `Do ${
                  isPerson2 ? "they" : "you"
                } still take this medication?`,
                description: "",
                name: "stillTakeMedication",
                tooltip: "",
              },
            },

            {
              componentType: "DateDDMMYYYY",
              props: {
                name: "endDate",
                label: `When did ${
                  isPerson2 ? "they" : "you"
                } stop taking the medication?`,
                required: false,
                description: "",
                placeholder: "",
                tooltip: "",
                prefix: undefined,
                startYear: 1920,
                endYear: new Date().getFullYear(),
              },
              dependsOnFields: [
                {
                  fieldName: "stillTakeMedication",
                  fieldValue: "false",
                },
              ],
            },
          ],
          dependsOnConditional: "and",
          dependsOnFields: [
            {
              fieldName: `${prefix}.medicalConditionsYesNo`,
              fieldValue: "true",
            },
            {
              fieldName: `${prefix}.medicationsYesNo`,
              fieldValue: "true",
            },
          ],
        }),
    }),
  });
};
