import { createContext, ReactNode, useContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import {
  IClientData,
  IJournalDocument,
  IJournalNote,
  IPension,
} from "../models/ClientData";
import { IQuoteData } from "../models/QuoteData";
import { IGetQuotesRequestData } from "../models/GetQuotesRequestData";
import axios, { AxiosRequestConfig } from "axios";
import appInsights from "../azureAppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getAllPropertyNames } from "../modules/forms/Helpers";
import { MedicalConditionSharedInfoSchema } from "../modules/forms/form_schemas/Shared/MedicalConditionSharedInfoSchema";
import * as Yup from "yup";

interface IPortalContext {
  insertClientData: (data?: IClientData) => Promise<number>;
  returnClientData: () => Promise<number>;
  setClientData: (data: IClientData | undefined) => void;
  clientData: IClientData | undefined;
  getNewQuotes: (quoteRequestData: IClientData | undefined) => Promise<any>;
  getNewQuickQuotes: (
    quoteRequestData: IClientData | undefined
  ) => Promise<any>;
  quoteData: IQuoteData | undefined;
  setQuoteData: (data: IQuoteData | undefined) => void;
  clearClientContext: () => Promise<void>;
  apiUpdateClientData: (updateData: IClientData | undefined) => Promise<number>;
  firstLogin: boolean;
  retrieveClientData: () => Promise<number>;
  isLoading: boolean;
  isSlow: boolean;
}

const PortalContext = createContext<Partial<IPortalContext>>({});

const normalTimeout = 45000;
const longertimeout = 60000;

async function axiosWithTimeout(
  config: AxiosRequestConfig,
  setIsLoading: (val: boolean) => void,
  setIsSlow: (val: boolean) => void,
  SLOW_THRESHOLD_MS: number = 10000
) {
  let slowTimer: any;

  try {
    setIsLoading(true);

    // Start the slow timer
    slowTimer = setTimeout(() => {
      setIsSlow(true);

      // Log that we had a slow request
      console.log("Slow response time detected!");
      appInsights.trackTrace({
        message: `Slow response time detected: ${config?.url} - Threshold exceeded: ${SLOW_THRESHOLD_MS}`,
        severityLevel: SeverityLevel.Warning,
      });
    }, SLOW_THRESHOLD_MS);

    // Make the request
    const response = await axios.request(config);

    // If we get here, request succeeded - clear states
    clearTimeout(slowTimer);
    setIsSlow(false);
    setIsLoading(false);

    return response;
  } catch (err: any) {
    // Reset the state
    clearTimeout(slowTimer);
    setIsSlow(false);
    setIsLoading(false);

    throw err;
  }
}

export function PortalContextProvider({ children }: { children: ReactNode }) {
  const [user, loading, error] = useAuthState(auth);
  const [firstLogin, setFirstLogin] = useState(false);

  const [clientData, setClientDataContext] = useState<
    IClientData | undefined
  >();
  const [quoteData, setQuoteData] = useState<IQuoteData | undefined>(undefined);

  // Track loading and slow states for the entire context
  const [isLoading, setIsLoading] = useState(false);
  const [isSlow, setIsSlow] = useState(false);

  // API Endpoint
  //old
  //const APIURL = "https://portalwebapiapi.azure-api.net/api";
  // const APIURL = "https://penseportalwebapi.azure-api.net/api";

  //local
  //const APIURL = "https://localhost:7081/api";
  //live
  //const APIURL = "https://matrixframeworkportalwebapi.azure-api.net/api";
  //dev
  const APIURL = "https://matrixframeworkportalwebapidev.azure-api.net/api";

  const setClientData = (data: IClientData | undefined) => {
    if (!data) {
      setClientDataContext(undefined);
      return;
    }

    // Default previousNames yes/no
    let previousNamesYesNo = "false";
    let person2PreviousNamesYesNo = "false";

    if (data.previousNames && data.previousNames.length > 0) {
      previousNamesYesNo = "true";
    }

    if (data.person2PreviousNames && data.person2PreviousNames.length > 0) {
      person2PreviousNamesYesNo = "true";
    }

    // Default smoking logic
    let isPreviousSmoker = "false";
    let isCurrentOrPreviousSmoker = "false";

    if (
      data.medicalInformation?.yearMonthStartedSmoking &&
      data.medicalInformation?.yearMonthStartedSmoking.length > 0 &&
      (!data.medicalInformation?.yearMonthStoppedSmoking ||
        data.medicalInformation?.yearMonthStoppedSmoking.length === 0)
    ) {
      isCurrentOrPreviousSmoker = "true";
    }

    if (
      data.medicalInformation?.yearMonthStartedSmoking &&
      data.medicalInformation?.yearMonthStartedSmoking.length > 0 &&
      data.medicalInformation?.yearMonthStoppedSmoking &&
      data.medicalInformation?.yearMonthStoppedSmoking.length > 0
    ) {
      isPreviousSmoker = "true";
    }

    let isPerson2PreviousSmoker = "false";
    let isPerson2CurrentOrPreviousSmoker = "false";

    if (
      data.person2MedicalInformation?.yearMonthStartedSmoking &&
      data.person2MedicalInformation?.yearMonthStartedSmoking.length > 0 &&
      (!data.person2MedicalInformation?.yearMonthStoppedSmoking ||
        data.person2MedicalInformation?.yearMonthStoppedSmoking.length === 0)
    ) {
      isPerson2CurrentOrPreviousSmoker = "true";
    }

    if (
      data.person2MedicalInformation?.yearMonthStartedSmoking &&
      data.person2MedicalInformation?.yearMonthStartedSmoking.length > 0 &&
      data.person2MedicalInformation?.yearMonthStoppedSmoking &&
      data.person2MedicalInformation?.yearMonthStoppedSmoking.length > 0
    ) {
      isPerson2PreviousSmoker = "true";
    }

    // Default medical conditions yes/no
    let hasMedicalConditionsYesNo = "false";
    let hasMedicationsYesNo = "false";

    if (
      data.medicalInformation &&
      Array.isArray(data.medicalInformation.medicalConditionList) &&
      data.medicalInformation.medicalConditionList.length > 0
    ) {
      hasMedicalConditionsYesNo = "true";
    }

    if (
      data.medicalInformation &&
      Array.isArray(data.medicalInformation.medicationList) &&
      data.medicalInformation.medicationList.length > 0
    ) {
      hasMedicationsYesNo = "true";

      data.medicalInformation.medicationList.forEach((medication) => {
        if (medication.endDate) {
          medication.stillTakeMedication = "false";
        } else {
          medication.stillTakeMedication = "true";
        }

        if (medication.dosage && parseInt(medication.dosage) > 0) {
          medication.dosageKnown = "true";
        } else {
          medication.dosageKnown = "false";
        }
      });
    }

    let person2HasMedicalConditionsYesNo = "false";
    let person2HasMedicationsYesNo = "false";

    if (
      data.person2MedicalInformation &&
      Array.isArray(data.person2MedicalInformation.medicalConditionList) &&
      data.person2MedicalInformation.medicalConditionList.length > 0
    ) {
      person2HasMedicalConditionsYesNo = "true";
    }

    if (
      data.person2MedicalInformation &&
      Array.isArray(data.person2MedicalInformation.medicationList) &&
      data.person2MedicalInformation.medicationList.length > 0
    ) {
      person2HasMedicationsYesNo = "true";

      data.person2MedicalInformation.medicationList.forEach((medication) => {
        if (medication.endDate) {
          medication.stillTakeMedication = "false";
        } else {
          medication.stillTakeMedication = "true";
        }

        if (medication.dosage && parseInt(medication.dosage) > 0) {
          medication.dosageKnown = "true";
        } else {
          medication.dosageKnown = "false";
        }
      });
    }

    // We need to get the fields from medicalconditionsharedinfoschema and put them under medinfo instead
    let sharedMedicalConditionInfo: { [key: string]: any } = {};

    if (
      data?.medicalInformation &&
      Array.isArray(data?.medicalInformation?.medicalConditionList) &&
      data?.medicalInformation?.medicalConditionList?.length > 0
    ) {
      const sharedInfoSchema = MedicalConditionSharedInfoSchema(
        "medicalInformation",
        false
      );

      const propertyNames = getAllPropertyNames(
        Yup.object().shape(sharedInfoSchema)
      );

      propertyNames.forEach((propertyName) => {
        propertyName = propertyName.replace("medicalConditionSharedInfo.", "");

        data.medicalInformation?.medicalConditionList?.forEach(
          (medicalCondition: any) => {
            const propertyValue = medicalCondition[propertyName];

            if (propertyValue !== undefined) {
              sharedMedicalConditionInfo[propertyName] = propertyValue;
            }
          }
        );
      });
    }

    // Map client journal notes and docs
    let clientJournalDocs: Array<IJournalDocument> = [];

    if (Array.isArray(data.journalNotes) && data.journalNotes.length > 0) {
      data.journalNotes.forEach((journalNote: IJournalNote) => {
        for (let i = 0; i < 11; i++) {
          const journalFileName = `attachment${i}Name`;
          const journalFile = `attachment${i}`;

          if (
            journalNote[journalFileName] &&
            journalNote[journalFileName] !== ""
          ) {
            clientJournalDocs.push({
              name: journalNote[journalFileName],
              file: journalNote[journalFile],
              date: journalNote.date,
              type: journalNote[journalFileName].split(".")[1],
              redirectURL:
                journalNote[journalFileName].requiresSignature === "true" &&
                journalNote[journalFileName].docusignEnvelopeID !== null
                  ? journalNote[journalFileName].docusignEnvelopeID
                  : null,
              requiresSignature: journalNote[journalFileName].requiresSignature,
            });
          }
        }
      });
    }

    // Map Annuity journal notes and docs
    let annuityJournalDocs: Array<IJournalDocument> = [];

    if (
      data.annuity &&
      Array.isArray(data.annuity.journalNotes) &&
      data.annuity.journalNotes.length > 0
    ) {
      data.annuity.journalNotes.forEach((journalNote: IJournalNote) => {
        for (let i = 0; i < 11; i++) {
          const journalFileName = `attachment${i}Name`;
          const journalFile = `attachment${i}`;

          if (
            journalNote[journalFileName] &&
            journalNote[journalFileName] !== ""
          ) {
            annuityJournalDocs.push({
              name: journalNote[journalFileName],
              file:
                journalNote.requiresSignature === "true"
                  ? ""
                  : journalNote[journalFile],
              date: journalNote.date,
              type:
                journalNote.requiresSignature === "true"
                  ? "pdf"
                  : journalNote[journalFileName].split(".")[1],
              redirectURL:
                journalNote.requiresSignature === "true" &&
                journalNote.redirectUrl !== null
                  ? journalNote.redirectUrl
                  : null,
              requiresSignature: journalNote.requiresSignature === "true",
            });
          }
        }
      });
    }

    // Map Pensions
    let pensions: Array<IPension> = [];

    if (
      data.pensions &&
      Array.isArray(data.pensions) &&
      data.pensions.length > 0
    ) {
      pensions = data.pensions;

      // Map Pension journal notes and docs
      pensions.forEach((pension) => {
        let pensionJournalDocs: Array<IJournalDocument> = [];

        if (
          pension &&
          Array.isArray(pension.journalNotes) &&
          pension.journalNotes.length > 0
        ) {
          pension.journalNotes.forEach((journalNote: IJournalNote) => {
            for (let i = 0; i < 11; i++) {
              const journalFileName = `attachment${i}Name`;
              const journalFile = `attachment${i}`;

              if (
                journalNote[journalFileName] &&
                journalNote[journalFileName] !== ""
              ) {
                pensionJournalDocs.push({
                  name: journalNote[journalFileName],
                  file: journalNote[journalFile],
                  date: journalNote.date,
                  type: journalNote[journalFileName].split(".")[1],
                  redirectURL:
                    journalNote.requiresSignature === "true" &&
                    journalNote.docusignEnvelopeID !== null
                      ? journalNote.docusignEnvelopeID
                      : null,
                  requiresSignature: journalNote.requiresSignature,
                });
              }
            }
          });

          pension.journalDocuments = pensionJournalDocs;
        }
      });
    }

    // Update annuity client1OrJoint field to match client1OrJoint on personal details
    if (data.annuity) {
      data.client1OrJoint = data.annuity.clientId;
    }

    // Handle employment values
    if (data?.employments && data?.employments.length > 0) {
      data?.employments.forEach((employment) => {
        if (
          employment.occupation === "3243" ||
          employment.occupation === "3585"
        ) {
          employment.employmentType = "N/A";
        }
      });
    }

    // Build data with defaults
    const clientDataWithDefaults = {
      ...data,
      hasPreviousNamesYesNo: previousNamesYesNo,
      person2HasPreviousNamesYesNo: person2PreviousNamesYesNo,
      journalDocuments: clientJournalDocs,
      medicalInformation: {
        ...(data.medicalInformation ?? {}),
        ...sharedMedicalConditionInfo,
        medicalConditionsYesNo: hasMedicalConditionsYesNo,
        medicationsYesNo: hasMedicationsYesNo,
        previousSmokerYesNo: isPreviousSmoker,
        previousOrCurrentSmokerYesNo:
          isPreviousSmoker === "true" || isCurrentOrPreviousSmoker === "true"
            ? "true"
            : "false",
      },
      person2MedicalInformation: {
        ...(data.person2MedicalInformation ?? {}),
        medicalConditionsYesNo: person2HasMedicalConditionsYesNo,
        medicationsYesNo: person2HasMedicationsYesNo,
        previousSmokerYesNo: isPerson2PreviousSmoker,
        previousOrCurrentSmokerYesNo:
          isPerson2PreviousSmoker === "true" ||
          isPerson2CurrentOrPreviousSmoker === "true"
            ? "true"
            : "false",
      },
      pensions: pensions,
      annuity: {
        ...data.annuity,
        milestones: [
          {
            name: "IFA advice & recommendation",
            completed: data.annuity?.iFACallCompletedDate ? true : false,
            completedDate: data.annuity?.iFACallCompletedDate
              ? data.annuity.iFACallCompletedDate.replace("00:00:00", "")
              : "",
          },
          {
            name: "Pension checks",
            completed: data.annuity?.pensionChecksCompletedDate ? true : false,
            completedDate: data.annuity?.pensionChecksCompletedDate
              ? data.annuity.pensionChecksCompletedDate.replace("00:00:00", "")
              : "",
          },
          {
            name: "Application pack sent",
            completed: data.annuity?.applicationPackSentToClientDate
              ? true
              : false,
            completedDate: data.annuity?.applicationPackSentToClientDate
              ? data.annuity.applicationPackSentToClientDate.replace(
                  "00:00:00",
                  ""
                )
              : "",
          },
          {
            name: "Application pack back",
            completed: data.annuity?.applicationPackBackDate ? true : false,
            completedDate: data.annuity?.applicationPackBackDate
              ? data.annuity.applicationPackBackDate.replace("00:00:00", "")
              : "",
          },
          {
            name: "Application submitted to provider",
            completed: data.annuity?.applicationSubmittedToProviderDate
              ? true
              : false,
            completedDate: data.annuity?.applicationSubmittedToProviderDate
              ? data.annuity.applicationSubmittedToProviderDate.replace(
                  "00:00:00",
                  ""
                )
              : "",
          },
          {
            name: "Completion",
            completed: data.annuity?.completedDate ? true : false,
            completedDate: data.annuity?.completedDate
              ? data.annuity.completedDate.replace("00:00:00", "")
              : "",
          },
        ],
        journalDocuments: annuityJournalDocs,
        escalationRate: data.annuity?.escalationRate,
        paymentFrequency: data.annuity?.paymentFrequency,
        paymentTiming: data.annuity?.paymentTiming,
        guaranteePeriod: data.annuity?.guaranteePeriod,
        reversionaryType: data.annuity?.reversionaryType,
        reversionaryBenefit: data.annuity?.reversionaryBenefit,
        reversionaryStart: data.annuity?.reversionaryStart,
      },
    };
    console.log(clientDataWithDefaults);
    setClientDataContext(clientDataWithDefaults);
  };

  async function apiUpdateClientData(
    updateData: IClientData | undefined
  ): Promise<number> {
    let responseData: IClientData = {};

    if (updateData) {
      // Update previousNames yes/no
      if (updateData.hasPreviousNamesYesNo === "false") {
        updateData.previousNames = "";
        updateData.previousNamesDateChanged = "";
      }

      if (updateData.person2HasPreviousNamesYesNo === "false") {
        updateData.person2PreviousNames = "";
        updateData.person2PreviousNamesDateChanged = "";
      }

      if (updateData.medicalInformation) {
        let medicalInfo: any = updateData.medicalInformation;

        // Default previousSmoker yes/no and previousOrCurrentSmoker yes/no
        if (medicalInfo.previousOrCurrentSmokerYesNo === "false") {
          medicalInfo.yearMonthStartedSmoking = "";
          medicalInfo.monthStartedSmoking = "";
          medicalInfo.yearStartedSmoking = "";
          medicalInfo.yearMonthStoppedSmoking = "";
          medicalInfo.monthStoppedSmoking = "";
          medicalInfo.yearStoppedSmoking = "";
          medicalInfo.cigarettesPerDay = 0;
          medicalInfo.gramsOfTobaccoPerDay = 0;
          medicalInfo.gramsOfPipeTobaccoPerDay = 0;
          medicalInfo.cigarsPerDay = 0;
        }

        // Set currentsmoker field
        if (
          medicalInfo.previousOrCurrentSmokerYesNo === "true" &&
          medicalInfo.previousSmokerYesNo === "false"
        ) {
          medicalInfo.currentSmoker = "true";
        }
        debugger;
        if (medicalInfo.previousSmoker === "false") {
          medicalInfo.yearMonthStoppedSmoking = "";
          medicalInfo.monthStoppedSmoking = "";
          medicalInfo.yearStoppedSmoking = "";
        }

        // Default medical conditions yes/no and medications yes/no
        if (medicalInfo.medicalConditionsYesNo === "false") {
          medicalInfo.medicalConditionList = [];
        }

        if (medicalInfo.medicationsYesNo === "false") {
          medicalInfo.medicationList = [];
        }

        if (
          medicalInfo?.medicalConditionsYesNo === "true" &&
          Array.isArray(medicalInfo?.medicalConditionList) &&
          medicalInfo?.medicalConditionList?.length > 0
        ) {
          // For each property in medicalconditionsharedinfoschema.tsx we need to get the value of it from our medinfo schema and put it and it's value into every medical condition in our medicalconditionlist
          const sharedInfoSchema = MedicalConditionSharedInfoSchema(
            "medicalInformation",
            false
          );

          // For each propertyname, get the value from medicalinfo and add it to every medicalcondition in the medicalcondition list
          const propertyNames = getAllPropertyNames(
            Yup.object().shape(sharedInfoSchema)
          );

          propertyNames.forEach((propertyName) => {
            propertyName = propertyName.replace(
              "medicalConditionSharedInfo.",
              ""
            );

            const propertyValue = medicalInfo[propertyName];

            if (propertyValue !== undefined) {
              medicalInfo.medicalConditionList.forEach(
                (medicalCondition: any) => {
                  medicalCondition[propertyName] = propertyValue;
                }
              );
            }
          });
        }
        // Default medical condition customerProvidedMedicalConditionName when medicalConditionID recognised
        medicalInfo?.medicalConditionList?.forEach((medCondition: any) => {
          if (medCondition.conditionName !== "Unknown") {
            medCondition.customerProvidedMedicalConditionName = "";
          }
        });

        updateData.medicalInformation = medicalInfo;
      }

      if (updateData.person2MedicalInformation) {
        let person2MedicalInfo: any = updateData.person2MedicalInformation;

        if (person2MedicalInfo.previousOrCurrentSmokerYesNo === "false") {
          person2MedicalInfo.yearMonthStartedSmoking = "";
          person2MedicalInfo.monthStartedSmoking = "";
          person2MedicalInfo.yearStartedSmoking = "";
          person2MedicalInfo.yearMonthStoppedSmoking = "";
          person2MedicalInfo.monthStoppedSmoking = "";
          person2MedicalInfo.yearStoppedSmoking = "";
          person2MedicalInfo.cigarettesPerDay = 0;
          person2MedicalInfo.gramsOfTobaccoPerDay = 0;
          person2MedicalInfo.gramsOfPipeTobaccoPerDay = 0;
          person2MedicalInfo.cigarsPerDay = 0;
        }

        if (
          person2MedicalInfo.previousOrCurrentSmokerYesNo === "true" &&
          person2MedicalInfo.previousSmokerYesNo === "false"
        ) {
          person2MedicalInfo.currentSmoker = "true";
        }

        if (person2MedicalInfo.previousSmokerYesNo === "false") {
          person2MedicalInfo.yearMonthStoppedSmoking = "";
          person2MedicalInfo.monthStoppedSmoking = "";
          person2MedicalInfo.yearStoppedSmoking = "";
        }

        if (person2MedicalInfo.medicalConditionsYesNo === "false") {
          person2MedicalInfo.medicalConditionList = [];
        }

        if (person2MedicalInfo.medicationsYesNo === "false") {
          person2MedicalInfo.medicationList = [];
        }

        if (
          person2MedicalInfo?.medicalConditionsYesNo === "true" &&
          Array.isArray(person2MedicalInfo?.medicalConditionList) &&
          person2MedicalInfo?.medicalCondition?.length > 0
        ) {
          // For each property in medicalconditionsharedinfoschema.tsx we need to get the value of it from our medinfo schema and put it and it's value into every medical condition in our medicalconditionlist
          const sharedInfoSchema = MedicalConditionSharedInfoSchema(
            "person2MedicalInformation",
            true
          );

          // Foreach propertyname, get the value from medicalinfo and add it to every medicalcondition in the medicalcondition list
          const propertyNames = getAllPropertyNames(
            Yup.object().shape(sharedInfoSchema)
          );
          debugger;
          propertyNames.forEach((propertyName) => {
            propertyName = propertyName.replace(
              "medicalConditionSharedInfo.",
              ""
            );

            const propertyValue = person2MedicalInfo[propertyName];

            if (propertyValue !== undefined) {
              person2MedicalInfo.medicalConditionList.forEach(
                (medicalCondition: any) => {
                  medicalCondition[propertyName] = propertyValue;
                }
              );
            }
          });
        }

        // Default medical condition customerProvidedMedicalConditionName when medicalConditionID recognised
        person2MedicalInfo?.medicalConditionList?.forEach(
          (medCondition: any) => {
            if (medCondition.conditionName !== "Unknown") {
              medCondition.customerProvidedMedicalConditionName = "";
            }
          }
        );

        updateData.person2MedicalInformation = person2MedicalInfo;
      }

      // Default annuity quote reversionary fields
      if (updateData?.annuity?.reversionaryTypeYesNo === "false") {
        updateData.annuity.reversionaryType = "5919"; // None
        updateData.annuity.reversionaryBenefit = "100272"; // None
        updateData.annuity.reversionaryStart = "";
      }

      // Default pension customerProvidedCedingSchemeName when cedingSchemeId recognised
      updateData.pensions?.forEach((pension) => {
        if (pension.cedingScheme !== "-1") {
          pension.customerProvidedCedingSchemeName = "";
        }
      });

      // Update annuity client1OrJoint field to match client1OrJoint on personal details
      if (updateData?.annuity) {
        updateData.annuity.clientId = updateData.client1OrJoint;
      }
    }

    if (!updateData) {
      setClientData(responseData);
      return 200;
    }

    try {
      const idToken = await auth.currentUser?.getIdToken();
      const url = `${APIURL}/client/update`;

      const response = await axiosWithTimeout(
        {
          method: "POST",
          url,
          data: updateData,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          timeout: normalTimeout, // 30s request timeout
        },
        setIsLoading,
        setIsSlow,
        10000 // 10 seconds to consider "slow"
      );

      setClientData(response.data);
      return 200;
    } catch (error: any) {
      console.log(error);

      let appInsightsError: Error = {
        name: "Portal Context - apiUpdateClientData Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      return errorCode ?? 500;
    }
  }

  async function returnClientData() {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      const url = `${APIURL}/client`;

      const response = await axiosWithTimeout(
        {
          method: "GET",
          url,
          headers: {
            Authorization: `${idToken}`,
          },
          timeout: normalTimeout,
        },
        setIsLoading,
        setIsSlow
      );

      // Success
      setClientData(response.data);
      return 200;
    } catch (error: any) {
      console.log(error);
      appInsights.trackException({ exception: error });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      return errorCode ?? 500;
    }
  }

  async function insertClientData(data?: IClientData) {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      let clientJSONObject = data ?? clientData ?? {};
      const url = `${APIURL}/client/insert`;

      // Check if clientData has first, last name and email, set if not before posting from user auth
      clientDataChecks(clientJSONObject);

      const response = await axiosWithTimeout(
        {
          method: "POST",
          url,
          data: clientJSONObject,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          timeout: normalTimeout,
        },
        setIsLoading,
        setIsSlow
      );

      setClientData(response.data);
      setFirstLogin(true);

      return 200;
    } catch (error: any) {
      console.log(error);
      appInsights.trackException({ error });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      return errorCode ?? 500;
    }
  }

  async function retrieveClientData() {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      let clientJSONObject = clientData ?? {};
      const url = `${APIURL}/client/retrieve`;

      clientDataChecks(clientJSONObject);

      const response = await axiosWithTimeout(
        {
          method: "POST",
          url,
          data: clientJSONObject,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          timeout: longertimeout,
        },
        setIsLoading,
        setIsSlow
      );

      setClientData(response.data);
      setFirstLogin(true);

      return 200;
    } catch (error: any) {
      console.log(error);
      appInsights.trackException({ error });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      return errorCode ?? 500;
    }
  }

  function clientDataChecks(clientJSONObject: IClientData) {
    if (!clientJSONObject?.firstName || clientJSONObject.firstName === null) {
      if (user?.displayName) {
        const firstName =
          user.displayName.indexOf(" ") === -1
            ? user.displayName
            : user.displayName.split(" ")[0];
        clientJSONObject.firstName = firstName;
      }
    }

    if (!clientJSONObject?.lastName || clientJSONObject.lastName === null) {
      if (user?.displayName) {
        const lastName =
          user.displayName.indexOf(" ") === -1
            ? ""
            : user.displayName.split(" ")[1];
        clientJSONObject.lastName = lastName;
      }
    }

    if (!clientJSONObject?.email || clientJSONObject.email === null) {
      if (user?.email) {
        clientJSONObject.email = user.email;
      }
    }
  }

  async function clearClientContext() {
    setClientData(undefined);
  }

  async function getNewQuotes(quoteRequestData: IClientData | undefined) {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      let url = `${APIURL}/quote/iress`;

      const response = await axiosWithTimeout(
        {
          method: "POST",
          url,
          data: quoteRequestData,
          headers: {
            Authorization: `${idToken}`,
          },
          timeout: longertimeout,
        },
        setIsLoading,
        setIsSlow,
        normalTimeout // Give quotes 30seconds before they are considered slow
      );

      const updatedClientData = {
        ...clientData,
        annuity: {
          ...clientData?.annuity,
          fullQuoteCompleted: "true",
          quotes: response.data,
        },
      };

      const updateResponse = await apiUpdateClientData(updatedClientData);

      if (updateResponse !== 200) {
        return 500;
      }

      return 200;
    } catch (error: any) {
      console.log(error);

      let appInsightsError: Error = {
        name: "Portal Context - getNewQuotes Error",
        message: String(error),
      };

      appInsights.trackException({ exception: appInsightsError });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      return errorCode ?? 500;
    }
  }

  async function getNewQuickQuotes(quoteRequestData: IClientData | undefined) {
    try {
      let url = `${APIURL}/quote/iressquick`;

      const response = await axiosWithTimeout(
        {
          method: "POST",
          url,
          data: quoteRequestData,
          headers: {},
          timeout: longertimeout,
        },
        setIsLoading,
        setIsSlow,
        normalTimeout // Give quotes 30seconds before they are considered slow
      );

      const updatedClientData = {
        ...clientData,
        annuity: {
          ...clientData?.annuity,
          quotes: response.data,
        },
      };

      setClientData(updatedClientData);
      return 200;
    } catch (error: any) {
      console.log(error);

      let appInsightsError: Error = {
        name: "Portal Context - getNewQuickQuotes Error",
        message: String(error),
      };

      appInsights.trackException({ exception: appInsightsError });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      return errorCode ?? 500;
    }
  }

  function buildGetQuotesRequest(): IGetQuotesRequestData {
    let quoteData: any = {
      client1: {},
    };

    let title = clientData?.title?.toLowerCase();
    switch (title) {
      case "mr":
        quoteData.client1.title = 0;
        break;
      case "mrs":
        quoteData.client1.title = 1;
        break;
      case "miss":
        quoteData.client1.title = 2;
        break;
      case "ms":
        quoteData.client1.title = 3;
        break;
      default:
        quoteData.client1.title = 4;
        break;
    }

    quoteData.client1.forenames = clientData?.firstName;
    quoteData.client1.surname = clientData?.lastName;
    quoteData.client1.middlename = clientData?.middleName;

    if (clientData?.postcode) {
      quoteData.client1.address.postcode = clientData?.postcode;
    }

    const quoteDataold: IGetQuotesRequestData = {
      client1: {
        enhancedUnderwriting: {
          lifestyle: {
            height_value:
              (clientData?.medicalInformation?.heightFeet
                ? clientData.medicalInformation.heightFeet * 12
                : 0) +
              (clientData?.medicalInformation?.heightInches
                ? clientData.medicalInformation.heightInches
                : 0),
            weight_value:
              (clientData?.medicalInformation?.weightStone
                ? clientData.medicalInformation.weightStone * 14
                : 0) +
              (clientData?.medicalInformation?.weightPounds
                ? clientData.medicalInformation.weightPounds
                : 0),
            waist_value: clientData?.medicalInformation?.waistMeasurement
              ? clientData?.medicalInformation?.waistMeasurement
              : 0,
            unitsOfAlcoholKnown: true,
            units_of_alcohol: clientData?.medicalInformation
              ?.alcoholUnitsPerWeek
              ? clientData?.medicalInformation?.alcoholUnitsPerWeek
              : 0,

            current_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
            previous_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
          },
          medicalConditions: [],
          heartFutureTreatment: {},
          heartFutureTreatmentType: 0,
          heartHasArterialSurgery: {},
          heartHasNonArterialSurgery: {},
          strokeDischarged: {},
          respiratoryHighestLungImpairment: 0,
        },
        title: 0,
        forenames: "",
        surname: "",
        middlename: "",
        address: {
          addressID: "",
          line_1: "",
          line_2: "",
          line_3: "",
          line_4: "",
          line_5: "",
          postcode: "",
        },
        maritalStatus: 0,
        sex: 0,
        dob: new Date(),
        occupation: {
          name: "",
          code: "",
        },
        smoker: {},
        medicalHistory: {},
        lifestyleFactors: {},
        relationshipToPartner: 0,
        useAgeNextBirthday: false,
        ccjBankruptcyInd: false,
      },
      client2: {
        impairmentLevel: 0,
        enhancedUnderwriting: {
          activities_of_daily_living: {
            dressing: 0,
            mobility: 0,
            transferring: 0,
            bladder: 0,
            bowels: 0,
            bathing: 0,
            feeding: 0,
            progression: 0,
          },
          lifestyle: {
            height_unit: 0,
            height_value: 0,
            weight_unit: 0,
            weight_value: 0,
            waist_unit: 0,
            waist_value: 0,
            unitsOfAlcoholKnown: {},
            units_of_alcohol: 0,
            current_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
            previous_smoking: {
              smoking: {},
              number_of_cigarettes_per_day: 0,
              number_of_cigars_per_day: 0,
              rolling_tobacco_per_week_unit: 0,
              rolling_tobacco_per_week_value: 0,
              pipe_tobacco_per_week_unit: 0,
              pipe_tobacco_per_week_value: 0,
              start_date: new Date(),
              end_date: new Date(),
            },
          },
          medicalConditions: [],
          heartFutureTreatment: {},
          heartFutureTreatmentType: 0,
          heartHasArterialSurgery: {},
          heartHasNonArterialSurgery: {},
          strokeDischarged: {},
          respiratoryHighestLungImpairment: 0,
        },
        title: 0,
        forenames: "",
        surname: "",
        middlename: "",
        address: {
          addressID: "",
          line_1: "",
          line_2: "",
          line_3: "",
          line_4: "",
          line_5: "",
          postcode: "",
        },
        maritalStatus: 0,
        sex: 0,
        dob: new Date(),
        occupation: {
          name: "",
          code: "",
        },
        smoker: {},
        medicalHistory: {},
        lifestyleFactors: {},
        relationshipToPartner: 0,
        useAgeNextBirthday: false,
        ccjBankruptcyInd: false,
      },
      productDetails: {
        reversionary: {
          type: 0,
          benefitPcnt: 0,
          start: 0,
          end: 0,
          spouseOption: 0,
          endDateTermYears: 0,
          endDateTermMonths: 0,
          endDateDate: new Date(),
          overlap: {},
        },
        productType: 0,
        fixedTermYears: 0,
        fixedTermMonths: 0,
        requiredOutcome: 0,
        incomelevel: {
          minimumGAD: false,
          maximumGAD: false,
          providerCalculatedRate: false,
        },
        totalFundValue: 0,
      },
    };

    return quoteData;
  }

  return (
    <PortalContext.Provider
      value={{
        insertClientData,
        returnClientData,
        setClientData,
        clientData,
        getNewQuotes,
        getNewQuickQuotes,
        quoteData,
        setQuoteData,
        clearClientContext,
        apiUpdateClientData,
        firstLogin,
        retrieveClientData,
        isLoading,
        isSlow,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
}

export function usePortal() {
  return useContext(PortalContext);
}
