import { useEffect, useState } from "react";
import { usePortal } from "../../context/PortalContext";
import { DataCard1 } from "../../modules/common/cards/DataCard1";
import { EditDataModal } from "../../modules/common/modals/EditDataModal";
import Table from "../../modules/common/other/Table";
import {
  pensionTypeLookup,
} from "../../modules/forms/Lookups";
import { groupedOptions } from "../../models/PensionProviders";

import { schema as personalDetailsSchema } from "../../modules/forms/form_schemas/AnnuityFactfind/PersonalDetails";

import { schema as pensionSchema } from "../../modules/forms/form_schemas/AnnuityFactfind/PensionDetails";

import {
  person1MedicalSchema,
  person2MedicalSchema,
} from "../../modules/forms/form_schemas/AnnuityFactfind/MedicalDetails";

import { schema as employmentSchema } from "../../modules/forms/form_schemas/AnnuityFactfind/EmploymentDetails";

import { schema as propertySchema } from "../../modules/forms/form_schemas/AnnuityFactfind/PropertyDetails";

import { CTA1 } from "../../modules/common/cards/CTA1";
import { Loader } from "../../modules/common/modals/LoaderModal";
import { Accordion } from "../../modules/common/other/Accordion";
import { IPension } from "../../models/ClientData";
import appInsights from "../../azureAppInsights";
import { useNavigate } from "react-router-dom";
import { calculateEmploymentDisplayValues } from "../../modules/common/componentDataHelpers/EmploymentHelper";

interface IProperty {
  address?: string;
  city?: string;
  postcode?: string;
  estimatedValue?: string;
  propertyID?: string;
}

interface IEmployment {
  occupation?: string;
  employmentType?: string;
  employmentStatus?: string;
  dateStarted?: string;
  dateEnded?: string;
}

interface IRow {
  data: string[];
}

export const MyDetails = () => {
  const { returnClientData, clientData, apiUpdateClientData }: any =
    usePortal();

  const navigate = useNavigate();

  const [showYourProfileModal, setShowYourProfileModal] = useState(false);
  const [showYourAccountModal, setShowYourAccountModal] = useState(false);
  const [showMedicalAndLifestyleModal, setShowMedicalAndLifestyleModal] =
    useState(false);
  const [showMedicalAndLifestyleModal2, setShowMedicalAndLifestyleModal2] =
    useState(false);
  const [showEmploymentModal, setShowEmploymentModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showPensionModal, setShowPensionModal] = useState(false);

  async function updateClient(data: any) {
    try {
      console.log("updating please wait.");
      const responseCode: number = await apiUpdateClientData(data);

      if (responseCode !== 200) {
        navigate(`/error/${responseCode}`);
      }
    } catch (error: any) {
      console.error(error);

      let appInsightsError: Error = {
        name: "MyDetails - updateClient Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });

      // If it's not a 404 or a 500 then mask it as a 500 as that's all we have an error page for
      let errorCode =
        error?.response?.status === 404 || error?.response?.status === 500
          ? error?.response?.status
          : 500;

      navigate(`/error/${errorCode}`);
    }
  }

  const [personalDetailsExpanded, setPersonalDetailsExpanded] = useState(true);
  const [medicalDetailsExpanded, setMedicalDetailsExpanded] = useState(false);
  const [propertyDetailsExpanded, setPropertyDetailsExpanded] = useState(false);
  const [pensionDetailsExpanded, setPensionDetailsExpanded] = useState(false);
  const [employmentDetailsExpanded, setEmploymentDetailsExpanded] =
    useState(false);
  const [recordLocked, setRecordLocked] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      let responseCode: number = await returnClientData();

      if (responseCode !== 200) {
        navigate(`/error/${responseCode}`);
      }
    };

    if (!clientData) {
      fetchClientData();
    } else {
      if (clientData?.annuity?.status != "Portal") {
        setRecordLocked(true);
      } else {
        setRecordLocked(false);
      }
    }
  }, []);

  useEffect(() => {
    if (clientData?.annuity?.status != "Portal") {
      setRecordLocked(true);
    } else {
      setRecordLocked(false);
    }
  }, [clientData]);

  function closeAllAccordians() {
    setPensionDetailsExpanded(false);
    setEmploymentDetailsExpanded(false);
    setPropertyDetailsExpanded(false);
    setMedicalDetailsExpanded(false);
    setPersonalDetailsExpanded(false);
  }

  const togglePersonalDetails = () => {
    closeAllAccordians();
    setPersonalDetailsExpanded(!personalDetailsExpanded);
  };

  const toggleMedicalDetails = () => {
    closeAllAccordians();
    setMedicalDetailsExpanded(!medicalDetailsExpanded);
  };

  const togglePropertyDetails = () => {
    closeAllAccordians();
    setPropertyDetailsExpanded(!propertyDetailsExpanded);
  };

  const toggleEmploymentDetails = () => {
    closeAllAccordians();
    setEmploymentDetailsExpanded(!employmentDetailsExpanded);
  };

  const togglePensionDetails = () => {
    closeAllAccordians();
    setPensionDetailsExpanded(!pensionDetailsExpanded);
  };

  return (
    <>
      {clientData ? (
        <>
          <div className="mb-10">
            <CTA1
              title={<div className="mb-5">Ready to complete a factfind?</div>}
              imageSrc="media/illustrations/bw/png/feedback.png"
              button={[
                {
                  to: "/factfind",
                  label: "GET STARTED",
                  className: "btn btn-primary fw-semibold px-6 py-3",
                  function: undefined,
                },
              ]}
            />
          </div>

          <EditDataModal
            data={clientData}
            schema={personalDetailsSchema}
            handleClose={() => setShowYourProfileModal(false)}
            show={showYourProfileModal}
            handleSubmit={updateClient}
            modalHeader="Your details"
            fullscreen={true}
            recordLocked={recordLocked}
            isPerson2={false}
            enableReinitialize={true}
          />
          <EditDataModal
            data={clientData}
            schema={pensionSchema}
            handleClose={() => setShowPensionModal(false)}
            show={showPensionModal}
            handleSubmit={updateClient}
            modalHeader="Pension details"
            fullscreen={true}
            recordLocked={recordLocked}
            isPerson2={false}
            enableReinitialize={true}
          />
          <EditDataModal
            data={clientData}
            schema={person1MedicalSchema}
            handleClose={() => setShowMedicalAndLifestyleModal(false)}
            show={showMedicalAndLifestyleModal}
            handleSubmit={updateClient}
            modalHeader="Medical details"
            fullscreen={true}
            recordLocked={recordLocked}
            isPerson2={false}
            enableReinitialize={true}
          />
          <EditDataModal
            data={clientData}
            schema={person2MedicalSchema}
            handleClose={() => setShowMedicalAndLifestyleModal2(false)}
            show={showMedicalAndLifestyleModal2}
            handleSubmit={updateClient}
            modalHeader="Person 2 Medical details"
            fullscreen={true}
            recordLocked={recordLocked}
            isPerson2={true}
            enableReinitialize={true}
          />
          <EditDataModal
            data={clientData}
            schema={employmentSchema}
            handleClose={() => setShowEmploymentModal(false)}
            show={showEmploymentModal}
            handleSubmit={updateClient}
            modalHeader="Employment details"
            fullscreen={true}
            recordLocked={recordLocked}
            isPerson2={false}
            enableReinitialize={true}
          />
          <EditDataModal
            data={clientData}
            schema={propertySchema}
            handleClose={() => setShowPropertyModal(false)}
            show={showPropertyModal}
            handleSubmit={updateClient}
            modalHeader="Property details"
            fullscreen={true}
            recordLocked={recordLocked}
            isPerson2={false}
            enableReinitialize={true}
          />
          <Accordion
            title={"Personal Details"}
            expanded={personalDetailsExpanded}
            content={
              <DataCard1
                title={"Your Profile"}
                description={
                  "Making sure your personal details are up to date prior to any IFA appointments will help speed up the appointment."
                }
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    function: () => setShowYourProfileModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Name",
                    value:
                      (clientData?.firstName && clientData?.firstName) +
                      " " +
                      (clientData?.lastName && clientData?.lastName),
                  },
                  {
                    name: "Pension Fund",
                    value:
                      clientData?.annuity?.pensionFund?.toString() ?? "0.00",
                    prefix: "£",
                    formatType: "currency",
                  },
                  {
                    name: "Date of Birth",
                    value: clientData?.dateOfBirth,
                  },
                ]}
                customContent={undefined}
              />
            }
            onToggle={togglePersonalDetails}
          />
          <Accordion
            title={"Pension Details"}
            expanded={pensionDetailsExpanded}
            content={
              <DataCard1
                title={"Pension Details"}
                description={""}
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    function: () => setShowPensionModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Number of pensions added",
                    value: clientData?.pensions?.length,
                  },
                ]}
                customContent={
                  clientData?.pensions?.length > 0 ? (
                    <Table
                      title="Your Pensions"
                      columns={[
                        { name: "Type", dataType: "text" },
                        { name: "Provider", dataType: "text" },
                        {
                          name: "Valuation",
                          dataType: "currency",
                          formatType: "currency",
                          prefix: "£",
                        },
                        { name: "Valuation Date", dataType: "date" },
                      ]}
                      rows={clientData?.pensions?.map((pension: IPension) => {
                        let pensionType = pensionTypeLookup.find(
                          (item) => item.value === pension.pensionType
                        );

                        const getProviderLabel = (
                          value: string
                        ): string | undefined => {
                          for (const group of groupedOptions) {
                            for (const option of group.options) {
                              if (option.value === value) {
                                return option.label;
                              }
                            }
                          }

                          return undefined; // Provider with the specified value not found
                        };

                        const providerLabel = getProviderLabel(
                          pension.cedingScheme
                        );

                        console.log(pension.valuationDate);

                        let pensionData: IRow = {
                          data: [
                            pensionType ? pensionType.label : "",
                            providerLabel && providerLabel !== "Unknown"
                              ? providerLabel
                              : pension.customerProvidedCedingSchemeName ?? "",
                            pension.valuation
                              ? pension?.valuation?.toString()
                              : "",
                            pension.valuationDate ? pension.valuationDate : "",
                          ],
                        };

                        return pensionData;
                      })}
                    />
                  ) : null
                }
              />
            }
            onToggle={togglePensionDetails}
          />
          <Accordion
            title={"Medical Details"}
            expanded={medicalDetailsExpanded}
            content={
              <>
                <DataCard1
                  title={"Person 1 Medical & Lifestyle"}
                  description={
                    "Completing the below can potentially increase the amount you could receive and entitle you to enhanced annuities."
                  }
                  headerButtons={[
                    {
                      label: "Edit",
                      to: "",
                      function: () => setShowMedicalAndLifestyleModal(true),
                    },
                  ]}
                  cardData={[
                    {
                      name: "Height",
                      value: `${
                        clientData?.medicalInformation?.heightFeet ?? ""
                      } ' ${
                        clientData?.medicalInformation?.heightInches ?? ""
                      }`.trim(),
                    },
                    {
                      name: "Weight",
                      value: `${
                        clientData?.medicalInformation?.weightStone ?? ""
                      }st ${
                        clientData?.medicalInformation?.weightPounds ?? ""
                      }lb`.trim(),
                    },
                    Array.isArray(
                      clientData?.medicalInformation?.medicalConditionList
                    )
                      ? clientData.medicalInformation.medicalConditionList.map(
                          (item: any, index: number) => ({
                            name: `Medical condition ${index + 1}`,
                            value: item.conditionName,
                          })
                        )
                      : [],
                    {
                      name: "Number of medications taken",
                      value:
                        clientData?.medicalInformation?.medicationList?.length,
                    },
                  ]}
                  customContent={undefined}
                />
                {clientData.client1OrJoint === "1496" && (
                  <DataCard1
                    title={"Person 2 Medical & Lifestyle"}
                    description={
                      "Completing the below can potentially increase the amount you could receive and entitle you to enhanced annuities."
                    }
                    headerButtons={[
                      {
                        label: "Edit",
                        to: "",
                        function: () => setShowMedicalAndLifestyleModal2(true),
                      },
                    ]}
                    cardData={[
                      {
                        name: "Height",
                        value: `${
                          clientData?.person2MedicalInformation?.heightFeet ??
                          ""
                        } ' ${
                          clientData?.person2MedicalInformation?.heightInches ??
                          ""
                        }`.trim(),
                      },
                      {
                        name: "Weight",
                        value: `${
                          clientData?.person2MedicalInformation?.weightStone ??
                          ""
                        }st ${
                          clientData?.person2MedicalInformation?.weightPounds ??
                          ""
                        }lb`.trim(),
                      },
                      Array.isArray(
                        clientData?.person2MedicalInformation
                          ?.medicalConditionList
                      )
                        ? clientData.person2MedicalInformation.medicalConditionList.map(
                            (item: any, index: number) => ({
                              name: `Medical condition ${index + 1}`,
                              value: item.conditionName,
                            })
                          )
                        : [],
                      {
                        name: "Number of medications taken",
                        value:
                          clientData?.person2MedicalInformation?.medicationList
                            ?.length,
                      },
                    ]}
                    customContent={undefined}
                  />
                )}
              </>
            }
            onToggle={toggleMedicalDetails}
          />
          <Accordion
            title={"Property Details"}
            expanded={propertyDetailsExpanded}
            content={
              <DataCard1
                title={"Add details about any properties you own"}
                description={""}
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    function: () => setShowPropertyModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Number of properties added",
                    value: clientData?.properties?.length,
                  },
                ]}
                customContent={
                  <Table
                    title="Your Properties"
                    columns={[
                      { name: "Name", dataType: "text" },
                      { name: "Address", dataType: "text" },
                      { name: "PostCode", dataType: "text" },
                      {
                        name: "Est. Value",
                        dataType: "currency",
                        formatType: "currency",
                        prefix: "£",
                      },
                    ]}
                    rows={clientData?.properties?.map(
                      (property: IProperty, index: number) => ({
                        data: [
                          "Property " + (index + 1),
                          property.address,
                          property.postcode,
                          property.estimatedValue,
                        ],
                      })
                    )}
                  />
                }
              />
            }
            onToggle={togglePropertyDetails}
          />
          <Accordion
            title={"Employment Details"}
            expanded={employmentDetailsExpanded}
            content={
              <DataCard1
                title={"Add details of current and past employments"}
                description={""}
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    function: () => setShowEmploymentModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Number of employments added",
                    value: clientData?.employments?.length,
                  },
                ]}
                customContent={
                  <Table
                    title="Your Employment History"
                    columns={[
                      { name: "Occupation", dataType: "text" },
                      { name: "Type", dataType: "text" },
                      { name: "Status", dataType: "text" },
                      { name: "Start Date", dataType: "date" },
                      { name: "End Date", dataType: "date" },
                    ]}
                    rows={clientData?.employments?.map(
                      (employment: IEmployment) => {
                        let employmentDisplay = calculateEmploymentDisplayValues(employment);
                        
                        let employmentData: IRow = {
                          data: [
                            employmentDisplay[0],
                            employmentDisplay[1],
                            employmentDisplay[2],
                            employmentDisplay[3],
                            employmentDisplay[4]
                          ],
                        };

                        return employmentData;
                      }
                    )}
                  />
                }
              />
            }
            onToggle={toggleEmploymentDetails}
          />
        </>
      ) : (
        <Loader show={true} text="Loading your data" />
      )}
    </>
  );
};
