import { useEffect, useRef } from "react";
import { usePortal } from "../context/PortalContext";
import { GetFormFields } from "../modules/forms/Helpers";
import { Loader } from "../modules/common/modals/LoaderModal";
import { IClientData } from "../models/ClientData";
import { AllData } from "../modules/forms/form_schemas/Shared/AllData";
import { useFormik, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";

export const FormTestPage = () => {
  const {
    returnClientData,
    clientData,
    apiUpdateClientData
  }: any = usePortal();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      let responseCode = await returnClientData();

      if (responseCode !== 200) {
        navigate(`/error/${responseCode}`);
      }
    };

    if (!clientData) {
      fetchClientData();
    } else {
      console.log("Existing client data:", clientData);
    }
  }, []);

  useEffect(() => {
    if (clientData) {
      console.log("Client data updated:", clientData);
    }
  }, [clientData]);

  async function updateClient(data: IClientData) {
    try {
      console.log("Updating, please wait...");
      await apiUpdateClientData(data);
    } catch (error) {
      console.error("Error updating client data:", error);
    }
  }

  const formik = useFormik<IClientData>({
    initialValues: clientData,
    validationSchema: AllData,
    onSubmit: updateClient,
    enableReinitialize: true,
  });

  const formRef = useRef<HTMLFormElement>(null);

  if (!clientData) {
    return <Loader show={true} text="Loading your data" />;
  }

  const formFields = GetFormFields(AllData, false, formik.values);

  return (
    <FormikProvider value={formik}>
      <div className="modal-body py-lg-10 px-lg-10">
        <div className="flex-row-fluid py-lg-5 px-lg-15">
          <form
            className="py-5 w-100 px-5 container-xl"
            noValidate
            id="ModalForm"
            ref={formRef}
            onSubmit={formik.handleSubmit}
          >
            {formFields}
          </form>
        </div>
      </div>
      <div className="modal-footer">
        {!formik.isValid ? (
          <div className="text-danger mt-2 d-flex justify-content-end">
            Please correct the above errors.
          </div>
        ) : null}
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => formRef.current?.requestSubmit()}
        >
          Save
        </button>
      </div>
    </FormikProvider>
  );
};
