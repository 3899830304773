import * as Yup from "yup";

export const medicationSchema = Yup.object().shape({
  medicationName: Yup.string()
    .label("Medication Name")
    .required("Please specify the name of the medication."),

  dosageKnown: Yup.string().label("Dosage Known").nullable(),

  numberTaken: Yup.string().label("Number Taken").nullable(),

  frequency: Yup.string()
    .label("Frequency Taken")
    .required("Please specify the number of times you take this medication.")
    .notOneOf(["", "0"], "Please select an option."),

  dosage: Yup.string()
    .label("Dosage")
    .nullable()
    .when("dosageKnown", {
      is: (val: string) => val === "true",

      then: Yup.string()
        .required("Please specify the dosage of this medication.")
        .notOneOf(["0", ""], "Please select an option"),
    }),

  dosageUnit: Yup.string().label("Dosage Unit").nullable(),

  forConditionType: Yup.string().label("Taken for condition type").nullable(),

  startDate: Yup.string()
    .label("When did you start taking the medication?")
    .nullable(),

  stillTakeMedication: Yup.string()
    .label("Are you still taking the medication?")
    .nullable(),

  endDate: Yup.string()
    .label("When did you stop taking the medication?")
    .nullable()
    .when("stillTakeMedication", {
      is: (val: string) => val === "false",
      then: Yup.string().required(
        "Please tell us the date you stopped taking this medication."
      ),
    }),
});
